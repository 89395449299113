import React, { Component } from "react";
import { StyleSheet, View, ActivityIndicator } from 'react-native';
import { Container,Icon,Button } from 'semantic-ui-react'
import '../css/test.css'
import { history } from '../history'
import FacebookLogin from 'react-facebook-login'
import { faIR } from "date-fns/locale";
import firebase from '../firebasedb/firebaseconfig';

class LOGIN extends React.Component {
    constructor(props) {   
    super();
    //this.sigin_success = this.sigin_success.bind(this);
    this.state = { 
      displayName: '',
      email: '', 
      password: '',
      isLoading: false,
      isSignedIn: false, // Local signed-in state.
      deviceMode: 'none'
    }
  }
  
  componentDidMount() {
    this.handleScreen()  
    this.setState({
      isLoading: true
    })
    console.log("Welcome to psyjai :-)")
    // firebase login redirect 
    firebase.auth()
    .getRedirectResult()
    .then((result) => {
      if (result.credential) {
        
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;
        var token = credential.accessToken;       
      }
      console.log(result)
      var user = result.user; 
      // if login 
      if(user != null){
        // console.log("user, ",user)
        localStorage.setItem('redirect',true)
        window.FB.api('/'+user.providerData[0].uid+"?access_token="+token, {fields: ['first_name','last_name']},async function(res) {
          // console.log(res)
          localStorage.setItem('firstname',res.first_name)
          localStorage.setItem('lastname',res.last_name)  
          let firstname = res.first_name 
          let lastname = res.last_name
          let username = firstname+" "+lastname 
          await localStorage.setItem('name',username)  
          await localStorage.setItem('uid',res.id)
          console.log('Good to see you, ' + username + ' :-)');
          console.log('login success !');
          localStorage.setItem('login',true) 
          // redirect to main dashboard
          history.push('/')
        })             
      }else{
        // if log out
        localStorage.setItem('login',false)
        this.setState({
          isLoading: false
        }) 
      }     
    })
  }

  handleScreen = () =>{
    const mediaMatchMobilePortrait = window.matchMedia('(max-width: 480px)');
    const mediaMatchMobileLanscape = window.matchMedia('(min-width: 480px) and (max-width: 900px) and (orientation: landscape)');
    const mediaMatchTabletLanscape = window.matchMedia('(min-width: 900px)');
    const mediaMatchTabletPortrait = window.matchMedia('(min-width: 480px) and (max-width: 900px) and (orientation: portrait)');
    
    if(mediaMatchMobilePortrait.matches){
      this.setState({
          deviceMode: "mobilePortrait"
      })
    }
    
    if(mediaMatchMobileLanscape.matches){
      this.setState({
        deviceMode: "mobileLandScape"
      })
    }

    if(mediaMatchTabletLanscape.matches){
      this.setState({
        deviceMode: "tabletLandScape"
      })
    }

    if(mediaMatchTabletPortrait.matches){
      this.setState({
          deviceMode: "tabletPortrait"
      })
    }

    mediaMatchTabletPortrait.addEventListener("change", e => {
      // console.log("onListernerTabletPortrait: ",e.matches,e.media)
      if(e.matches){
        this.setState({
          deviceMode: "tabletPortrait"
        })
      }
    })

    mediaMatchMobilePortrait.addEventListener("change", e => {
      // console.log("onListernerMobilePort: ",e.matches,e.media)
      if(e.matches){
        this.setState({
          deviceMode: "mobilePortrait"
        })
      }
    })

    mediaMatchMobileLanscape.addEventListener("change", e => {
      // console.log("onListernerMobileLand: ",e.matches,e.media)
      if(e.matches){
        this.setState({
          deviceMode: "mobileLandScape"
        })
      }
    })

    mediaMatchTabletLanscape.addEventListener("change", e => {
      // console.log("onListernerTabletLand: ",e.matches,e.media)
      if(e.matches){
        this.setState({
          deviceMode: "tabletLandScape"
        })
      }
    })
  }

  signInFacebookByfirebase = () =>{
      // set loading
      this.setState({
        isLoading: true
      })
    // login with facebook
      var provider = new firebase.auth.FacebookAuthProvider();
      firebase.auth().signInWithRedirect(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;
        var user = result.user;
        var accessToken = credential.accessToken; 
        localStorage.setItem('redirect',true)  
        // console.log(result)
      })      
      .catch((error) => {
        // console.log( error.message)
        localStorage.setItem('login',false)  
      });
  }

  signInWithFacebook(){
      window.FB.login(function(response) {
      if (response.authResponse) {
       console.log('Welcome!  Fetching your information.... ');
       window.FB.api('/me', {fields: ['first_name','last_name']}, function(res) {
          if (res && !res.error) {
            /* handle the result */
            localStorage.setItem('firstname',res.first_name)
            localStorage.setItem('lastname',res.last_name)  
            let firstname = res.first_name 
            let lastname = res.last_name
            let username = firstname+" "+lastname 
            localStorage.setItem('name',username)  
            localStorage.setItem('uid',res.id)
            console.log('Good to see you, ' + username + ' :-)');
            console.log('login success !');
            // redirect to main dashboard
            history.push('/')          
          }     
       });
      } else {
        console.log('User cancelled login or did not fully authorize.');
        localStorage.setItem('login',false)
      }
  },{scope: 'public_profile,email'});
  }

  render(){
    
    if(this.state.isLoading){
      return(
        <View style={styles.preloader}>
          <ActivityIndicator size="large" color="#9E9E9E"/>
        </View>
      )
    }else{
      return (
         <div id="background">
          <Container id="psyjaiContainner">
            <h1 id="headPsyjai">PSYJAI</h1> 
              <Button id="signInbutton" size='large' color='facebook' 
                onClick= {()=> {
                  // firebase.auth()
                  this.signInFacebookByfirebase()
                }}>
                  <Icon name='facebook' /> Sign in with Facebook
              </Button>
            {/* <StyledFirebaseAuth id="signInbutton" uiConfig={this.uiConfig} firebaseAuth={firebase.auth()}/> */}
          </Container>        
       </div>
      );
    }  
  }
}

export default LOGIN;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 35,
    backgroundColor: '#fff'
  },
  inputStyle: {
    width: '100%',
    marginBottom: 15,
    paddingBottom: 15,
    alignSelf: "center",
    borderColor: "#ccc",
    borderBottomWidth: 1
  },
  loginText: {
    color: '#3740FE',
    marginTop: 25,
    textalign: 'center'
  },
  preloader: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff'
  },
});