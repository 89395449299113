import React, { useState, useEffect } from 'react';
import './App.css';
import AppRouter from './router'


function App() {
  return (
    <div> 
      <AppRouter/> 
    </div>
  );
}

export default App;
