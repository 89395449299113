import React from "react";
import avatar from '../images/matthew.png'; 
import '../css/tree.css'
import firebase from '../firebasedb/firebaseconfig'
import 'semantic-ui-css/semantic.css';
import { Segment, Card, Icon, Image ,Grid,Divider,Message,Dimmer,Loader } from 'semantic-ui-react'
import { Col,Row,Button,Container } from 'react-bootstrap'
import { FaHome,FaTheaterMasks,FaRegLightbulb,FaRegEdit,FaTree,FaQuestion} from "react-icons/fa";
import { MdMood } from "react-icons/md";
import { GrArticle } from "react-icons/gr";
// import {browserHistory} from 'react-router';
import { history } from '../history';
require('firebase/auth');
require('firebase/database');

const dataLinkLocal = [
  ["https://dl.dropbox.com/s/v7iwb63pnst1zp1/step1.png","https://dl.dropbox.com/s/oqj9084agd9kmyn/step2.png",
  "https://dl.dropbox.com/s/5cgi1m0t8n3mdgr/step3.png","https://dl.dropbox.com/s/yxxg5ikpx35l0f1/step4.png","https://dl.dropbox.com/s/zdenfy444z47564/step5.png"],
  ["https://dl.dropbox.com/s/tjw4anhhb993nix/step1.png","https://dl.dropbox.com/s/4szggv91y4ii3xz/step2.png","https://dl.dropbox.com/s/hiokeyii56ojxz6/step3.png",
  "https://dl.dropbox.com/s/g461dnwsuktgb66/step4.png","https://dl.dropbox.com/s/vgmw2sazzdx0yr3/step5.png"],
  ["https://dl.dropbox.com/s/xeqze4zlu0u5d2q/step1.png","https://dl.dropbox.com/s/1uudhpcv6ysrjmp/step2.png","https://dl.dropbox.com/s/jtuxa2qglf6vehb/step3.png",
  "https://dl.dropbox.com/s/p6jrquyxdh1jj1z/step4.png","https://dl.dropbox.com/s/agl6q018l0gj9ml/step5.png"],
  ["https://dl.dropbox.com/s/34qg4pk11ntn1mm/step1.png","https://dl.dropbox.com/s/sfggc5gwa6swd7e/step2.png","https://dl.dropbox.com/s/oxyjh715rxo9jlw/step3.png",
  "https://dl.dropbox.com/s/68zaa6tugsna7ko/step4.png","https://dl.dropbox.com/s/bawx4q70juaunz2/step5.png"],
  ["https://dl.dropbox.com/s/5sgy87eubaqdbmz/step1.png","https://dl.dropbox.com/s/1xw7flz9d0o4q76/step2.png","https://dl.dropbox.com/s/us8vh0v6kdoh5u0/step3.png",
  "https://dl.dropbox.com/s/72v8y1o3bhhnly1/step4.png","https://dl.dropbox.com/s/956ze91lls8n0up/step5.png"],
  ["https://dl.dropbox.com/s/npyl5487rhf452d/step1.png","https://dl.dropbox.com/s/it5oendozbu7a7t/step2.png","https://dl.dropbox.com/s/6xp9tm6r3d12m6y/step3.png",
  "https://dl.dropbox.com/s/ak6jjqfwh4be6i7/step4.png","https://dl.dropbox.com/s/6p9lng39euuio1l/step5.png"],
  ["https://dl.dropbox.com/s/2strry8qc3xm0un/step1.png","https://dl.dropbox.com/s/d0zw6m5hrs84shc/step2.png","https://dl.dropbox.com/s/slqenas6vaa8cyg/step3.png",
  "https://dl.dropbox.com/s/zutql837yi6q89z/step4.png","https://dl.dropbox.com/s/1xg2hygjmlki7dj/step5.png"],
  ["https://dl.dropbox.com/s/eimzsc0zzlyo1p7/step1.png","https://dl.dropbox.com/s/5mr9d64xq5aq8sp/step2.png","https://dl.dropbox.com/s/97tjh9o5n283qlk/step3.png",
  "https://dl.dropbox.com/s/xak1dv59esox79u/step4.png","https://dl.dropbox.com/s/rhy9mu0i2g2xp8q/step5.png"],
  ["https://dl.dropbox.com/s/eimzsc0zzlyo1p7/step1.png","https://dl.dropbox.com/s/5mr9d64xq5aq8sp/step2.png","https://dl.dropbox.com/s/97tjh9o5n283qlk/step3.png",
  "https://dl.dropbox.com/s/xak1dv59esox79u/step4.png","https://dl.dropbox.com/s/rhy9mu0i2g2xp8q/step5.png"]
]

class Tree extends React.Component {
  constructor(props) {
    super(props);
     this.state = {     
      pictures: [],
      reward:[],
      name:[],
      qua:[],
      url:[],
      loading: true
      
    };
     this.onDrop = this.onDrop.bind(this);
  }
  state = { activeItem: 'home' }
  onDrop(picture) {
      this.setState({
          pictures: this.state.pictures,
      });
  }


  handleItemClick = (e, { name }) => this.setState(
      { activeItem: name },
      history.push('/'+name)
    )

   getReward = async () => {
    let username = localStorage.getItem('name')
    // console.log(username)
    //let username = "Test name"
    let rewardTree = []
    let result = []
    let collectTree = []
    this.setState({ loading: true })
    let checkCollectTree = firebase.database().ref().child("users").child(username).child("collectTree")
      await checkCollectTree.once("value",snap =>{
        if(snap.val() != null){
          snap.forEach(tree => {
            collectTree.push(tree.val())
          })    
        }      
      })
    // console.log(collectTree)
    for(let i=0; i< collectTree.length-1; i++){
      let numberTree = 0
      if(collectTree[i] == "cherrybrossom"){ 
        numberTree = 1
      }
      if(collectTree[i] == "intanin"){
        numberTree = 2
      }
      if(collectTree[i] == "lemon"){ 
        numberTree = 3
      }
      if(collectTree[i] == "lingnum"){ 
        numberTree = 4
      }
      if(collectTree[i] == "maple"){ 
        numberTree = 5
      }
      if(collectTree[i] == "ทับทิม"){ 
        numberTree = 6
      }
      if(collectTree[i] == "มะกอก"){ 
        numberTree = 7
      }
      if(collectTree[i] == "โมกขาว"){ 
        numberTree = 8
      }     
      rewardTree.push({
        nameTree: collectTree[i],
        url: dataLinkLocal[numberTree][4],
      })
    } 
    result = Object.values(rewardTree.reduce((c, {nameTree,url}) => {
      c[nameTree] = c[nameTree] || {nameTree: nameTree,quatity: 0,url: url};
      c[nameTree].quatity++;
      return c;
    }, {}))
    // console.log(result)
    this.setTreeData(result) 
}

setTreeData(result){
    this.setState({
      loading: false,
      reward: result,
    })
}
handleScreen = () =>{
  const mediaMatchMobilePortrait = window.matchMedia('(max-width: 480px)');
  const mediaMatchMobileLanscape = window.matchMedia('(min-width: 480px) and (max-width: 900px) and (orientation: landscape)');
  const mediaMatchTabletLanscape = window.matchMedia('(min-width: 900px)');
  const mediaMatchTabletPortrait = window.matchMedia('(min-width: 480px) and (max-width: 900px) and (orientation: portrait)');

  if(mediaMatchMobilePortrait.matches){
    this.setState({
        deviceMode: "mobilePortrait"
    })
  }
  
  if(mediaMatchMobileLanscape.matches){
    this.setState({
      deviceMode: "mobileLandScape"
    })
  }

  if(mediaMatchTabletLanscape.matches){
    this.setState({
      deviceMode: "tabletLandScape"
    })
  }

  if(mediaMatchTabletPortrait.matches){
    this.setState({
        deviceMode: "tabletPortrait"
    })
  }

  mediaMatchTabletPortrait.addEventListener("change", e => {
    // console.log("onListernerTabletPortrait: ",e.matches,e.media)
    if(e.matches){
      this.setState({
        deviceMode: "tabletPortrait"
      })
    }
  })

  mediaMatchMobilePortrait.addEventListener("change", e => {
    // console.log("onListernerMobilePort: ",e.matches,e.media)
    if(e.matches){
      this.setState({
        deviceMode: "mobilePortrait"
      })
    }
  })

  mediaMatchMobileLanscape.addEventListener("change", e => {
    // console.log("onListernerMobileLand: ",e.matches,e.media)
    if(e.matches){
      this.setState({
        deviceMode: "mobileLandScape"
      })
    }
  })

  mediaMatchTabletLanscape.addEventListener("change", e => {
    // console.log("onListernerTabletLand: ",e.matches,e.media)
    if(e.matches){
      this.setState({
        deviceMode: "tabletLandScape"
      })
    }
  })
}

  componentDidMount() {
    this.handleScreen()
    this.getReward();
      //this.getUsers();
  }

  render() {
    const { activeItem } = this.state
    const { data, loading } = this.state;
    return (
     <>
     {
      (this.state.deviceMode == "tabletLandScape") && 
      <Grid>
       <Grid.Column id="gridTree">     
     <Segment id="segmentTree">
     <h3 id="fontHeadResult">ต้นไม้ (Tree)</h3>
       <Divider style={{margin: "2%"}} />
       <div id="scrollTree">
       {
         !loading && (
         <Card.Group  itemsPerRow={4}>
         { this.state.reward.map((reward, i) => 
             <Card sortable={false}>
               {/* <Image id="imgTree" src={reward.url[4]}/> */}
               <Image id="imgTree" centered src={reward.url} size='small' />
                 <Card.Content>
                 <Card.Header id="nameHeaderTree">{reward.nameTree} </Card.Header>
                </Card.Content>  
                <Card.Content extra>
                 <Icon name='trophy' />{reward.quatity}
               </Card.Content>
             </Card>                    
          )}
           </Card.Group> )
       }
       {
         loading && (
           <Loader active content='Loading' />
         )
        
       }
        {!loading && (this.state.reward == '' || this.state.reward == null) &&
        <div style={{display: 'flex', justifyContent: 'center'}}>
        <Message size='large' style={{marginTop:'30px'}}compact color='teal'>
        <Message.Header  style={{fontFamily:'Mitr',fontWeight:'400'}}>โอ๊ะแย่จัง คุณยังไม่มีต้นไม้เลย T_T</Message.Header>
        <Message.Content style={{fontFamily:'Mitr',fontWeight:'300'}}>รีบไปสะสมกันเถอะ</Message.Content>  
        </Message>
           
        
        </div>
      }
       </div>
    </Segment>  
    </Grid.Column>
    </Grid>
    }
    {
      (this.state.deviceMode == "mobileLandScape") && 
      <Grid id="gridTree">
       <Grid.Column >     
     <Segment id="segmentTree">
     <h3 id="fontHeadResult">ต้นไม้ (Tree)</h3>
       <Divider style={{margin: "2%"}} />
       <div id="scrollTree">
       {
         !loading && (
         <Card.Group  itemsPerRow={3}>
         { this.state.reward.map((reward, i) => 
             <Card sortable={false}>
               {/* <Image id="imgTree" src={reward.url[4]}/> */}
               <Image id="imgTree" centered src={reward.url} size='small' />
                 <Card.Content>
                 <Card.Header id="nameHeaderTree">{reward.nameTree} </Card.Header>
                </Card.Content>  
                <Card.Content extra>
                 <Icon name='trophy' />{reward.quatity}
               </Card.Content>
             </Card>                    
          )}
           </Card.Group> )
       }
       {
         loading && (
           <Loader active content='Loading' />
         )
       }
         {!loading && (this.state.reward == '' || this.state.reward == null) &&
       <div style={{display: 'flex', justifyContent: 'center'}}>
       <Message size='tiny' style={{marginTop:'30px'}}compact color='teal'>
        <Message.Header  style={{fontFamily:'Mitr',fontWeight:'400'}}>โอ๊ะแย่จัง คุณยังไม่มีต้นไม้เลย</Message.Header>
        <Message.Content style={{fontFamily:'Mitr',fontWeight:'300'}}>รีบไปสะสมกันเถอะ</Message.Content>  
        </Message>
       </div>
      }
       </div>
    </Segment>  
    </Grid.Column>
    </Grid>
    }
     {
      (this.state.deviceMode == "mobilePortrait") && 
      <Grid id="gridTree">
       <Grid.Column id="gridTree">     
     <Segment id="segmentTree">
     <h3 id="fontHeadResult">ต้นไม้ (Tree)</h3>
       <Divider style={{margin: "2%"}} />
       <div id="scrollTree">
       {
         !loading && (
         <Card.Group  itemsPerRow={2}>
         { this.state.reward.map((reward, i) => 
             <Card sortable={false}>
               {/* <Image id="imgTree" src={reward.url[4]}/> */}
               <Image id="imgTree" centered src={reward.url} size='small' />
                 <Card.Content>
                 <Card.Header id="nameHeaderTree">{reward.nameTree} </Card.Header>
                </Card.Content>  
                <Card.Content extra>
                 <Icon name='trophy' />{reward.quatity}
               </Card.Content>
             </Card>                    
          )}
           </Card.Group> )
       }
       {
         loading && (
           <Loader active content='Loading' />
         )
       }
         {!loading && (this.state.reward == '' || this.state.reward == null) &&
        <div style={{display: 'flex', justifyContent: 'center'}}>
       <Message size='tiny' style={{marginTop:'30px'}}compact color='teal'>
        <Message.Header  style={{fontFamily:'Mitr',fontWeight:'400'}}>โอ๊ะแย่จัง คุณยังไม่มีต้นไม้เลย</Message.Header>
        <Message.Content style={{fontFamily:'Mitr',fontWeight:'300'}}>รีบไปสะสมกันเถอะ</Message.Content>  
        </Message>
        </div>
      }
       </div>
    </Segment>  
    </Grid.Column>
    </Grid>
    }
    {
      (this.state.deviceMode == "tabletPortrait") && 
      <Grid id="gridTree">
       <Grid.Column>     
     <Segment id="segmentTree">
     <h3 id="fontHeadResult">ต้นไม้ (Tree)</h3>
       <Divider style={{margin: "2%"}} />
       <div id="scrollTree">
       {
         !loading && (
         <Card.Group  itemsPerRow={4}>
         { this.state.reward.map((reward, i) => 
             <Card sortable={false}>
              {/* // <Image id="imgTree" src={reward.url[4]}/> */}
               <Image id="imgTree" centered src={reward.url} size='small' />
                 <Card.Content>
                 <Card.Header id="nameHeaderTree">{reward.nameTree} </Card.Header>
                </Card.Content>  
                <Card.Content extra>
                 <Icon name='trophy' />{reward.quatity}
               </Card.Content>
             </Card>                    
          )}
           </Card.Group> )
       }
       {
         loading && (
           <Loader active content='Loading' />
         )
       }
         {!loading && (this.state.reward == '' || this.state.reward == null) &&
       <div style={{display: 'flex', justifyContent: 'center'}}>
      <Message style={{marginTop:'30px'}} compact color='teal'>
        <Message.Header  style={{fontFamily:'Mitr',fontWeight:'400'}}>โอ๊ะแย่จัง คุณยังไม่มีต้นไม้เลย T_T</Message.Header>
        <Message.Content style={{fontFamily:'Mitr',fontWeight:'300'}}>รีบไปสะสมกันเถอะ</Message.Content>  
        </Message>
       </div>
      }
       </div>
    </Segment>  
    </Grid.Column>
    </Grid>
    }
    </>
    
    );
  }
}export default Tree;

