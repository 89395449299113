import React from "react";
import '../css/dash.css'
import 'semantic-ui-css/semantic.css';
import firebase from '../firebasedb/firebaseconfig'
import { Segment,Label,Modal,Message,Image,Popup,Button,Grid,Loader } from 'semantic-ui-react'
import { Col,Row } from 'react-bootstrap'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction";
require('firebase/auth');
require('firebase/database');

const blue = './moodImg/blue5.png'
const red = './moodImg/red5.png'
const green = './moodImg/green5.png'
const yellow = './moodImg/yellow5.png'
const white = './moodImg/white.png'

const dataLinkLocal = [
  ["https://dl.dropbox.com/s/v7iwb63pnst1zp1/step1.png","https://dl.dropbox.com/s/oqj9084agd9kmyn/step2.png",
  "https://dl.dropbox.com/s/5cgi1m0t8n3mdgr/step3.png","https://dl.dropbox.com/s/yxxg5ikpx35l0f1/step4.png","https://dl.dropbox.com/s/zdenfy444z47564/step5.png"],
  ["https://dl.dropbox.com/s/tjw4anhhb993nix/step1.png","https://dl.dropbox.com/s/4szggv91y4ii3xz/step2.png","https://dl.dropbox.com/s/hiokeyii56ojxz6/step3.png",
  "https://dl.dropbox.com/s/g461dnwsuktgb66/step4.png","https://dl.dropbox.com/s/vgmw2sazzdx0yr3/step5.png"],
  ["https://dl.dropbox.com/s/xeqze4zlu0u5d2q/step1.png","https://dl.dropbox.com/s/1uudhpcv6ysrjmp/step2.png","https://dl.dropbox.com/s/jtuxa2qglf6vehb/step3.png",
  "https://dl.dropbox.com/s/p6jrquyxdh1jj1z/step4.png","https://dl.dropbox.com/s/agl6q018l0gj9ml/step5.png"],
  ["https://dl.dropbox.com/s/34qg4pk11ntn1mm/step1.png","https://dl.dropbox.com/s/sfggc5gwa6swd7e/step2.png","https://dl.dropbox.com/s/oxyjh715rxo9jlw/step3.png",
  "https://dl.dropbox.com/s/68zaa6tugsna7ko/step4.png","https://dl.dropbox.com/s/bawx4q70juaunz2/step5.png"],
  ["https://dl.dropbox.com/s/5sgy87eubaqdbmz/step1.png","https://dl.dropbox.com/s/1xw7flz9d0o4q76/step2.png","https://dl.dropbox.com/s/us8vh0v6kdoh5u0/step3.png",
  "https://dl.dropbox.com/s/72v8y1o3bhhnly1/step4.png","https://dl.dropbox.com/s/956ze91lls8n0up/step5.png"],
  ["https://dl.dropbox.com/s/npyl5487rhf452d/step1.png","https://dl.dropbox.com/s/it5oendozbu7a7t/step2.png","https://dl.dropbox.com/s/6xp9tm6r3d12m6y/step3.png",
  "https://dl.dropbox.com/s/ak6jjqfwh4be6i7/step4.png","https://dl.dropbox.com/s/6p9lng39euuio1l/step5.png"],
  ["https://dl.dropbox.com/s/2strry8qc3xm0un/step1.png","https://dl.dropbox.com/s/d0zw6m5hrs84shc/step2.png","https://dl.dropbox.com/s/slqenas6vaa8cyg/step3.png",
  "https://dl.dropbox.com/s/zutql837yi6q89z/step4.png","https://dl.dropbox.com/s/1xg2hygjmlki7dj/step5.png"],
  ["https://dl.dropbox.com/s/eimzsc0zzlyo1p7/step1.png","https://dl.dropbox.com/s/5mr9d64xq5aq8sp/step2.png","https://dl.dropbox.com/s/97tjh9o5n283qlk/step3.png",
  "https://dl.dropbox.com/s/xak1dv59esox79u/step4.png","https://dl.dropbox.com/s/rhy9mu0i2g2xp8q/step5.png"],
  ["https://dl.dropbox.com/s/eimzsc0zzlyo1p7/step1.png","https://dl.dropbox.com/s/5mr9d64xq5aq8sp/step2.png","https://dl.dropbox.com/s/97tjh9o5n283qlk/step3.png",
  "https://dl.dropbox.com/s/xak1dv59esox79u/step4.png","https://dl.dropbox.com/s/rhy9mu0i2g2xp8q/step5.png"]
]

const cherry = ["ต้นเชอรรี่เป็นสัญลักษณ์ของ โชคลาภและความโชคดีเมื่อบานสะพรั่ง นอกจากนี้ยังมีความหมายทางด้าน ความรัก ความโรแมนติกอีกด้วย ต้นเชอรี่บลอสซั่มจะออกดอกบานเพียง 1 ครั้งต่อปี และมีช่วงเวลาที่อวดความสวยงามเพียงไม่นาน เป็นเหมือนการเตือนถึงชีวิตที่แสนสั้นแต่ยังคงสวยงามเต็มเปี่ยมไปด้วยชีวิตชีวา และคอยย้ำเตือนให้เราใช้ชีวิตให้เต็มในทุก ๆ วัน ต้นเชอรี่บลอสซั่มเป็นที่นิยมมากในญี่ปุ่น ถึงขนาดมีเทศกาล HANAMI เพื่อไปเฉลิมฉลองการเข้าฟใบไม้ผลิ โดยการปิกนิกดูต้นไม้",
"เป็นตัวแทนของการเริ่มต้นใหม่ ความโชคดีและสีกลีบดอกที่ดูอ่อนโยนยังแสดงถึงความสวยงาม ความสุขที่บริสุทธิ์ และจากความสวยงามที่มีให้ชื่นชมในช่วงเวลาอันสั้นนี้ ทำให้ตระหนักถึงความหมายในการแบ่งปันช่วงเวลาดีๆร่วมกันกับคนที่เรารัก เรามาปลูกความรักให้ผลิบานในจิตใจไปด้วยกันนะ"]
const lingnum = ["เป็นต้นที่มีเนื้อมากหนักที่สุดในโลก และก็เติบโตช้าด้วยคนที่ปลูกต้นนี้ ต้องมีความอดทนที่จะรอการเจริญเติบโตพอตัวเลยแหละได้คูณสองเลยนะ ทั้งได้เห็นการเติบโตของต้นไม้และเห็นการเจริญเติบโตภายในใจของผู้ปลูกจากความเพียรและอดทนรอ",
"แปลว่า 'ไม้แห่งชีวิต' เป็นต้นไม้ที่เติบโตช้า แต่ในขณะเดียวกันก็เป็นเนื้อไม้ที่หนักที่สุดในโลกถึงเราจะโตช้าแต่ก็โตเติบอย่างยั่งยืนนะ"]
const whitemoke = ["ปลูกไว้จะทำให้เกิดความสุข ความบริสุทธิ์ หลุดพ้นจากทุกข์ทั้งปวง และจะช่วยคุ้มครองปกป้องภัยอันตรายต่างๆ ถ้าปลูกเราไว้ เราจะปกป้องเธอเอง",
"ต้นนี้เป็นต้นที่สื่อถึงความบริสุทธิ์ เชื่อกันว่าผู้ใดปลูกไว้จะนำมาซึ่งความสุข หลุดพ้นจากทุกข์ทั้งปวง ทั้งยังจะช่วยปัดเปล่าอันตรายต่างๆ ไม่ให้เข้ามาแนบกายโอม สิ่งไม่ดีจงออกไป สิ่งดีๆจงเข้ามา เพี้ยง !!"]
const makok = ["มะกอกเป็นสัญลักษณ์แทนอิสรภาพและความหวัง สื่อถึงเสรีภาพและมิตรภาพ การปลูกต้นมะกอกโอลีฟ จะทำให้อายุยืนยาวอีกด้วย เราจะอยู่ด้วยกันไปจนแก่เฒ่าเลยนะ","เป็นต้นที่เจริญเติบโตได้ง่าย แม้สภาพแวดล้อมที่ไม่อุดมสมบูรณ์ ยังสื่อถึงเสรีภาพและมิตรภาพและยังเชื่อกันว่า ผู้ใดที่มีหรือปลูกไว้จะประสบความสำเร็จอย่างที่ตั้งใจ เราจะคอยเป็นกำลังใจในการเติบโต การก้าวหน้าของเธอต่อไปนะ"]
const maple = ["ต้นเมเปิ้ลเป็นสัญลักษณ์ของความสมดุล การบูชา เวทย์มนตร์ คำมั่นสัญญา ชีวิตยืนยาว ความเอื้อเฟื้อ ความหมายที่ซ้อนอยู่ในต้นเมเปิ้ลคือ ความสามารถในการปรับตัวในสภาพดินและสภาพอากาศที่แตกต่างกันได้ และเมื่อถึงเวลาแห่งการร่วงโรย ใบต้นเมเปิ้ลยังคงสวยงามและน่าดึงดูดด้วยสีแดงชาดราวโลหิต ทำให้สวยงามยิ่งกว่าเดิม หากผู้ใดพบเห็นต้นเมเปิ้ลอายุเก่าแก่ ถือว่าคุณได้รับพรให้มีชีวิตที่ดีงามและยืนยาว",
"เป็นต้นไม้ที่แสดงถึงความแข็งแกร่งและความยืดหยุ่น เพราะสามารถทนต่อสภาพอากาศที่เลวร้ายได้ เชื่อกันว่าสีแดงของใบเมเปิลจะนำความรัก ความสวยงามมาสู่คนรอบข้างของผู้นั้น เราพร้อมมอบใบเมเปิลให้เธอเสมอนะ <3"]
const lemon = ["ต้น lemon เป็นสัญลักษณ์ของความสะอาด การรักษา ซึ่งสัญลักษณ์ได้มากจากความสามารถของผล lemon ที่สามารถชำละล้างร่างกายและจิตใจ หรือพลังงานเชิงลบที่เราสะสมมาจากการดำเนินชีวิตได้ นอกจากนี้ผลของ Lemon ยังช่วยให้ร่างกายและจิตวิญญาณแข็งแรงเปรียบเสมือนมีโล่ป้องกันสุขภาพอยู่ตลอดเวลา นอกจากนี้ต้น lemon ยังมีความหมายเกี่ยวกับชีวิตที่ยาวนาน ด้วยความที่ต้น Lemon เป็นต้นไม้ที่ใบเขียวตลอดทั้งชีวิต ทำให้มักมีผู้คนมาขอพรเกี่ยกับชีวิตให้พวกเค้าดำรงชีวิตอยู่อย่างมีความหมาย",
"แสดงถึงความชัดเจน การสื่อสารและความสุข ชวนให้นึกถึงความสดชื่น สดใส ทั้งจากรสชาติที่เปรี้ยวและสีสันที่สดใส ผลของเลมอนยังช่วยชำระล้างร่างกายและจิตใจอีกด้วย"]
const tubtim = ["ต้นทับทิม ถือเป็นต้นไม้ที่มีความศักดิ์สิทธิ์ และมีความเชื่อจากหลายวัฒนธรรม ต้นทับทิมเป็นสัญลักษณ์ของ ความอุดมสมบูรณ์ ความสวยงามและชีวิตที่เป็นนิรันด์ ตามความเชื่อของกรีก ทับทิมเป็นผลไม้คู่กับ เทพีแห่งความอุดมสมบูรณ์และการเก็บเกี่ยว ดิมีเทอร์ นอกจากนี้ตามความเชื่อของประเทศจีนนั้น ต้นทับทิมเป็นต้นไม้ค่อยขจัดสิ่งชั่วร้าย ใครปลูกไว้หน้าบ้านสามารถช่วยป้องกันภูติผีปีศาจที่คอยติดตามมาได้ และในพิธีแต่งงานมักมีผลทับทิมอยู่ซึ่งเปรียบเสมือนการอวยพรให้มีลูกเยอะๆ",
"เปรียบเป็น 'อัญมณี' ของผลไม้ และเป็นสัญลักษณ์ของความสมบูรณ์ผู้คนมักปลูกไว้ที่บ้านเพื่อป้องกันสิ่งที่ไม่ดี และนำความสมบูรณ์พร้อมมาให้"]
const intanin = ["ต้นอินทนิล มีความหมายว่า อดทน แข็งแกร่ง เนื่องจากต้นอินทนิลเป็นไม้อายุยืน สามารถปลูกได้ในทุกสภาพแวดล้อมเหมือนบอกเป็นนัยว่า ผู้ที่ได้ต้นอินทนิลจงอดทนและสามารถผ่านอุปสรรคไปได้อย่างแข็งขัน เพื่อเริ่มเบิกบานดั่งดอกไม้ที่สวยงาม","เป็นต้นที่มีอายุยืนยาว สามารถเจริญเติบโตได้ในทุกสภาพแวดล้อม จึงแสดงถึงความแข็งแกร่ง และพร้อมปรับตัวกับสถานการณ์ต่างๆ และช่อดอกสีม่วงนั้น ที่มีลักษณะเกาะกันเป็นกลุ่ม แนบชิดกัน ยังแสดงถึงความรัก ความกลมเกลียวที่พร้อมร่วมสุขทุกข์กันขอให้รอบกายของเธอ รายล้อมไปด้วยหมู่ผู้คนที่ให้การสนับสนุนเธอ ดังเช่นช่อดอก และมีจิตใจที่เข้มแข็ง ดังลำต้นของต้นอินทนิลนี้นะ"]
const cherrybrossom = ["ความหมายของดอกซากุระในภาษาดอกไม้ คือ ความสำเร็จ การประสบผลสำเร็จ และหัวใจที่เข้มแข็ง ซากุระจึงเป็นสัญลักษณ์ของความเป็นทหารและวิถีความเป็นบูชิโดของญี่ปุ่น","คุณลักษณะเด่นของซากุระก็คือ เมื่อร่วงจะร่วงพร้อมกันหมด ซากุระจึงเป็นสัญลักษณ์ของเลือดทหาร และซามูไรของญี่ปุ่นมีดอกซากุระในเกาหลี,สหรัฐอเมริกา,แคนาดา,จีน หรือที่อื่นๆ แต่ไม่มีกลิ่น ขณะที่ซากุระของญี่ปุ่นนั้นผู้คนจำนวนมากยกย่องชื่นชมกลิ่นของมัน และมักจะกล่าวฝากไว้ในบทกวีดอกซากุระของญี่ปุ่นนี้ ในภาษาอังกฤษมีคำเรียกทั่วไปว่า 'cherry blooms' หรือ 'cherry blossom' หรือไม่ก็ 'Japanese Flowering Cherry'"]

class Dashboard extends React.Component {
  constructor(props) { 
      super(props);
      // const name = this.props.location.Data
      // console.log(name)
      this.state = {     
        pictures: [],
        deviceMode: '', 
        isLoading: false,
        users: [],
        error: null,
        timestamp:  [],
        allMood: [],
        Tree:[],
        random: 0,
        countLevelTree: 'none',
        activebutton: false,
        curTree: '',
        curStep: '',
        allData: [],
        // test tree faii part
        buttonTree: [],
        update: false,
        imageTree: 'none',
        haveUser: false,
        nameTree: 'none',
        setNameTree: [],
        stopAdd: 0,
        message: 99,
        buttonPress: false,
        modalTree: false,
        prevnameTree: 'none',
        prevImageTree: 'none',
        stateUser: 'none',
        linkUrlSet: [],
        numberTreeName: 99,
        firstfetch: 0
      };
      this.onDrop = this.onDrop.bind(this);
      // this.onChange = this.onChange.bind(this);
    }
  state = { activeItem: 'home' }

  fetchUsers = () => {
    // Where we're fetching data from
    let username = localStorage.getItem('name')
    // let username = "Som O Janha"
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username: username })
  };
    fetch('https://psyjai-dashboard.herokuapp.com/emotion_class',requestOptions)
      // We get the API response and receive data in JSON format...
      .then(response => response.json())
      // ...then we update the users state
      .then(async data => {
          // console.log(data) 
          this.setState({
            users: data,
            isLoading: false,
          })
          let obj = []
          let allMood = []
          let setDate = []
          let setData = []
          // check user in tree database
          //  let username = "Test name"
          let username = localStorage.getItem('name')
          let checkUser = firebase.database().ref().child("users").child(username).child("collectTree")

          // check user doesn't bot test
          if(data.length == 0){
            this.setState({
              countLevelTree: 0,
              message: "คุณยังไม่มีต้นไม้เลย มาปลูกต้นไม้กันเถอะ !",
              stateUser: "firstTime"
            })
            allMood.push({
              date: [],
              mood: 'none',
            })
            // check user have data chabot in database
          }else{

            //  get data Tree in database (firebase)
            let collectTree = []
            let collectDate = []
            let getTreeTime = 0  
            let checkCollectTree = firebase.database().ref().child("users").child(username).child("collectTree")
            await checkCollectTree.once("value",snap =>{
              if(snap.val() != null){
                snap.forEach(tree => {
                  collectTree.push(tree.val())
                })    
              }      
            })

            let checkDateReward = firebase.database().ref().child("users").child(username).child("date")
            await checkDateReward.once("value",snap =>{
              if(snap.val() != null){
                snap.forEach(date => {
                  collectDate.push(date.key)
                })   
              }  
            })

            let checkTreeTime = firebase.database().ref().child("users").child(username).child("treeTime")
            await checkTreeTime.once("value",snap =>{
              if(snap.val() != null){
                getTreeTime =  snap.val() 
              }       
            })

            let sizeCollectTree = collectTree.length
            if(sizeCollectTree!= 0 ){
              this.setState({
                countLevelTree: getTreeTime,
                stateUser: "Indatabase",
                nameTree: collectTree[sizeCollectTree-1]
              })          
            }else if(sizeCollectTree == 0 ){
              this.setState({
                countLevelTree: 0,
                message: "มาปลูกต้นไม้กันเถอะ",
                stateUser: "firstTime"
              })
            }

          // check date in data to add obj list
          for(let i in data){
            let dateSplit = data[i].timestamp.split("T")
            let count = 0
            if(setDate.length == 0){
              // console.log(i,data[i])
              if(data[i].variable == "topic"){
                setDate.push({
                  [dateSplit[0]]:{
                    levelPre: "none",
                    levelPost: "none",
                    mood: "none",
                    topic: data[i].value
                  }
                })
              }        
              if(data[i].variable == "PostSeverityemo"){
                setDate.push({
                  [dateSplit[0]]:{
                    levelPre: "none",
                    levelPost: data[i].value,
                    mood: "none",
                    topic: "none"
                  }
                })
              }        
              if(data[i].variable == "PreSeverityemo"){
                setDate.push({
                  [dateSplit[0]]:{
                    levelPre: data[i].value,
                    levelPost: "none",
                    mood: "none",
                    topic: "none"
                  }
                })
              }       
              if(data[i].variable == "emotion_class"){
                setDate.push({
                  [dateSplit[0]]:{
                    levelPre: "none",
                    levelPost: "none",
                    mood: data[i].value,
                    topic: "none"
                  }
                })
              }            
            }else{
              for(let a in setDate){
                // console.log(Object.values(setDate[a])[0].mood)
                if(dateSplit[0] == Object.keys(setDate[a])[0]){
                   if(data[i].variable == "emotion_class"){
                      Object.values(setDate[a])[0].mood = data[i].value
                   }   
                   if(data[i].variable == "PreSeverityemo"){
                      Object.values(setDate[a])[0].levelPre = data[i].value
                   }   
                    if(data[i].variable == "PostSeverityemo"){
                      Object.values(setDate[a])[0].levelPost = data[i].value
                   }   
                   if(data[i].variable == "topic"){
                      Object.values(setDate[a])[0].topic = data[i].value
                   }   
                   count++     
                }
              }

              if(count == 0){
                if(data[i].variable == "topic"){
                  setDate.push({
                    [dateSplit[0]]:{
                      levelPre: "none",
                      levelPost: "none",
                      mood: "none",
                      topic: data[i].value
                    }
                  })
                }        
                if(data[i].variable == "PostSeverityemo"){
                  setDate.push({
                    [dateSplit[0]]:{
                      levelPre: "none",
                      levelPost: data[i].value,
                      mood: "none",
                      topic: "none"
                    }
                  })
                }        
                if(data[i].variable == "PreSeverityemo"){
                  setDate.push({
                    [dateSplit[0]]:{
                      levelPre: data[i].value,
                      levelPost: "none",
                      mood: "none",
                      topic: "none"
                    }
                  })
                }       
                if(data[i].variable == "emotion_class"){
                  setDate.push({
                    [dateSplit[0]]:{
                      levelPre: "none",
                      levelPost: "none",
                      mood: data[i].value,
                      topic: "none"
                    }
                  })
                }            
              }
            }
          }

          // console.log(setDate)
          for(let i in setDate){
            let img = ""
            let countButton = 0
            if(Object.values(setDate[i])[0].mood != "none"){
                // console.log(Object.keys(setDate[i])[0])
                if( Object.values(setDate[i])[0].mood == "สบายๆ"){
                  img = green
                }
                if( Object.values(setDate[i])[0].mood == "เฉยๆ"){
                  img = white
                }
                if( Object.values(setDate[i])[0].mood == "อารมณ์ดี"){
                  img = yellow
                }
                if( Object.values(setDate[i])[0].mood == "สงบ"){
                  img = green
                }
                if( Object.values(setDate[i])[0].mood == "อารมณ์ไม่ดี"){
                  img = red
                }
                if( Object.values(setDate[i])[0].mood == "ซึมๆ"){
                  img = blue
                }
                
                obj.push({
                  date: Object.keys(setDate[i])[0],
                  title: img,
                })

                
                // console.log(Object.keys(setDate[i])[0])
                // check user in tree database
                if(this.state.linkUrlSet != 'none' || this.state.linkUrlSet != null){
                  // case have user in database
                  if(collectTree.length != 0){
                    for(let n in collectDate){
                      // console.log(collectDate[n],Object.keys(setDate[i])[0])
                      if(collectDate[n] == Object.keys(setDate[i])[0]){
                        // console.log("match ! ",n,Object.keys(setDate[i])[0])
                        this.state.buttonTree.push(true)
                        countButton++
                      }
                    }

                    if(countButton == 0){
                      this.state.buttonTree.push(false)
                    }

                     // case have user in database
                    if(collectTree[sizeCollectTree-1] == "cherry"){
                    this.setState({
                        numberTreeName: 0 
                    })
                    }
                    if(collectTree[sizeCollectTree-1] == "cherrybrossom"){ 
                      this.setState({ 
                        numberTreeName: 1 
                      })
                    }
                    if(collectTree[sizeCollectTree-1] == "intanin"){
                        this.setState({ 
                          numberTreeName: 2 
                        })
                    }
                    if(collectTree[sizeCollectTree-1] == "lemon"){ 
                      this.setState({ 
                        numberTreeName: 3 
                      })
                    }
                   if(collectTree[sizeCollectTree-1] == "lingnum"){ 
                      this.setState({ 
                        numberTreeName: 4 
                       })
                     }
                   if(collectTree[sizeCollectTree-1] == "maple"){ 
                      this.setState({ 
                        numberTreeName: 5 
                       })
                     }
                   if(collectTree[sizeCollectTree-1] == "ทับทิม"){ 
                      this.setState({ 
                        numberTreeName: 6 
                       })
                     }
                   if(collectTree[sizeCollectTree-1] == "มะกอก"){ 
                      this.setState({ 
                        numberTreeName: 7 
                     })
                   }
                   if(collectTree[sizeCollectTree-1] == "โมกขาว"){ 
                      this.setState({ 
                        numberTreeName: 8 
                       })
                   }

                   if(this.state.firstfetch == 0){
                    if( getTreeTime == 1 || getTreeTime == 2){
                      this.setState({
                        imageTree: dataLinkLocal[this.state.numberTreeName][0],
                        message: "ต้นไม้ของคุณยังเป็นต้นกล้าเริ่มต้น เข้ามาทำให้มันเติบโตกว่านี้กันเถอะ !"
                      })
                    } 
                    if( getTreeTime == 3 || getTreeTime == 4){   
                      this.setState({
                        imageTree: dataLinkLocal[this.state.numberTreeName][1],
                        // imageTree: dataLinkLocal[numberTree][1],
                        message: "ต้นไม้ของคุณได้โตขึ้นอีกขั้นแล้ว มาทำให้มันโตขึ้นกว่านี้กันเถอะ"
                      }) 
                    }    
                    if( getTreeTime == 5 || getTreeTime == 6){    
                      this.setState({
                        imageTree: dataLinkLocal[this.state.numberTreeName][2],
                        // imageTree: dataLinkLocal[numberTree][2],
                        message: "มาปลูกต้นไม้กันนน"
                      })
                    }     
                    if( getTreeTime == 7 || getTreeTime == 8){  
                      this.setState({
                        imageTree: dataLinkLocal[this.state.numberTreeName][3],
                        // imageTree: dataLinkLocal[numberTree][3],
                        message: "มาปลูกต้นไม้กันนน :-)"
                      })  
                    }     
                    if( getTreeTime == 9){    
                      this.setState({
                        imageTree: dataLinkLocal[this.state.numberTreeName][4],
                        // imageTree: dataLinkLocal[numberTree][4],
                        message: "เย้ ต้นไม้ของคุณโตเต็มวัยแล้ว !"
                      })
                    }     
                   }
                }
              } 

                setData.push({
                  date: Object.keys(setDate[i])[0],
                  mood: Object.values(setDate[i])[0].mood,
                  levelPre: Object.values(setDate[i])[0].levelPre,
                  levelPost: Object.values(setDate[i])[0].levelPost,
                  topic: Object.values(setDate[i])[0].topic
                })

                allMood.push({
                  date: Object.keys(setDate[i])[0],
                  mood: Object.values(setDate[i])[0].mood,
                })
            }     
          }
   
          this.setState({
            timestamp: obj,
            allData: setData
          }, ()=>{
             localStorage.setItem('allMood',JSON.stringify(allMood))
          })
          }  
        }
      )
      // Catch any errors we hit and update the app
      .catch(error => this.setState({ error, isLoading: false }));
      const min = 0
      const max = 2
      const rand = Math.floor(min + Math.random() * (max - min));
      this.setState({ random: this.state.random + rand });  
  }

  componentDidMount() {
    this.handleScreen()
    this.getTreeLink()
    this.setImageTreePath()   
    this.fetchUsers();
  }

  //  function for get all name tree in database
  getTreeLink = async () => {
    // console.log("from getLinkTree")
    let getNameTreeAll = firebase.database().ref().child("Tree")
    let setname = []       
    await getNameTreeAll.on("value",async data => {        
      await data.forEach(i => {
        setname.push(i.key)
      })
    })
      this.setState({
        setNameTree: setname
      })
      // console.log("set state tree name already")

  }

  onDrop(picture) {
      this.setState({
          pictures: this.state.pictures,
      });
  }

  countLevel = (c) =>{
    c=c+1
    this.setState({
      countLevelTree: c
    })
   // console.log(this.state.countLevelTree)
  }

  // function for modal
  modalToggle = () =>{
    this.setState({
      modalTree: !this.state.modalTree
    })
  }

  // function for get link url image ( but get slowly ;-; )
  setImageTreePath = async () =>{
    let linkset = []
    // console.log("from setLinkPath")     
      let getUrlTree = firebase.database().ref().child("Tree")
      await getUrlTree.on("value" , data => { 
         linkset.push(Object.values(data.val())) 
        //  console.log(Object.values(data.val()))     
      })
    this.setState({
      linkUrlSet: linkset[0]
    })    
    localStorage.setItem("linkUrlImg",linkset[0])
  }

  // function for click get reward
  handleClickTree = async (date,username,numberButton) => {

    this.setState({firstfetch: 1})
     // new tree and first time
    if(this.state.stateUser == "firstTime" && this.state.stopAdd == 0){
      this.setState({ stopAdd: 1,firstfetch:0})
      const min = 0
      const max = 8
      const numRand = Math.floor(min + Math.random() * (max - min));
      this.setState({       
        imageTree: dataLinkLocal[numRand][0],
        countLevelTree: 1,
        nameTree: this.state.setNameTree[numRand],
        numberTreeName: numRand,
        stopAdd: 0,
        stateUser: "Indatabase",
        message: "ต้นไม้ของคุณได้โตขึ้นอีกขั้นแล้ว มาทำให้มันโตขึ้นกว่านี้กันเถอะ !"
      },()=>{
        // console.log("set state already !")
        firebase.database().ref().child("users").child(username).child("treeTime").set(1)
        firebase.database().ref().child("users").child(username).child("date").set({ [date]: 1})
        firebase.database().ref().child("users").child(username).child("collectTree").set([this.state.setNameTree[numRand]])
      })
    }

    // have tree in database
    if(this.state.stateUser == "Indatabase"){
      // another times (not 9)
      if(this.state.countLevelTree !=9 && this.state.countLevelTree < 10 && this.state.stopAdd == 0){
        this.setState({ stopAdd: 1,firstfetch:0})
        var updates = {};
        let countTree = this.state.countLevelTree+1
        updates['/treeTime'] = this.state.countLevelTree+1;
        // updates['/date/' + date] = 1;
        firebase.database().ref().child("users").child(username).child("treeTime").set(countTree)
        firebase.database().ref().child("users").child(username).child("date").child(date).set(1)
        if(countTree == 1 || countTree == 2){
          this.setState({
            imageTree: dataLinkLocal[this.state.numberTreeName][0],     
            stopAdd: 0,
            countLevelTree: countTree,
            message: "ว้าว ได้รางวัลอีกแล้ว ! ต้นไม้ของคุณได้โตขึ้นอีกขั้น มาทำให้มันโตขึ้นกว่านี้กันเถอะ"
          })   
        }
        if(countTree == 3 || countTree== 4){
          // console.log(dataLinkLocal[this.state.numberTreeName][1])
          this.setState({
            imageTree: dataLinkLocal[this.state.numberTreeName][1],     
            stopAdd: 0,
            countLevelTree: countTree,
            message: "เย้ ! ต้นไม้ของคุณได้โตขึ้นอีกขั้นแล้ว มาทำให้มันโตขึ้นกว่านี้กันเถอะ"
          })   
        }
        if(countTree == 5 || countTree == 6){
          this.setState({
            imageTree: dataLinkLocal[this.state.numberTreeName][2],     
            stopAdd: 0,
            countLevelTree: countTree,
            message: "มาปลูกต้นไม้กันนน"
          })   
        }
        if(countTree == 7 || countTree == 8){
          this.setState({
            imageTree: dataLinkLocal[this.state.numberTreeName][3],     
            stopAdd: 0,
            countLevelTree: countTree,
            message: "มาปลูกต้นไม้กันนน :-)"
          })   
        }
        if(countTree == 9){
          this.setState({
            imageTree: dataLinkLocal[this.state.numberTreeName][4],     
            stopAdd: 0,
            countLevelTree: countTree,
            message: "เย้ ต้นไม้ของคุณโตเต็มวัยแล้ว !"
          })   
        }   
        // console.log("set state already !")  
      }
      // times at 9
      if(this.state.countLevelTree == 9 && this.state.stopAdd == 0){
        this.setState({ 
          stopAdd: 1,
          firstfetch:0,
          prevImageTree: dataLinkLocal[this.state.numberTreeName][4],
          prevnameTree: this.state.nameTree
        })
        const min = 0
        const max = 8
        const numRand = Math.floor(min + Math.random() * (max - min));
        var updates = {};
        updates['/treeTime'] = 1;
        updates['/date/' + date] = 1;
        this.setState({
          modalTree: true,
          imageTree: dataLinkLocal[numRand][0],     
          stopAdd: 0,
          countLevelTree: 1,
          nameTree: this.state.setNameTree[numRand],
          numberTreeName: numRand,
          message: "ว้าว เยี่ยมไปเลย ~! คุณได้รับต้นไม้ต้นใหม่ มาทำให้มันโตขึ้นกว่านี้กันเถอะ :-)"
        })
        let arrayTree = []
        let listTree = firebase.database().ref().child("users").child(username).child("collectTree")
        await listTree.once("value", data =>{
          // console.log(data.val())
          data.forEach(i=>{
            arrayTree.push(i.val())
          })
        })
        arrayTree.push(this.state.setNameTree[numRand])
        firebase.database().ref().child("users").child(username).update(updates)
        firebase.database().ref().child("users").child(username).child("collectTree").set(arrayTree)
      }
    }
    this.state.buttonTree[numberButton] = true
    let items = [...this.state.buttonTree];
    this.setState({ items }) 
  }



  handleScreen = () =>{
    const mediaMatchPortrait = window.matchMedia('(orientation: portrait)')
    const mediaMatchLanscape = window.matchMedia('(orientation: landscape)')
    const mediaMatchMobilePortrait = window.matchMedia('(max-width: 480px)');
    const mediaMatchMobileLanscape = window.matchMedia('(min-width: 480px) and (max-width: 900px) and (orientation: landscape)');
    const mediaMatchTabletLanscape = window.matchMedia('(min-width: 900px)');
    const mediaMatchTabletPortrait = window.matchMedia('(min-width: 480px) and (max-width: 900px) and (orientation: portrait)');
    
    if(mediaMatchLanscape.matches){
      this.setState({
        deviceSide: "landscape"
     })
    }

    if(mediaMatchPortrait.matches){
      this.setState({
        deviceSide: "portrait"
     })
    }

    if(mediaMatchMobilePortrait.matches){
      this.setState({
          deviceMode: "mobilePortrait"
      })
    }
    
    if(mediaMatchMobileLanscape.matches){
      this.setState({
        deviceMode: "mobileLandScape"
      })
    }

    if(mediaMatchTabletLanscape.matches){
      this.setState({
        deviceMode: "tabletLandScape"
      })
    }

    if(mediaMatchTabletPortrait.matches){
      this.setState({
          deviceMode: "tabletPortrait"
      })
    }

    mediaMatchTabletPortrait.addEventListener("change", e => {
      // console.log("onListernerTabletPortrait: ",e.matches,e.media)
      if(e.matches){
        this.setState({
          deviceMode: "tabletPortrait"
        })
      }
    })

    mediaMatchMobilePortrait.addEventListener("change", e => {
      // console.log("onListernerMobilePort: ",e.matches,e.media)
      if(e.matches){
        this.setState({
          deviceMode: "mobilePortrait"
        })
      }
    })

    mediaMatchMobileLanscape.addEventListener("change", e => {
      // console.log("onListernerMobileLand: ",e.matches,e.media)
      if(e.matches){
        this.setState({
          deviceMode: "mobileLandScape"
        })
      }
    })

    mediaMatchTabletLanscape.addEventListener("change", e => {
      // console.log("onListernerTabletLand: ",e.matches,e.media)
      if(e.matches){
        this.setState({
          deviceMode: "tabletLandScape"
        })
      }
    })

    mediaMatchPortrait.addEventListener("change", e => {
      // console.log("onListernerPortrait: ",e.matches,e.media)
      if(e.matches){
        this.setState({
          deviceSide: "portrait"
        })
      }
    })

    mediaMatchLanscape.addEventListener("change", e => {
      // console.log("onListernerLandscape: ",e.matches,e.media)
      if(e.matches){
        this.setState({
          deviceSide: "landscape"
        })
      }
    })
  }


  renderEvent = (eventInfo,allData) =>{
      var mood = "white"
      var setButton = eventInfo.event._context.options.events
      let numberButton = 99
      for(let i in setButton){
        if(eventInfo.event._context.options.events[i].date == eventInfo.event.startStr){
          numberButton = i
        }
      }

      if( eventInfo.event.title == red){
        mood = 'red'
      }
      if( eventInfo.event.title == green){
        mood = 'green'
      }
      if( eventInfo.event.title == yellow){
        mood = 'yellow'
      }
      if( eventInfo.event.title == blue){
        mood = 'blue'
      }
      var text = eventInfo.event.title
      var date = eventInfo.event.startStr
      var pre_value = 1
      var post_value = 1
      var topic = 'none'
      // console.log("allData: ",allData)
      for(let i in allData){
          if(allData[i].date == date){
            // console.log("yeahhh: ",levelPre[i].preValue)
            pre_value = allData[i].levelPre
            post_value = allData[i].levelPost
            if(allData[i].topic != 'none'){
              topic = allData[i].topic
            }else{
              topic = 'none'
            }
            
            
          }        
      }
      // console.log(date,pre_value,post_value,mood)
      var imgLevelPre,imgLevelPost
      if(pre_value == 0){
        imgLevelPre = mood+"1"
      }else{
        imgLevelPre = mood+pre_value.toString()
      }

      if(post_value == 0){
        imgLevelPost = mood+"1"
      }else{
        imgLevelPost = mood+post_value.toString()
      }
      // var imgLevelPre = mood+pre_value.toString()
      // var imgLevelPost = mood+post_value.toString()
      if(mood == "white"){
        imgLevelPre = "white"
        imgLevelPost = "white"
      }
      
    return (
        <div>
          {
            this.state.modalTree == false &&
            <Popup className="popupDash" trigger={
                <Image src={text} id="imageEmotion"/>  
            } hoverable>
            <Popup.Header>
              {
                topic != 'none' && mood == "red" ?
                <Row><Label style={{margin: '10px',fontFamily: 'Mitr',fontWeight:'300'}} color='red' horizontal>{topic}</Label></Row> :
                topic != 'none' && mood == "blue" ? 
                <Row><Label style={{margin: '10px',fontFamily: 'Mitr',fontWeight:'300'}} color='blue' horizontal>{topic}</Label></Row> :
                topic != 'none' && mood == "yellow" ?
                <Row><Label style={{margin: '10px',fontFamily: 'Mitr',fontWeight:'300'}} color='yellow' horizontal>{topic}</Label></Row> :
                topic != 'none' && mood == "green" ?
                <Row><Label style={{margin: '10px',fontFamily: 'Mitr',fontWeight:'300'}} color='teal' horizontal>{topic}</Label></Row> :
                topic != 'none' && mood == "white" ?
                <Row><Label style={{margin: '10px',fontFamily: 'Mitr',fontWeight:'300'}} color='grey' horizontal>{topic}</Label></Row> :
                null  
              }     
              {
               mood != "white" && (
                <Row>
                  {
                    pre_value != "none" && post_value == "none" &&
                    <Col>
                      <Image src={"./moodImg/"+imgLevelPre+".png"} size='tiny' />
                    </Col>
                  }
                  {
                    post_value != "none"&&
                    <Col>
                      <Image src={"./moodImg/"+imgLevelPost+".png"} size='tiny' />
                    </Col>
                  }
                  {
                    pre_value == "none" && post_value == "none" &&
                    <Col>
                      <Image src={text} size='tiny' />
                    </Col>
                  }
               </Row> ) 
              }
              {
               mood == "white" && (
                <Row>
                  <Col>
                    <Image src={"./moodImg/"+imgLevelPost+".png"} size='tiny' />
                    <p style={{fontSize:'15px',fontWeight:'500'}} id="fontPopup"></p>
                  </Col>
               </Row> ) 
              }
             
            </Popup.Header>
            {
                <Row style={{justifyContent: 'center'}}>
                  <Button disabled={this.state.buttonTree[numberButton]} id="buttonPrize" size='mini'
                  onClick={ async () => { 
                      let username = localStorage.getItem('name')
                      //  let username = "Test name"       
                      await this.handleClickTree(date,username,numberButton)            
             
                                         
                  }}>
                    รับรางวัล</Button>
                </Row>          
              }
          </Popup>
          }
          
      </div>
    )
  }
  
  render() {
    
    return (
    <>
    <Modal
        closeIcon
        onClose={() => this.modalToggle()}
        id="modalProfile"
        open={this.state.modalTree}
        // open={true}
        centered
        size={'tiny'}
     >
    <Modal.Header id="messageHeadModal">ยินดีด้วย ! คุณได้สะสมต้น {this.state.prevnameTree}</Modal.Header>
    <Image style={{margin: '10px'}} size='medium' src={this.state.prevImageTree} wrapped />
    <Modal.Content scrolling>
      {
        this.state.prevnameTree == "cherry" &&
        <Modal.Description id="modalDesTree">
        {cherry[this.state.random]}
      </Modal.Description>
      }
      {
        this.state.prevnameTree == "cherrybrossom" &&
        <Modal.Description id="modalDesTree">
        {cherrybrossom[this.state.random]}
      </Modal.Description>
      }     
      {
        this.state.prevnameTree == "intanin" &&
        <Modal.Description id="modalDesTree">
        {intanin[this.state.random]}
      </Modal.Description>
      }      
      {
        this.state.prevnameTree == "lemon" &&
        <Modal.Description id="modalDesTree">
        {lemon[this.state.random]}
      </Modal.Description>
      }      
      {
        this.state.prevnameTree == "lingnum" &&
        <Modal.Description id="modalDesTree">
        {lingnum[this.state.random]}
      </Modal.Description>
      }      {
        this.state.prevnameTree == "maple" &&
        <Modal.Description id="modalDesTree">
        {maple[this.state.random]}
      </Modal.Description>
      }      {
        this.state.prevnameTree == "ทับทิม" &&
        <Modal.Description id="modalDesTree">
        {tubtim[this.state.random]}
      </Modal.Description>
      }      {
        this.state.prevnameTree == "มะกอก" &&
        <Modal.Description id="modalDesTree">
        {makok[this.state.random]}
      </Modal.Description>
      }      {
        this.state.prevnameTree == "โมกขาว" &&
        <Modal.Description id="modalDesTree">
        {whitemoke[this.state.random]}
      </Modal.Description>
      }
     </Modal.Content>
  </Modal> 
           
     {
      (this.state.deviceMode == "mobilePortrait") &&
     <div style={{height: '100vh',overflowY: 'scroll'}}>
        <Grid id="gridCard">
          <Grid.Column id="gridCalender">     
            <Segment id="calendar" only='computer'>
            {this.error ? <p>{this.error.message}</p> : null}
            <FullCalendar 
                plugins={[ dayGridPlugin, interactionPlugin ]}
                initialView="dayGridMonth"   
                titleFormat={{ year: 'numeric', month: 'short' }}      
                handleWindowResize = {true}
                contentHeight={100}
                eventBorderColor="transparent"
                eventBackgroundColor="transparent"
                showNonCurrentDates={false}
                fixedWeekCount={false}
                eventContent={(eventInfo) => 
                  this.renderEvent(eventInfo,this.state.allData)}
                events={this.state.timestamp}
                // dateClick={this.handleDateClick}
            />
          </Segment>  
          </Grid.Column>
          <Grid.Column id="gridTreeDash">
          <Message positive id="messageCard"> 
              {
                (this.state.imageTree != 'none' && this.state.countLevelTree != 'none') &&
                  <Message.Header id="messageTree">{this.state.message}</Message.Header>
              }

               {
                ((this.state.imageTree == 'none' || this.state.countLevelTree == 'none') && this.state.countLevelTree != 0) &&
                    <Loader id="imgTree" active/> 
                }
                 {
                (this.state.countLevelTree == 0) && 
                  <Message.Header id="messageTree">มาปลูกต้นไม้กันเถอะ :-)</Message.Header>       
                }
               
              </Message> 
            <Segment id="textTree">
            {
              ((this.state.imageTree != 'none' || this.state.countLevelTree != 'none') && this.state.countLevelTree != 0) &&
              <Label id="labelTree" as='a' ribbon>{this.state.nameTree}</Label>
            }
              {
                    this.state.imageTree != 'none' &&
                     <Image 
                      src={this.state.imageTree}
                      size='small'
                      id="imgTree"
                    />
                  }
                  {
                   ((this.state.imageTree == 'none' || this.state.countLevelTree == 'none') && this.state.countLevelTree != 0) &&
                      <Loader id="imgTree"  active/>
                  }
                  { (this.state.countLevelTree == 0 ) &&
                     <Image 
                      src="https://dl.dropbox.com/s/hunmrl1trl1fa87/FF13B144-755C-4A35-9205-94A251C24EF0.png"
                      size='tiny'
                      id="imgTree"
                    />
                  }
              </Segment>
          </Grid.Column>
        </Grid>
        <div style={{height: "150px"}}></div>
      </div>
    } 
     {
      ( this.state.deviceMode == "mobileLandScape" ) &&
     <div style={{width: window.innerWidth, height: '100vh',overflowY: 'scroll'}}>
        <Grid id="gridCard">
          <Grid.Column id="gridCalender">     
            <Segment id="calendar" only='computer'>
            {this.error ? <p>{this.error.message}</p> : null}
            <FullCalendar 
                plugins={[ dayGridPlugin, interactionPlugin ]}
                initialView="dayGridMonth" 
                contentHeight={50}
                titleFormat={{ year: 'numeric', month: 'short' }}         
                handleWindowResize = {true}
                eventBorderColor="transparent"
                eventBackgroundColor="transparent"
                showNonCurrentDates={false}
                fixedWeekCount={false}
                eventContent={(eventInfo) => 
                  this.renderEvent(eventInfo,this.state.allData)}
                events={this.state.timestamp}
                // dateClick={this.handleDateClick}
            />
          </Segment>  
          </Grid.Column>
          <Grid.Column width={9} id="gridTreeDash">
          <Message positive id="messageCard"> 
              {
                (this.state.imageTree != 'none' && this.state.countLevelTree != 'none') &&
                  <Message.Header id="messageTree">{this.state.message}</Message.Header>
              }
               {
                ((this.state.imageTree == 'none' || this.state.countLevelTree == 'none') && this.state.countLevelTree != 0) &&        
                   <Loader id="imgTree"  active/>            
                }
                 {
                (this.state.countLevelTree == 0) && 
                  <Message.Header id="messageTree">{this.state.message}</Message.Header>  
                }

               
              </Message> 
            <Segment id="textTree">
            {
             ((this.state.imageTree != 'none' || this.state.countLevelTree != 'none') && this.state.countLevelTree != 0) &&
              <Label id="labelTree" as='a' ribbon>{this.state.nameTree}</Label>
            }
              {
                    this.state.imageTree != 'none' &&
                     <Image 
                      src={this.state.imageTree}
                      size='small'
                      id="imgTree"
                    />
                  }
                  {
                   ((this.state.imageTree == 'none' || this.state.countLevelTree == 'none') && this.state.countLevelTree != 0) &&
                      <Loader id="imgTree"  active/>
                  }
                  { (this.state.countLevelTree == 0 ) &&
                     <Image 
                      src="https://dl.dropbox.com/s/hunmrl1trl1fa87/FF13B144-755C-4A35-9205-94A251C24EF0.png"
                      size='small'
                      id="imgTree"
                    />
                  }
              </Segment>
          </Grid.Column>
        </Grid>
        <div style={{height: "150px"}}></div>
   </div>
    } 
    {
      (this.state.deviceMode == "tabletLandScape") &&
     <>
        <Grid id="gridDashboard">
          <Grid.Column id="gridCalender">     
            <Segment id="calendar">
            {this.error ? <p>{this.error.message}</p> : null}
            <FullCalendar 
                plugins={[ dayGridPlugin, interactionPlugin ]}
                initialView="dayGridMonth"         
                handleWindowResize = {true}
                eventBorderColor="transparent"
                eventBackgroundColor="transparent"
                showNonCurrentDates={false}
                fixedWeekCount={false}
                eventContent={(eventInfo) =>      
                   this.renderEvent(eventInfo,this.state.allData)
                }
                events={this.state.timestamp}
            />
          </Segment>  
          </Grid.Column>
          <Grid.Column id="gridTreeDash">
          <Message positive id="messageCard"> 
              {
                (this.state.imageTree != 'none' && this.state.countLevelTree != 'none') &&
                  <Message.Header id="messageTree">{this.state.message}</Message.Header>
              }
               {
                 ((this.state.imageTree == 'none' || this.state.countLevelTree == 'none') && this.state.countLevelTree != 0) &&
                    <Loader id="imgTree"  active/> 
                }
                 {
                (this.state.countLevelTree == 0) &&
                  <Message.Header id="messageTree">{this.state.message}</Message.Header>  

                }
               
              </Message> 
            <Segment id="textTree">
            {
               ((this.state.imageTree != 'none' || this.state.countLevelTree != 'none') && this.state.countLevelTree != 0) &&
              <Label id="labelTree" as='a' ribbon>{this.state.nameTree}</Label>
            }
              {
                    this.state.imageTree != 'none' &&
                     <Image 
                      // src={this.state.curStep[currentLevel]}
                      src={this.state.imageTree}
                      size='small'
                      id="imgTree"
                    />
                  }
                  {
                    ((this.state.imageTree == 'none' || this.state.countLevelTree == 'none') && this.state.countLevelTree != 0) &&
                      <Loader id="imgTree"  active/>
                  }
                  { (this.state.countLevelTree == 0 ) &&
                     <Image 
                      // src={this.state.curStep[currentLevel]}
                      src="https://dl.dropbox.com/s/hunmrl1trl1fa87/FF13B144-755C-4A35-9205-94A251C24EF0.png"
                      size='small'
                      id="imgTree"
                    />
                  }
              </Segment>
          </Grid.Column>
        </Grid>
      </>
    }

{
      (this.state.deviceMode == "tabletPortrait") &&
        <Grid id="gridCard">
          <Grid.Column id="gridCalender">     
            <Segment id="calendar" only='computer'>
            {this.error ? <p>{this.error.message}</p> : null}
            <FullCalendar 
                plugins={[ dayGridPlugin, interactionPlugin ]}
                initialView="dayGridMonth"         
                handleWindowResize = {true}
                eventBorderColor="transparent"
                eventBackgroundColor="transparent"
                showNonCurrentDates={false}
                fixedWeekCount={false}
                height={'70vw'}
                eventContent={(eventInfo) => 
                  this.renderEvent(eventInfo,this.state.allData)}
                events={this.state.timestamp}
                // dateClick={this.handleDateClick}
            />
          </Segment>  
          </Grid.Column>
          <Grid.Column id="gridTreeDash">
          <Message positive id="messageCard"> 
              {
                (this.state.imageTree != 'none' && this.state.countLevelTree != 'none') &&
                  <Message.Header id="messageTree">{this.state.message}</Message.Header>
              }
               {
               ((this.state.imageTree == 'none' || this.state.countLevelTree == 'none') && this.state.countLevelTree != 0) &&                 
                    <Loader id="imgTree"  active/>              
                }
                 {
                (this.state.countLevelTree == 0) &&               
                  <Message.Header id="messageTree">{this.state.message}</Message.Header>      
                }
               
              </Message> 
            <Segment id="textTree">
            {
             ((this.state.imageTree != 'none' || this.state.countLevelTree != 'none') && this.state.countLevelTree != 0) &&
              <Label id="labelTree" as='a' ribbon>{this.state.nameTree}</Label>
            }
              {
                    this.state.imageTree != 'none' &&
                     <Image 
                      src={this.state.imageTree}
                      size='small'
                      id="imgTree"
                    />
                  }
                  {
                    ((this.state.imageTree == 'none' || this.state.countLevelTree == 'none') && this.state.countLevelTree != 0) &&
                      <Loader id="imgTree"  active/>
                  }
                   { (this.state.countLevelTree == 0 ) &&
                     <Image 
                      src="https://dl.dropbox.com/s/hunmrl1trl1fa87/FF13B144-755C-4A35-9205-94A251C24EF0.png"
                      size='small'
                      id="imgTree"
                    />
                  }
              </Segment>
          </Grid.Column>
        </Grid>
    }
    </> 
    );
  }
}export default Dashboard;

