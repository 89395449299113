import React,{useState} from "react";
import avatar from '../images/matthew.png'; 
import '../css/dash.css'
import 'semantic-ui-css/semantic.css';
import { Menu,Modal,Image,Grid,Tab,Button,Icon,Sidebar,Container,Divider } from 'semantic-ui-react'
import { FaHome,FaTheaterMasks,FaRegLightbulb,FaRegEdit,FaTree,FaQuestion,FaCommentDots} from "react-icons/fa";
import { history } from '../history';
import firebase from "../firebasedb/firebaseconfig"
import { Link } from 'react-router-dom'

class MenuBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
          scheduled: null, 
          selectedDate : '',
          setSelectedDate: '',
          activeItem: 'dashboard',
          deviceMode: 'none',
          visible: false,
          deviceSide: 'none',
          modalProfile: false,
          avatarProfile: 100,
          avatarSelect: 99,
          avatarChoose: false,
          profile: [false,false,false,false,false,false,false,false,false],
          username: null
        }
      }

      componentDidMount(){
        localStorage.setItem('allMood',[])
        this.getpath()
        // this.checkLoginFacebook()
        this.handleScreen()  
        this.setUsername()
        this.checkAvatar()
        this.getTreeLink()
    }

    getpath = () =>{
      let location = window.location.href
      let path = location.split("/")
      let page_path = path[3].split("?")

      // console.log(page_path[0])
      if(page_path[0] == ""){
        this.setState({
          activeItem: 'dashboard'
        })
      }

      if(page_path[0] == "mood"){
        this.setState({
          activeItem: 'mood'
        })
      }

      if(page_path[0] == "intervention"){
        this.setState({
          activeItem: 'intervention'
        })
      }

      if(page_path[0] == "homework"){
        this.setState({
          activeItem: 'homework'
        })
      }

      if(page_path[0] == "result_screening"){
        this.setState({
          activeItem: 'result'
        })
      }

      if(page_path[0] == "collection_tree"){
        this.setState({
          activeItem: 'tree'
        })
      }

      if(page_path[0] == "comment"){
        this.setState({
          activeItem: 'comment'
        })
      }

      if(page_path[0] == "question"){
        this.setState({
          activeItem: 'qa'
        })
      }
      
      if(page_path[0] != "question" && page_path[0] != "comment" && page_path[0] != "collection_tree"
      && page_path[0] != "result_screening" && page_path[0] != "homework" && page_path[0] != "intervention"
      && page_path[0] != "mood" && page_path[0] != ""){
        this.setState({
          activeItem: null
        })
      }

    }

    checkLoginFacebook = () =>{
      window.fbAsyncInit = function() {
        window.FB.init({
            appId            : '755271551771919',
            autoLogAppEvents : true,
            xfbml            : true,
            version          : 'v11.0'
        });
        
        window.FB.getLoginStatus(function(response) {
          // console.log(response)
            if (response.status === 'connected') {
                // The user is logged in and has authenticated your
                // app, and response.authResponse supplies
                // and set login is true   
                window.FB.api('/me', {fields: ['first_name','last_name']}, function(res) {
                    if (res && !res.error) {
                      /* handle the result */
                      localStorage.setItem('login',true)
                      localStorage.setItem('firstname',res.first_name)
                      localStorage.setItem('lastname',res.last_name)  
                      let firstname = res.first_name 
                      let lastname = res.last_name
                      let username = firstname+" "+lastname 
                      localStorage.setItem('name',username)  
                      localStorage.setItem('uid',res.id)
                      // console.log('Good to see you, ' + username + ' :-)');
                      // console.log('login success)');         
                    }
                })
                // console.log(response)
            } else if (response.status === 'not_authorized') {
                // The user hasn't authorized your application.  They
                // must click the Login button, or you must call FB.login
                localStorage.setItem('login',false)
                // console.log(response)
            } else {
                // The user isn't logged in to Facebook. You can launch a
                // login dialog with a user gesture, but the user may have
                // to log in to Facebook before authorizing your application.
                localStorage.setItem('login',false)
                // console.log(response)
            }
            });
        };
    }
    setUsername = async() =>{
      let username = localStorage.getItem('name')
      await this.setState({
        username: username
      })
      // console.log(this.state.username)
    }

    setImageTreePath = async () =>{
    let linkset = []   
    await this.state.setNameTree.forEach(i=>{
      let getUrlTree = firebase.database().ref().child("Tree").child(i)
      getUrlTree.on("value" , data => { 
         linkset.push(Object.values(data.val())) 
        //  console.log("add link already",Object.values(data.val()))     

      })
    })
    localStorage.setItem("linkUrlSet",JSON.stringify(linkset))
    // console.log("add link set already")     
  }

    getTreeLink = async () => {
      // console.log("from getLinkTree")
      let getNameTreeAll = firebase.database().ref().child("Tree")
      let setname = []       
      await getNameTreeAll.on("value",async data => {        
        data.forEach(i => {
          setname.push(i.key)
          // console.log("add tree name already",i.key)
          // this.state.setNameTree.push(i.key)     
        })
      })     
        localStorage.setItem("setNameTree",JSON.stringify(setname))
    }

      checkAvatar = async () => {
        let username = localStorage.getItem('name')
        let checkAvatar =  firebase.database().ref().child("users").child(username).child("imageAvatar")
        checkAvatar.once("value", snap => {
          // console.log(snap)
          if(snap.val() == null){
            this.setState({
              avatarProfile: 99,
            })
          }else{
            this.setState({
              avatarProfile: snap.val()
            })
          }
        })

      }

      selectedAvatar = (numberImg) =>{
        for(let i in this.state.profile){
          if(i == numberImg){
            this.state.profile[i] = true
          }else{
            this.state.profile[i] = false
          }
        }
        let items = [...this.state.profile];
        this.setState({ items })  
      }


      handleScreen = () =>{
        const mediaMatchPortrait = window.matchMedia('(orientation: portrait)')
        const mediaMatchLanscape = window.matchMedia('(orientation: landscape)')
        const mediaMatchMobilePortrait = window.matchMedia('(max-width: 600px)');
        const mediaMatchMobileLanscape = window.matchMedia('(min-width: 480px) and (max-width: 900px) and (orientation: landscape)');
        const mediaMatchTabletLanscape = window.matchMedia('(min-width: 900px)');
        const mediaMatchTabletPortrait = window.matchMedia('(min-width: 480px) and (max-width: 900px) and (orientation: portrait)');
                    
        if(mediaMatchLanscape.matches){
          this.setState({
            deviceSide: "landscape"
         })
        }

        if(mediaMatchPortrait.matches){
          this.setState({
            deviceSide: "portrait"
         })
        }

        if(mediaMatchMobilePortrait.matches){
          this.setState({
              deviceMode: "mobilePortrait"
          })
        }
        
        if(mediaMatchMobileLanscape.matches){
          this.setState({
            deviceMode: "mobileLandScape"
          })
        }

        if(mediaMatchTabletLanscape.matches){
          this.setState({
            deviceMode: "tabletLandScape"
          })
        }

        if(mediaMatchTabletPortrait.matches){
          this.setState({
              deviceMode: "tabletPortrait"
          })
        }

        mediaMatchTabletPortrait.addEventListener("change", e => {
          // console.log("onListernerTabletPortrait: ",e.matches,e.media)
          if(e.matches){
            this.setState({
              deviceMode: "tabletPortrait"
            })
          }
        })

        mediaMatchMobilePortrait.addEventListener("change", e => {
          // console.log("onListernerMobilePort: ",e.matches,e.media)
          if(e.matches){
            this.setState({
              deviceMode: "mobilePortrait"
            })
          }
        })

        mediaMatchMobileLanscape.addEventListener("change", e => {
          // console.log("onListernerMobileLand: ",e.matches,e.media)
          if(e.matches){
            this.setState({
              deviceMode: "mobileLandScape"
            })
          }
        })

        mediaMatchTabletLanscape.addEventListener("change", e => {
          // console.log("onListernerTabletLand: ",e.matches,e.media)
          if(e.matches){
            this.setState({
              deviceMode: "tabletLandScape"
            })
          }
        })

        mediaMatchPortrait.addEventListener("change", e => {
          // console.log("onListernerPortrait: ",e.matches,e.media)
          if(e.matches){
            this.setState({
              deviceSide: "portrait"
            })
          }
        })

        mediaMatchLanscape.addEventListener("change", e => {
          // console.log("onListernerLandscape: ",e.matches,e.media)
          if(e.matches){
            this.setState({
              deviceSide: "landscape"
            })
          }
        })
    }

      handleItemClick = (e, { name }) => {
        this.setState({ activeItem: name })
        // console.log(name)
      }

      setVisible = (status) =>{
        this.setState({ visible: status})
      }

      logout = async () => {
        // window.FB.logout(() => {
        //   localStorage.clear();
        //   localStorage.setItem("login",false)
        //   // console.log("logged out");
        //   history.push('/') 
        // });  
        await firebase.auth().signOut().then(()=>{
          localStorage.setItem("login",false)
          localStorage.setItem('name',null)   
          history.push('/')
        }) 
               
      }

      modalToggle = () =>{
        this.setState({
          modalProfile: !this.state.modalProfile
        })
      }

      saveAvatar = async() =>{
        let username = localStorage.getItem('name')
        await firebase.database().ref().child("users").child(username).child("imageAvatar")
        .set(this.state.avatarProfile)
      }

      render() {        
          return(
          <div style={{height: '100vh'}}> 
             <Modal
              onClose={() => this.modalToggle()}
              id="modalProfile"
              open={this.state.modalProfile}
              // open={true}
              centered
              size={'tiny'}
            >
            <Modal.Header id="messageHeadModal">กรุณาเลือกรูปโปรไฟล์</Modal.Header>
            <Modal.Content scrolling>
              <Modal.Description>
                   <Container id="rowAvatar">
                     <Container id={this.state.profile[0] ? "imageAvatarActive" : "imageAvatar"}
                     onClick={()=>{
                       this.selectedAvatar(0)
                       this.setState({ avatarSelect: 0})
                     }} >
                       <Image src={"https://dl.dropbox.com/s/0yq6mbl08pvq8k3/Women1%28Square%29.jpg"}  size='tiny' centered circular/>
                     </Container>
                     <Container id={this.state.profile[1] ? "imageAvatarActive" : "imageAvatar"} 
                     onClick={()=>{
                      this.selectedAvatar(1)
                      this.setState({ avatarSelect: 1})
                     }}
                     >
                      <Image src={"https://dl.dropbox.com/s/pf3lf3f5i47r576/Women2%28Square%29.jpg"}  size='tiny' centered circular />
                    </Container>   
                     <Container id={this.state.profile[2] ? "imageAvatarActive" : "imageAvatar"} 
                     onClick={()=>{
                      this.selectedAvatar(2)
                      this.setState({ avatarSelect: 2})
                     }}
                     >
                       <Image src={"https://dl.dropbox.com/s/8hpf94udvelsjd9/Women3%28Square%29.jpg"}  size='tiny' centered circular />
                     </Container>         
                   </Container>
                   <Container id="rowAvatar">
                     <Container id={this.state.profile[3] ? "imageAvatarActive" : "imageAvatar"}
                     onClick={()=>{
                      this.selectedAvatar(3)
                      this.setState({ avatarSelect: 3})
                     }}
                     >
                       <Image src={"https://dl.dropbox.com/s/8x8zqq3lhvb3yec/Men1%28Square%29.jpg"}  size='tiny' centered circular/>
                     </Container>
                     <Container id={this.state.profile[4] ? "imageAvatarActive" : "imageAvatar"} 
                     onClick={()=>{
                      this.selectedAvatar(4)
                      this.setState({ avatarSelect: 4})
                     }}
                     >
                       <Image src={"https://dl.dropbox.com/s/6poal00raafsjo5/Men2%28Square%29.jpg"}  size='tiny' centered circular />
                     </Container>   
                     <Container id={this.state.profile[5] ? "imageAvatarActive" : "imageAvatar"} 
                     onClick={()=>{
                      this.selectedAvatar(5)
                      this.setState({ avatarSelect: 5})
                     }}
                     >
                       <Image src={"https://dl.dropbox.com/s/abjxibdon9p5zj5/Men3%28Square%29.jpg"}  size='tiny' centered circular />
                     </Container>         
                   </Container>
                   <Container id="rowAvatar">
                     <Container id={this.state.profile[6] ? "imageAvatarActive" : "imageAvatar"} 
                     onClick={()=>{
                      this.selectedAvatar(6)
                      this.setState({ avatarSelect: 6})
                     }}
                     >
                       <Image src={"https://dl.dropbox.com/s/hcvjoy2wwuoyruc/Lgbt1%28Square%29.jpg"}  size='tiny' centered circular/>
                    </Container>
                     <Container id={this.state.profile[7] ? "imageAvatarActive" : "imageAvatar"} 
                     onClick={()=>{
                      this.selectedAvatar(7)
                      this.setState({ avatarSelect: 7})
                     }}
                     >
                       <Image src={"https://dl.dropbox.com/s/ox4u8imaqzuc2j6/Lgbt2%28Square%29.jpg"}  size='tiny' centered circular />
                     </Container>   
                     <Container id={this.state.profile[8] ? "imageAvatarActive" : "imageAvatar"} 
                     onClick={()=>{
                      this.selectedAvatar(8)
                      this.setState({ avatarSelect: 8})
                     }}
                     >
                       <Image src={"https://dl.dropbox.com/s/kcx9x5q266tukez/Lgbt3%28Square%29.jpg"}  size='tiny' centered circular />
                     </Container>         
                   </Container> 
                </Modal.Description>
              </Modal.Content> 
              <Modal.Actions>
                <Container style={{display: 'flex', justifyContent: 'center'}}>
                  {
                    this.state.avatarProfile != 99 &&
                    <Button id="buttonModal" onClick={()=> this.modalToggle()}>ยกเลิก</Button>
                  }              
                  <Button id="buttonModal" onClick={ async()=> {
                    this.modalToggle()
                    await this.setState({ avatarProfile: this.state.avatarSelect})
                    this.saveAvatar()
                    
                  }} positive>ตกลง</Button> 
                </Container>   
              </Modal.Actions>
                </Modal> 

            {
             (this.state.deviceMode == "mobilePortrait" || this.state.deviceMode == "mobileLandScape" ) &&
            <div>
              {
                this.state.visible &&
            <Grid id="sideBarMobile" only="mobile" >
              <Menu.Item as='a'
                      onClick={()=>{
                        this.setVisible(false)
                    }}
                    >
                  <Grid.Row style={{display:'flex', justifyContent:'flex-end', margin: '10%'}}>
                    <Grid.Column><Icon style={{color:'#386A48'}} size={'large'} name='close'/></Grid.Column>
                  </Grid.Row>
                </Menu.Item>

              <Link id="itemSide" to="/">
                  <Menu.Item as='a'
                      onClick={()=>{
                        this.setState({activeItem: "dashboard"})
                        this.setVisible(false)
                    }}
                    >
                 <Icon style={{color:'#386A48'}} size={'large'} name='home'/>
                <p id="fontMenu">หน้าแรก</p>  
                </Menu.Item>
              </Link>
              <Divider style={{marginLeft:'14%',marginRight:'14%'}}/>
              <Link id="itemSide" to="/mood">
                <Menu.Item as='a'
                   onClick={()=>{
                     this.setState({activeItem: "mood"})
                     this.setVisible(false)
                  }}
                >
                <FaTheaterMasks style={{color:'#386A48'}} size='27px'/>
                 <p id="fontMenu">อารมณ์</p>
                 
                </Menu.Item>
                </Link>
              <Divider style={{marginLeft:'14%',marginRight:'14%'}}/>
              <Link id="itemSide" to="/intervention">
                <Menu.Item as='a'
                  onClick={()=>{
                    this.setState({activeItem: "intervention"})
                    this.setVisible(false)
                }}
                > 
               
                <Icon style={{color:'#386A48'}} size={'large'} name='lightbulb outline' />
                <p id="fontMenu">สิ่งที่ได้เรียนรู้</p>
                </Menu.Item>
              </Link>
              <Divider style={{marginLeft:'14%',marginRight:'14%'}}/>
              <Link id="itemSide" to="/homework">
                <Menu.Item as='a'
                   onClick={()=>{
                     this.setState({activeItem: "homework"})
                     this.setVisible(false)
                  }}
                >
                 <Icon style={{color:'#386A48'}} size={'large'} name='edit' />
                <p id="fontMenu">การบ้าน</p>
          
                </Menu.Item>
              </Link>
              <Divider style={{marginLeft:'14%',marginRight:'14%'}}/>
              <Link id="itemSide" to="/result_screening">
                <Menu.Item as='a'
                  onClick={()=>{
                    this.setState({activeItem: "result"})
                    this.setVisible(false)
                  }}
                >
              
                  <Icon style={{color:'#386A48'}} size={'large'} name='newspaper' />
                  <p id="fontMenu">ผลการประเมินอารมณ์</p>

                </Menu.Item>
              </Link>
              <Divider style={{marginLeft:'14%',marginRight:'14%'}}/>
              <Link id="itemSide" to="/collection_tree">
                <Menu.Item as='a'
                  onClick={()=>{
                    this.setState({activeItem: "tree"})
                    this.setVisible(false)
                  }}
                >
                  
                   <Icon style={{color:'#386A48'}} size={'large'} name='tree' />
                  <p id="fontMenu">ต้นไม้</p>
                </Menu.Item>
              </Link>
              <Divider style={{marginLeft:'14%',marginRight:'14%'}}/>
              <Link id="itemSide" to="/comment">
                <Menu.Item as='a'
                  onClick={()=>{
                    this.setState({activeItem: "comment"})
                    this.setVisible(false)
                  }}
                >
                  <Icon style={{color:'#386A48'}} size={'large'} name='commenting' />
                  <p id="fontMenu">แสดงความคิดเห็น</p>
                </Menu.Item>
              </Link>
              <Divider style={{marginLeft:'14%',marginRight:'14%'}}/>
              <Link id="itemSide" to="/question">
                <Menu.Item as='a'
                  onClick={()=>{
                    this.setState({activeItem: "qa"})
                    this.setVisible(false)
                  }}
                >
                 
                  <Icon style={{color:'#386A48'}} size={'large'} name='question circle outline' />
                  <p id="fontMenu">คำถามที่พบบ่อย</p>
                </Menu.Item>
              </Link>
              <Divider style={{marginLeft:'14%',marginRight:'14%'}}/>
              <Menu.Item id="itemSide" as='a'
                  onClick={()=>{
                    this.logout()
                    this.setVisible(false)
                  }}
                >  
                  <Icon style={{color:'#386A48'}} size={'large'} name='log out' />
               <p id="fontMenu">ออกจากระบบ</p> 
                </Menu.Item>
            </Grid>
          }
          {
             !this.state.visible &&
             <Grid id="containnerMobile" only="mobile">
              <Grid.Row id="gridMenu">
                  <Grid.Column>
                    <Icon  style={{color:'#0f8970'}} name="bars" size='large' onClick={()=>this.setVisible(true)}/>
                  </Grid.Column>
                </Grid.Row>
            </Grid>
          }
           </div>
           } 

            {
             (this.state.deviceMode == "tabletLandScape")  &&
             <Grid id="containnerComputer"
             style={this.state.visible ? { zIndex: 4 }:{ zIndex: 1 } } 
             >
                <Grid.Row>  
                  <Grid.Column id="gridMenu">
                  <Menu vertical id="font" style={{ borderRadius: 20,border: 0 }}>   
                      <Menu.Item 
                        name='profile'
                        active={this.state.activeItem === 'profile'}
                        fitted='horizontally'
                        style={{textalign: 'center'}}
                      >           
                      <Container id="imgLoading">
                       
                      {
                        this.state.avatarProfile == 99 && 
                        <Image src={avatar}  size='small' centered circular id="imageProfile" 
                      />
                      }
                      {
                        this.state.avatarProfile == 0 && 
                        <Image src={"https://dl.dropbox.com/s/0yq6mbl08pvq8k3/Women1%28Square%29.jpg"}  size='small' centered circular id="imageProfile" 
                        onClick={ ()=> this.modalToggle() }/>
                      }
                      {
                        this.state.avatarProfile == 1 && 
                        <Image src={"https://dl.dropbox.com/s/pf3lf3f5i47r576/Women2%28Square%29.jpg"}  size='small' centered circular id="imageProfile" 
                        onClick={ ()=> this.modalToggle() }/>
                      }
                      {
                        this.state.avatarProfile == 2 && 
                        <Image src={"https://dl.dropbox.com/s/8hpf94udvelsjd9/Women3%28Square%29.jpg"}  size='small' centered circular id="imageProfile" 
                        onClick={ ()=> this.modalToggle() }/>
                      }
                      {
                        this.state.avatarProfile == 3 && 
                        <Image src={"https://dl.dropbox.com/s/8x8zqq3lhvb3yec/Men1%28Square%29.jpg"}  size='small' centered circular id="imageProfile" 
                        onClick={ ()=> this.modalToggle() }/>
                      }
                      {
                        this.state.avatarProfile == 4 && 
                        <Image src={"https://dl.dropbox.com/s/6poal00raafsjo5/Men2%28Square%29.jpg"}  size='small' centered circular id="imageProfile" 
                        onClick={ ()=> this.modalToggle() }/>
                      }
                      {
                        this.state.avatarProfile == 5 && 
                        <Image src={"https://dl.dropbox.com/s/abjxibdon9p5zj5/Men3%28Square%29.jpg"}  size='small' centered  circular id="imageProfile" 
                        onClick={ ()=> this.modalToggle() }/>
                      }
                      {
                        this.state.avatarProfile == 6 && 
                        <Image src={"https://dl.dropbox.com/s/hcvjoy2wwuoyruc/Lgbt1%28Square%29.jpg"}  size='small' centered circular id="imageProfile" 
                        onClick={ ()=> this.modalToggle() }/>
                      }
                      {
                        this.state.avatarProfile == 7 && 
                        <Image src={"https://dl.dropbox.com/s/ox4u8imaqzuc2j6/Lgbt2%28Square%29.jpg"}  size='small' centered circular id="imageProfile" 
                        onClick={ ()=> this.modalToggle() }/>
                      }
                      {
                        this.state.avatarProfile == 8 && 
                        <Image src={"https://dl.dropbox.com/s/kcx9x5q266tukez/Lgbt3%28Square%29.jpg"}  size='small' centered circular id="imageProfile" 
                        onClick={ ()=> this.modalToggle() }/>
                      }
                       <Container id="buttonChange">
                        {
                          this.state.avatarProfile != 100 && 
                            <Button circular icon='images outline' onClick={ ()=> this.modalToggle() }/>
                        }
                        </Container>
                    
                      </Container>
                        
                      <Container id="nameContainner">
                        <label id="head-name">{this.state.username}</label>
                      </Container>  
                     
                      </Menu.Item>
                      <Link to="/">
                          <Menu.Item
                          id={this.state.activeItem === 'dashboard' ? "menuActive": "menuInActive"}
                          name='dashboard'
                          active={this.state.activeItem === 'dashboard'}
                          onClick={this.handleItemClick}
                          >
                          <p id="pad"><FaHome size='15px' />  หน้าแรก</p>
                          </Menu.Item>
                      </Link>
                      <Link to="/mood">
                      <Menu.Item
                      id={this.state.activeItem === 'mood' ? "menuActive": "menuInActive"}
                      name='mood'
                      active={this.state.activeItem === 'mood'}
                      onClick={this.handleItemClick}
                      >
                      <p id="pad"><FaTheaterMasks  size='15px'/>  อารมณ์(Moods)</p>
                      </Menu.Item>
                      </Link>
                      <Link to="/intervention">
                        <Menu.Item
                        id={this.state.activeItem === 'intervention' ? "menuActive": "menuInActive"}
                        name='intervention'
                        active={this.state.activeItem === 'intervention'}
                        onClick={this.handleItemClick}       
                        >
                        <p id="pad"><FaRegLightbulb  size='15px'/>  สิ่งที่ได้เรียนรู้</p>
                        </Menu.Item>
                      </Link>
                      <Link to="/homework">
                      <Menu.Item
                      id={this.state.activeItem === 'homework' ? "menuActive": "menuInActive"}
                      name='homework'
                      active={this.state.activeItem === 'homework'}
                      onClick={this.handleItemClick}
                      >
                      <p id="pad"><FaRegEdit  size='15px'/>  การบ้าน</p>
                      </Menu.Item>
                      </Link>
                      <Link to="/result_screening">
                          <Menu.Item
                          id={this.state.activeItem === 'result' ? "menuActive": "menuInActive"}
                          name='result'
                          active={this.state.activeItem === 'result'}
                          onClick={this.handleItemClick}
                          >
                          <p id="pad"> <Icon name='newspaper' />  ผลการประเมินอารมณ์</p>
                          </Menu.Item>
                      </Link>
                      <Link to="/collection_tree">
                          <Menu.Item
                          id={this.state.activeItem === 'tree' ? "menuActive": "menuInActive"}
                          name='tree'
                          active={this.state.activeItem === 'tree'}
                          onClick={this.handleItemClick}
                          >
                          <p id="pad"><FaTree  size='15px'/>  ต้นไม้</p>
                          </Menu.Item>
                      </Link>
                      <Link to="/comment">
                          <Menu.Item
                          name='comment'
                          id={this.state.activeItem === 'comment' ? "menuActive": "menuInActive"}
                          active={this.state.activeItem === 'comment'}
                          onClick={this.handleItemClick}
                          >
                          <p id="pad"><FaCommentDots  size='15px'/>  แสดงความคิดเห็น</p>
                          </Menu.Item>
                      </Link>
                      <Link to="/question">
                          <Menu.Item
                          name='qa'
                          id={this.state.activeItem === 'qa' ? "lastMenuActive": "menuInActive"}
                          active={this.state.activeItem === 'qa'}
                          onClick={this.handleItemClick}
                          >
                          <p id="pad"><FaQuestion  size='15px'/>  คำถามที่พบบ่อย</p>
                          </Menu.Item>  
                      </Link>
                    </Menu>
                    <Button id="logout" onClick={this.logout}>ออกจากระบบ</Button>
                  </Grid.Column>     
                </Grid.Row>                
              </Grid>                
           }
          {
             this.state.deviceMode == "tabletPortrait"  &&
            <Grid id="containnerTablet" 
            style={this.state.visible ? { zIndex: 4 }:{ zIndex: 1 } } 
            only="tablet">
              <Sidebar.Pushable>
              <Sidebar.Pusher>
                <Sidebar
                  as={Menu}
                  animation='overlay'
                  direction='left'
                  icon='labeled'
                  inverted
                  onHide={() => this.setVisible(false)}
                  vertical
                  visible={this.state.visible}
                  width='thin'
                  style={{backgroundColor:'#DBFAA4',height: window.innerHeight}}
                >
                <Link to="/">
                    <Menu.Item as='a' style={{marginTop: '20%'}}
                        onClick={()=>{
                          this.setState({activeItem: "dashboard"})
                          this.setVisible(false)
                      }}
                      >
                    <Icon style={{color:'#386A48'}}  name='home' />
                    <p id="fontMenu">หน้าแรก</p>
                  </Menu.Item>
                </Link>
                <Link to="/mood">
                <Menu.Item as='a'
                   onClick={()=>{
                     this.setState({activeItem: "mood"})
                     this.setVisible(false)
                  }}
                >
                  <FaTheaterMasks style={{color:'#386A48'}}  size='27px'/>
                  <p id="fontMenu">อารมณ์</p>
                </Menu.Item>
                </Link>
                <Link to="/intervention">
                <Menu.Item as='a'
                  onClick={()=>{
                    this.setState({activeItem: "intervention"})
                    this.setVisible(false)
                }}
                >
                  <Icon style={{color:'#386A48'}} name='lightbulb outline' />
                  <p id="fontMenu">สิ่งที่ได้เรียนรู้</p>
                </Menu.Item>
                </Link>
                <Link to="/homework">
                <Menu.Item as='a'
                   onClick={()=>{
                     this.setState({activeItem: "homework"})
                     this.setVisible(false)
                  }}
                >
                  <Icon style={{color:'#386A48'}} name='edit' />
                  <p id="fontMenu">การบ้าน</p>
                </Menu.Item>
                </Link>
                <Link to="/result_screening">
                <Menu.Item as='a'
                  onClick={()=>{
                    this.setState({activeItem: "result"})
                    this.setVisible(false)
                  }}
                >
                  <Icon style={{color:'#386A48'}} name='newspaper' />
                  <p id="fontMenu">ผลการประเมินอารมณ์</p>
                </Menu.Item>
                </Link>
                <Link to="/collection_tree">
                <Menu.Item as='a'
                  onClick={()=>{
                    this.setState({activeItem: "tree"})
                    this.setVisible(false)
                  }}
                >
                  <Icon style={{color:'#386A48'}} name='tree' />
                  <p id="fontMenu">ต้นไม้</p>
                </Menu.Item>
                </Link>
                <Link to="/comment">
                <Menu.Item as='a'
                  onClick={()=>{
                    this.setState({activeItem: "comment"})
                    this.setVisible(false)
                  }}
                >
                  <Icon style={{color:'#386A48'}} name='commenting' />
                  <p id="fontMenu">แสดงความคิดเห็น</p>
                </Menu.Item>
                </Link>
                <Link to="/question">
                <Menu.Item as='a'
                  onClick={()=>{
                    this.setState({activeItem: "qa"})
                    this.setVisible(false)
                  }}
                >
                  <Icon style={{color:'#386A48'}} name='question circle outline' />
                  <p id="fontMenu">คำถามที่พบบ่อย</p>
                </Menu.Item>
                </Link>
                <Menu.Item as='a'
                  onClick={()=>{
                    this.logout()
                    this.setVisible(false)
                  }}
                >
                  <Icon style={{color:'#386A48'}} name='log out' />
                  <p id="fontMenu">ออกจากระบบ</p>
                </Menu.Item>
                </Sidebar>
                <Grid.Row id="gridMenu">
                  <Grid.Column>
                    <Icon  style={{color:'#0f8970'}} name="bars" size='large' onClick={()=>this.setVisible(true)}/>
                  </Grid.Column>
                </Grid.Row>
                </Sidebar.Pusher>
              </Sidebar.Pushable>
            </Grid>
           }
            </div>
          );
      }

}
export default MenuBar;