import React from "react";

import avatar from '../images/matthew.png'; 
import '../css/qa.css'
import 'semantic-ui-css/semantic.css';
import { Segment,Accordion,List,Grid,Image,Icon,Divider,Button } from 'semantic-ui-react'
import { render } from 'react-dom';
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { history } from '../history';
class qa extends React.Component {
  constructor(props) {
    super(props);
     this.state = {     
      pictures: []
      
      
    };
  }

  state = { activeItem: 'home' }
  state = { activeIndex: 0 }

  componentDidMount(){
    this.handleScreen()
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  handleItemClick = (e, { name }) => this.setState(
    { activeItem: name },
    history.push('/'+name)
    
    )

    handleScreen = () =>{
      const mediaMatchPortrait = window.matchMedia('(orientation: portrait)')
      const mediaMatchLanscape = window.matchMedia('(orientation: landscape)')
      const mediaMatchMobilePortrait = window.matchMedia('(max-width: 480px)');
      const mediaMatchMobileLanscape = window.matchMedia('(min-width: 480px) and (max-width: 900px) and (orientation: landscape)');
      const mediaMatchTabletLanscape = window.matchMedia('(min-width: 900px)');
      const mediaMatchTabletPortrait = window.matchMedia('(min-width: 480px) and (max-width: 900px) and (orientation: portrait)');
    
      if(mediaMatchMobilePortrait.matches){
        this.setState({
            deviceMode: "mobilePortrait"
        })
      }
      
      if(mediaMatchMobileLanscape.matches){
        this.setState({
          deviceMode: "mobileLandScape"
        })
      }
  
      if(mediaMatchTabletLanscape.matches){
        this.setState({
          deviceMode: "tabletLandScape"
        })
      }
  
      if(mediaMatchTabletPortrait.matches){
        this.setState({
            deviceMode: "tabletPortrait"
        })
      }
  
      mediaMatchTabletPortrait.addEventListener("change", e => {
        // console.log("onListernerTabletPortrait: ",e.matches,e.media)
        if(e.matches){
          this.setState({
            deviceMode: "tabletPortrait"
          })
        }
      })
  
      mediaMatchMobilePortrait.addEventListener("change", e => {
        // console.log("onListernerMobilePort: ",e.matches,e.media)
        if(e.matches){
          this.setState({
            deviceMode: "mobilePortrait"
          })
        }
      })
  
      mediaMatchMobileLanscape.addEventListener("change", e => {
        // console.log("onListernerMobileLand: ",e.matches,e.media)
        if(e.matches){
          this.setState({
            deviceMode: "mobileLandScape"
          })
        }
      })
  
      mediaMatchTabletLanscape.addEventListener("change", e => {
        // console.log("onListernerTabletLand: ",e.matches,e.media)
        if(e.matches){
          this.setState({
            deviceMode: "tabletLandScape"
          })
        }
      })
    }

  render() {
    const { activeIndex } = this.state
    // console.log(this.state.pictures)
    return (   
      <>
      {
          <Grid id="gridQA">
          <Grid.Column>      
      <Segment id="segmentQA">
        <h3 id="fontHeadQA">คำถามที่พบบ่อย</h3>
        <Divider style={{margin: "2%"}} />
        <div id="scrollQA">
      <Accordion fluid styled>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={this.handleClick}
          style={{fontSize:'15px',fontFamily: 'Mitr',fontWeight:'500'}}
        >
          <Icon name='dropdown' />
          Q1 : วิธีการเข้าใช้งาน?
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          {/* <Header  style={{fontFamily: 'Mitr'}} size='small'>วิธีการเข้าใช้งาน</Header> */}
          <List ordered>
          <List.Item style={{marginLeft:'15px',fontSize:'15px'}} as='li'>1. เข้าใช้งานผ่านเพจเฟสบุ๊คนี้ <a href="https://www.facebook.com/psyjaibot"  target="_blank">https://www.facebook.com/psyjaibot.</a></List.Item>   
          <List.Item style={{marginLeft:'15px',fontSize:'15px',position: 'relative'}} as='li'>2. กดปุ่มสีฟ้าที่เขียนว่า Send Message ในกรณีใช้งานบนมือถือจะต้องมี App Messager ในเครื่องด้วย</List.Item>  
          <Grid style={{margin: '10px',display: 'flex', justifyContent: 'center'}}>
            <Image.Group>
              <Image style={{padding:'2px'}} size='medium' src="./qa/qa5.jpg"></Image>
              <Image style={{padding:'2px'}} size='medium' src="./qa/qa6.jpg"></Image>
            </Image.Group>
          </Grid>
          <List.Item style={{marginLeft:'15px',fontSize:'15px'}} as='li'>3. ท่านสามารถเริ่มการสนทนากับแชทบอทได้โดยการกดปุ่ม "เริ่มสนทนา"</List.Item>
          <List.Item style={{marginLeft:'15px',fontSize:'15px'}} as='li'>4. หลังจากนั้นท่านสามารถเริ่มต้นการพูดคุยได้เลย</List.Item>
          </List>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={this.handleClick}
          style={{fontSize:'15px',fontFamily: 'Mitr',fontWeight:'500'}}
        >
          <Icon name='dropdown' />
          Q2 : วิธีการใช้งานเบื้องต้น?
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
          <List >
          <List.Header style={{marginLeft:'10px',fontWeight:'450',fontSize:'16px'}}>ขณะพูดคุยกับแชทบอท ผู้ใช้งานสามารถโต้ตอบกับแชทบอทได้ 2 วิธี</List.Header>
          <List.Item  style={{marginLeft:'5%',fontSize:'15px'}}>1. การกดที่ตัวเลือก</List.Item>
          <List.Item  style={{marginLeft:'5%',fontSize:'15px'}}>2. การพิมพ์ข้อความ</List.Item>
          </List>
          <List.Header  style={{marginLeft:'10px',fontWeight:'450',fontSize:'16px'}}>ในกรณีของการกดตัวเลือก</List.Header>
          <List.Item style={{marginLeft:'15px',fontSize:'15px'}}>- ในบางช่วงของการพูดคุย แชทบอทจะมีการขึ้นตัวเลือกมาให้ ผู้ใช้งานสามารถเลือกกดตัวเลือกที่ให้มาได้</List.Item>
          <Image style={{padding:'2px'}} size="medium" centered src="https://dl.dropbox.com/s/dws0urdi4e93jih/2.JPG"></Image>

          <List.Header  style={{marginTop:'10px',marginLeft:'10px',fontWeight:'450',fontSize:'16px'}}>ในกรณีที่บอทไม่ได้ขึ้นตัวเลือกมาให้</List.Header>
          <List.Item style={{marginLeft:'15px',fontSize:'15px'}}>- ผู้ใช้งานสามารถพิมพ์เพื่อสนทนาโต้ตอบได้เลย</List.Item>
          <Image style={{padding:'2px'}} size="medium" centered src="https://dl.dropbox.com/s/5j02syyzcd8uuhq/2_2.JPG"></Image>

          <List.Header  style={{marginTop:'10px',marginLeft:'10px',fontWeight:'450',fontSize:'16px'}}>ในกรณีที่ผู้ใช้งานต้องการเข้าเมนูลัด</List.Header>
          <List.Item style={{marginLeft:'15px',fontSize:'15px'}}>- ท่านสามารถกดปุ่มด้านล่างขวา หลังจากนั้นจะมีเมนูขึ้นมาด้านล่าง ท่านสามารถเลือกเมนูที่ต้องการได้ 4 หัวข้อ</List.Item>
          <List.Item style={{marginLeft:'2%',fontSize:'15px'}}>ได่แก่ 1.เมนูหลัก  2.คุยเล่น 3.ข้อมูลสุขภาพ 4.ส่งข้อเสนอแนะ</List.Item>
          <Image style={{padding:'2px'}} size="medium" centered src="./qa/qa4.jpg"></Image>
          <Image style={{padding:'2px'}} size="medium" centered src="./qa/qa7.jpg"></Image>

          <List.Header  style={{marginTop:'10px',marginLeft:'10px',fontWeight:'450',fontSize:'16px'}}>ในกรณีที่ผู้ใช้งานต้องการหยุดการสนทนา</List.Header>
          <List.Item style={{marginLeft:'15px',fontSize:'15px'}}>- ไม่ว่าจะกำลังพูดคุยอยู่ในช่วงไหน ท่านสามารถหยุดการพูดคุยและออกจากการสนทนาได้ทันที และสามารถกลับมาคุยเรื่องเดิมจากจุดที่หยุดการสนทนาได้ตลอด แต่หากท่านต้องการเริ่มการสนทนาอีกครั้ง สามารถกดที่ปุ่มเมนูหลัก เพื่อเข้าสู่หน้าเมนูใหม่อีกครั้ง</List.Item>
          <Image style={{padding:'2px'}} size="medium" centered src="./qa/qa3.jpg"></Image>

          <List.Header  style={{marginTop:'10px',marginLeft:'10px',fontWeight:'450',fontSize:'16px'}}>ในกรณีที่ผู้ใช้งานต้องการเปลี่ยนหัวข้อการสนทนา</List.Header>
          <List.Item style={{marginLeft:'15px',fontSize:'15px'}}>- ไม่ว่าจะกำลังพูดคุยอยู่ในช่วงไหน ท่าานสามารถเปลี่ยนหัวข้อการพูดคุยได้ โดยการพิมพ์คำว่า <b>อยากเปลี่ยนเรื่องคุย</b> แชทบอทจะนำท่านเข้าสู่การพูดคุยเพื่อหาหัวข้ออันใหม่</List.Item>

          <List.Header  style={{marginTop:'10px',marginLeft:'10px',fontWeight:'450',fontSize:'16px'}}>การเก็บรักษาข้อมูลของผู้ใช้งาน</List.Header>
          <List.Item style={{marginLeft:'15px',fontSize:'15px'}}>- ขณะพูดคุยกับแชทบอท ระบบจะมีการเก็บข้อมูลการพูดคุยของผู้ใช้งาน ซึ่งข้อมูลเหล่านี้จะถูกเก็บไว้เพื่อนำไปใช้ในการพัฒนาและปรับปรุงการพูดคุยของแชทบอทให้มีคุณภาพที่ดีขึ้น 
          ดังนั้น หากมีการเผยแพร่จะเป็นในลักษณะของภาพรวม ไม่ได้เป็นการแสดงข้อมูลเจาะจงของบุคคลใด บุคคลหนึ่ง</List.Item>

          <List.Header  style={{marginTop:'10px',marginLeft:'10px',fontWeight:'450',fontSize:'16px'}}>สำหรับผู้ใช้งานครั้งแรก</List.Header>
          <List.Item style={{marginLeft:'15px',fontSize:'15px'}}>- สำหรับการใช้งานครั้งแรก Psyjai ต้องการให้คุณได้ตอบคำถามข้อมูลส่วนตัวในเบื้องต้นและประเมินเกี่ยวกับอารมณ์ก่อน 
          เพื่อที่เราจะได้เข้าใจคุณมากยิ่งขึ้น สามารถเลือกที่เมนูประเมินอารมณ์ตรงมุมขวาล่างตรงกล่องข้อความ (Messenger) ของเพจ Psyjai ได้เลย :) </List.Item>
          {/* <Image style={{padding:'2px'}} size="medium" centered src="https://dl.dropbox.com/s/96t30pfurjpxu53/3.JPG"></Image> */}

          <List.Header  style={{marginTop:'10px',marginLeft:'10px',fontWeight:'450',fontSize:'16px'}}>สำหรับผู้ที่เคยใช้งาน</List.Header>
          <List.Item style={{marginLeft:'15px',fontSize:'15px'}}>- สามารถพูดคุยกับ Psyjai ได้โดยกดรูปที่หน้าเมนูหรือเลือกที่เมนูหลักและเลือก 
          "คุยกับใส่ใจ" ตรงมุมขวาล่างที่กล่องข้อความ (Messenger) ของเพจ Psyjai ได้เลย :) </List.Item>
          <Image style={{padding:'2px'}} size='small' centered src="https://dl.dropbox.com/s/9l41qgkiibwsxp0/3.JPG"></Image>

          <List.Header  style={{marginTop:'10px',marginLeft:'10px',fontWeight:'450',fontSize:'16px'}}>ต้องการพูดคุยเกี่ยวกับโควิด-19</List.Header>
          <List.Item style={{marginLeft:'15px',fontSize:'15px'}}>- สามารถพูดคุยเกี่ยวกับเรื่องโควิด-19 ได้โดยกดรูปที่หน้าเมนูหรือเลือกเมนูหลักและเลือก “ใส่ใจสู้โควิด” ตรงมุมขวาล่างที่กล่องข้อความ (Messenger) ของเพจ Psyjai ได้เลย</List.Item>
          <Image style={{padding:'2px'}} size='small' centered src="./qa/qa9.jpg"></Image>
          
          <List.Header  style={{marginTop:'10px',marginLeft:'10px',fontWeight:'450',fontSize:'16px'}}>ต้องการประเมินสุขภาพจิต</List.Header>
          <List.Item style={{marginLeft:'15px',fontSize:'15px'}}>- สามารถเข้าประเมินได้จากหน้าเมนูหรือกดเลือกเมนูหลักและเลือก “ประเมินสุขภาพจิต” ตรงมุมขวาล่างที่กล่องข้อความ (Messenger) ของเพจ Psyjai ได้เลย :)</List.Item>
          <Image style={{padding:'2px'}} size='small' centered src="./qa/qa13.jpg"></Image>

          <List.Header  style={{marginTop:'10px',marginLeft:'10px',fontWeight:'450',fontSize:'16px'}}>ต้องการเข้าโหมดคุยเล่น (Chitchat)</List.Header>
          <List.Item style={{marginLeft:'15px',fontSize:'15px'}}>สามารถเข้าโหมดคุยเล่น (Chitchat)ได้จากเมนูคุยเล่น (Chitchat) ตรงมุมขวาล่างที่กล่องข้อความ (Messenger) ของเพจ Psyjai ได้เลย :)</List.Item>
          <Image style={{padding:'2px'}} size='small' centered src="./qa/qa8.jpg"></Image>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 2}
          index={2}
          onClick={this.handleClick}
          style={{fontSize:'15px',fontFamily: 'Mitr',fontWeight:'500'}}
        >
          <Icon name='dropdown' />
          Q3 : วิธีแก้ไขเบื้องต้น ในกรณีแชทบอทค้างหรือไม่ตอบ?
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 2}>
          <p>
          ถ้าแชทบอทไม่ตอบให้รอสักครู่บอทอาจจะกำลังโหลดรูปอยู่ แต่ถ้าหากนานเกิน 1 นาที ขอให้ท่านกดเลือกเมนูที่ต้องการที่มุมล่างด้านขวาของ Messenger Psyjai 
          ระบบจะเริ่มทำงานใหม่อีกครั้งค่ะ
          </p>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 3}
          index={3}
          onClick={this.handleClick}
          style={{fontSize:'15px',fontFamily: 'Mitr',fontWeight:'500'}}
        >
          <Icon name='dropdown' />
          Q4 : จะเข้าทำการบ้านต้องทำอย่างไร?
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 3}>
          <p>กดจากตัวเลือกที่หน้าเมนูหรือสามารถกดที่เมนูหลักและเลือก “การบ้าน” ได้เลย</p>
          <Image style={{padding:'2px'}}size='small' centered src="./qa/qa11.jpg"></Image>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 4}
          index={4}
          onClick={this.handleClick}
          style={{fontSize:'15px',fontFamily: 'Mitr',fontWeight:'500'}}
        >
          <Icon name='dropdown' />
          Q5 : อยากทราบผลประเมินอารมณ์ของตนเองต้องทำอย่างไร ?
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 4}>
          <p>กดจากตัวเลือกที่หน้าเมนูหรือสามารถกดที่เมนูหลักและเลือก “ข้อมูลสุขภาพ” ได้เลย</p>
          <Image style={{padding:'2px'}} size='small' centered src="./qa/qa10.jpg"></Image>
        </Accordion.Content>


        <Accordion.Title
          active={activeIndex === 5}
          index={5}
          onClick={this.handleClick}
          style={{fontSize:'15px',fontFamily: 'Mitr',fontWeight:'500'}}
        >
          <Icon name='dropdown' />
          Q6 : ต้องการให้ต้นไม้เติบโต ทำอย่างไร
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 5}>
          <p>การเจริญเติบโตของน้องต้นไม้จะอิงจากการเข้ามาพูดคุยกับแชทบอทในแต่ละวันของท่าน โดยท่านสามารถกด <b>รับรางวัล</b> จากหน้าอารมณ์ในปฏิทินแสดงอารมณ์ในแต่ละวัน เมื่อกดรับรางวัลแล้วต้นไม้จะมีการเติบโตไปตามลำดับ หากอยากให้น้องต้นไม้โตไวๆ อย่าลืมเข้ามาคุยกับใส่ใจบ่อยๆนะ</p>
          <Image style={{padding:'2px'}} size='small' centered src="./qa/qa12.jpg"></Image>
          <Grid style={{margin: '10px',display: 'flex', justifyContent: 'center'}}>
          <Image.Group style={{margin:'2%'}}>
              <Image style={{padding:'2px'}} size='small' src="./qa/qa15.jpg"></Image>
              <Image style={{padding:'2px'}} size='small' src="./qa/qa16.jpg"></Image>
              <Image style={{padding:'2px'}} size='small' src="./qa/qa17.jpg"></Image>
          </Image.Group>
          </Grid>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 6}
          index={6}
          onClick={this.handleClick}
          style={{fontSize:'15px',fontFamily: 'Mitr',fontWeight:'500'}}
        >
          <Icon name='dropdown' />
          Q7 : เงื่อนไขและข้อตกลงการใช้งาน
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 6}>
          <Button onClick={() => history.push('/disclaimer')}>
            <p  style={{fontSize:'15px',fontFamily: 'Mitr',fontWeight:'400'}}>ดูเงื่อนไขและข้อตกลงการใช้งาน</p></Button>
        </Accordion.Content>
      </Accordion>
      </div>
      </Segment>
      </Grid.Column> 
      </Grid>
  }
   
      </>
    );
  }
}export default qa;

