import React from "react";
import 'semantic-ui-css/semantic.css';
import '../css/comment.css'
import { Segment,Rating,Grid,Divider,Form,TextArea,Button,Modal,Image,Container,
Dimmer,Loader } from 'semantic-ui-react'
import axios from "axios";

class Comment extends React.Component {
    constructor(props) {
      super(props);
       this.state = { 
            rating: 0,
            comment: null,
            image64: null,
            loading: false,
            success: null,
            openModal: false,
            deviceMode: 'none',
            uidUser: null,
            username: null,
            checkNull: false
      };
    }

    componentDidMount() {
        this.handleScreen()
        this.setState({
            uidUser: localStorage.getItem('uid'),
            username: localStorage.getItem('name')
        })
      }

    handleScreen = () =>{
        const mediaMatchMobilePortrait = window.matchMedia('(max-width: 480px)');
        const mediaMatchMobileLanscape = window.matchMedia('(min-width: 480px) and (max-width: 900px) and (orientation: landscape)');
        const mediaMatchTabletLanscape = window.matchMedia('(min-width: 900px)');
        const mediaMatchTabletPortrait = window.matchMedia('(min-width: 480px) and (max-width: 900px) and (orientation: portrait)');
      
        if(mediaMatchMobilePortrait.matches){
          this.setState({
              deviceMode: "mobilePortrait"
          })
        }
        
        if(mediaMatchMobileLanscape.matches){
          this.setState({
            deviceMode: "mobileLandScape"
          })
        }
    
        if(mediaMatchTabletLanscape.matches){
          this.setState({
            deviceMode: "tabletLandScape"
          })
        }
    
        if(mediaMatchTabletPortrait.matches){
          this.setState({
              deviceMode: "tabletPortrait"
          })
        }
    
        mediaMatchTabletPortrait.addEventListener("change", e => {
        //   console.log("onListernerTabletPortrait: ",e.matches,e.media)
          if(e.matches){
            this.setState({
              deviceMode: "tabletPortrait"
            })
          }
        })
    
        mediaMatchMobilePortrait.addEventListener("change", e => {
        //   console.log("onListernerMobilePort: ",e.matches,e.media)
          if(e.matches){
            this.setState({
              deviceMode: "mobilePortrait"
            })
          }
        })
    
        mediaMatchMobileLanscape.addEventListener("change", e => {
        //   console.log("onListernerMobileLand: ",e.matches,e.media)
          if(e.matches){
            this.setState({
              deviceMode: "mobileLandScape"
            })
          }
        })
    
        mediaMatchTabletLanscape.addEventListener("change", e => {
        //   console.log("onListernerTabletLand: ",e.matches,e.media)
          if(e.matches){
            this.setState({
              deviceMode: "tabletLandScape"
            })
          }
        })
    }
    
    handleRate = (e, { rating, maxRating }) => {
        // console.log(rating)
        this.setState({
            rating: rating
        })
    }

    handleComment = (e) =>{
        // console.log(e.target.value)
        this.setState({comment: e.target.value});
    }

    saveComment = async () =>{
        this.setState({loading: true})
        let allData = {
            botId: "104496288028116",
            userId: this.state.uidUser,
            username: this.state.username,
            data: {
                rating: this.state.rating,
                comment: this.state.comment,
                image64: this.state.image64
            }
        }
        if(this.state.rating == 0 && this.state.comment == null && this.state.image64 == null){
            this.setState({
                loading: false,
                checkNull: true,
                openModal: true
            })
        }else{
            await axios.post('https://psyjai-dashboard.herokuapp.com/addComment', allData)
            .then(response => {
                // console.log(response)
                this.setState({
                    checkNull: false,
                    loading: false,
                    success: true,
                    openModal: true
                })
            })
            .catch(e => {
                // console.log(e)
                this.setState({
                    checkNull: false,
                    loading: false,
                    success: false,
                    openModal: true
                })
            })
        }
       
            
    }

    changeHandler = (e) =>{
        // console.log(e.target.files[0])
        let idCardBase64 = '';
        this.getBase64(e.target.files[0], (result) => {
            idCardBase64 = result;
            this.setState({
                image64: idCardBase64
            })
            // console.log(idCardBase64)
        });
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            // console.log('Error: ', error);
        };
    }

    render() {
        // console.log(this.state.deviceMode)
        return (   
            <>
            {
            (this.state.deviceMode == "tabletLandScape") && 
            <Grid>
            <Grid.Column id="gridComment">      
                <Segment id="segmentComment">
                    <h3 id="fontHeadComment">แสดงความคิดเห็น</h3>
                    <Divider style={{margin: "2%"}} />
                 
                    <Grid.Row style={{marginLeft: "3%"}}>
                        <label style={{fontSize: '15px'}}>ระดับความพึงพอใจ : </label>
                    </Grid.Row>
                    <Grid.Row style={{marginLeft: "7%",marginTop:"1%"}}>
                        <Rating icon='star' defaultRating={this.state.rating} maxRating={5} icon='star' size='massive' 
                        onRate={this.handleRate} />
                    </Grid.Row>
                    <Grid.Row style={{marginLeft: "3%",marginTop:"2%"}}>
                        <label style={{fontSize: '15px'}}>ความคิดเห็น : </label>
                    </Grid.Row>
                    <Grid.Row style={{marginLeft: "7%",marginTop:"1%"}}>
                        <Form>
                            <TextArea style={{width: '50vw'}} placeholder='Tell us more'
                            onChange={this.handleComment} />
                        </Form>
                    </Grid.Row>
                    <Grid.Row style={{marginLeft: "3%",marginTop:"2%"}}>
                        <label style={{fontSize: '15px'}}>อัพโหลดรูปภาพ : </label>
                    </Grid.Row>
                    <Grid.Row style={{marginLeft: "7%",marginTop:"1%"}}>
                        <input type="file" name="file" onChange={this.changeHandler}/>
                    </Grid.Row>
                    <Grid.Row style={{marginTop:"3%",display: 'flex',justifyContent: 'center'}}>
                        <Button onClick={this.saveComment} type='submit' id="btnSaveComment">ยืนยัน</Button>
                    </Grid.Row>          
                </Segment>
            </Grid.Column>
            </Grid>
            }
            {
            (this.state.deviceMode == "tabletPortrait") && 
            <Grid id="gridComment">
            <Grid.Column>      
                <Segment id="segmentComment">
                    <h3 id="fontHeadComment">แสดงความคิดเห็น</h3>
                    <Divider style={{margin: "2%",marginBottom:'5%'}} />  
                    <Grid.Row style={{marginLeft: "3%"}}>
                        <label style={{fontSize: '15px'}}>ระดับความพึงพอใจ : </label>
                    </Grid.Row>
                    <Grid.Row style={{marginLeft: "7%",marginTop:"1%"}}>
                        <Rating icon='star' defaultRating={this.state.rating} maxRating={5} icon='star' size='massive' 
                        onRate={this.handleRate} />
                    </Grid.Row>
                    <Grid.Row style={{marginLeft: "3%",marginTop:"2%"}}>
                        <label style={{fontSize: '15px'}}>ความคิดเห็น : </label>
                    </Grid.Row>
                    <Grid.Row style={{marginLeft: "7%",marginTop:"1%"}}>
                        <Form>
                            <TextArea style={{width: '50vw'}} placeholder='Tell us more'
                            onChange={this.handleComment} />
                        </Form>
                    </Grid.Row>
                    <Grid.Row style={{marginLeft: "3%",marginTop:"2%"}}>
                        <label style={{fontSize: '15px'}}>อัพโหลดรูปภาพ : </label>
                    </Grid.Row>
                    <Grid.Row style={{marginLeft: "7%",marginTop:"1%"}}>
                        <input type="file" name="file" onChange={this.changeHandler}/>
                    </Grid.Row>
                    <Grid.Row style={{marginTop:"5%",display: 'flex',justifyContent: 'center'}}>
                        <Button onClick={this.saveComment} type='submit' id="btnSaveComment">ยืนยัน</Button>
                    </Grid.Row>          
                </Segment>
            </Grid.Column>
            </Grid>
            }
            {
            (this.state.deviceMode == "mobileLandScape") && 
            <Grid id="gridComment">
            <Grid.Column >      
                <Segment id="segmentComment">
                    <h3 id="fontHeadComment">แสดงความคิดเห็น</h3>
                    <Divider style={{margin: "2%",marginBottom:'5%'}} />  
                    <div id="scrollComment">
                        <Grid.Row style={{marginLeft: "3%"}}>
                            <label style={{fontSize: '15px'}}>ระดับความพึงพอใจ : </label>
                        </Grid.Row>
                        <Grid.Row style={{marginLeft: "7%",marginTop:"1%"}}>
                            <Rating icon='star' defaultRating={this.state.rating} maxRating={5} icon='star' size='massive' 
                            onRate={this.handleRate} />
                        </Grid.Row>
                        <Grid.Row style={{marginLeft: "3%",marginTop:"2%"}}>
                            <label style={{fontSize: '15px'}}>ความคิดเห็น : </label>
                        </Grid.Row>
                        <Grid.Row style={{marginLeft: "7%",marginTop:"1%"}}>
                            <Form>
                                <TextArea style={{width: '50vw'}} placeholder='Tell us more'
                                onChange={this.handleComment} />
                            </Form>
                        </Grid.Row>
                        <Grid.Row style={{marginLeft: "3%",marginTop:"2%"}}>
                            <label style={{fontSize: '15px'}}>อัพโหลดรูปภาพ : </label>
                        </Grid.Row>
                        <Grid.Row style={{marginLeft: "7%",marginTop:"1%"}}>
                            <input type="file" name="file" onChange={this.changeHandler}/>
                        </Grid.Row>
                        <Grid.Row style={{marginTop:"5%",display: 'flex',justifyContent: 'center'}}>
                            <Button onClick={this.saveComment} type='submit' id="btnSaveComment">ยืนยัน</Button>
                        </Grid.Row>  
                    </div>        
                </Segment>
            </Grid.Column>
            </Grid>
            }
            {
            (this.state.deviceMode == "mobilePortrait") && 
            <Grid id="gridComment">
            <Grid.Column>      
                <Segment id="segmentComment">
                    <h3 id="fontHeadComment">แสดงความคิดเห็น</h3>
                    <Divider style={{margin: "2%",marginBottom:'5%'}} />  
                    <div id="scrollComment">
                        <Grid.Row style={{marginLeft: "13%"}}>
                            <label style={{fontSize: '13px'}}>ระดับความพึงพอใจ : </label>
                        </Grid.Row>
                        <Grid.Row style={{display: 'flex',justifyContent:'center',marginTop:"1%"}}>
                            <Rating icon='star' defaultRating={this.state.rating} maxRating={5} icon='star' size='massive' 
                            onRate={this.handleRate} />
                        </Grid.Row>
                        <Grid.Row style={{marginLeft: "13%",marginTop:"3%"}}>
                            <label style={{fontSize: '13px'}}>ความคิดเห็น : </label>
                        </Grid.Row>
                        <Grid.Row style={{display: 'flex',justifyContent:'center',marginTop:"1%"}}>
                            <Form>
                                <TextArea placeholder='Tell us more'
                                onChange={this.handleComment} />
                            </Form>
                        </Grid.Row>
                        <Grid.Row style={{marginLeft: "13%",marginTop:"3%"}}>
                            <label style={{fontSize: '13px'}}>อัพโหลดรูปภาพ : </label>
                        </Grid.Row>
                        <Grid.Row style={{marginLeft: "18%",marginTop:"1%"}}>
                            <input type="file" name="file" onChange={this.changeHandler}/>
                        </Grid.Row>
                        <Grid.Row style={{marginTop:"10%",display: 'flex',justifyContent: 'center'}}>
                            <Button onClick={this.saveComment} type='submit' id="btnSaveComment">ยืนยัน</Button>
                        </Grid.Row>  
                    </div>        
                </Segment>
            </Grid.Column>
            </Grid>
            }
            <Modal
                dimmer={true}
                open={this.state.openModal}
                size={'mini'}
            >
                <Modal.Header>PSYJAI</Modal.Header>
                <Modal.Content>
                    <Container style={{display: 'flex',alignItems:'center',flexDirection: 'column'}}>
                        {!this.state.checkNull && this.state.success && <Image src='checked.png' size='small' />}
                        {!this.state.checkNull && this.state.success == false && <Image src='cancel.png' size='small' />}
                        {this.state.checkNull && <Image src='warning.png' size='small' />}
                        {!this.state.checkNull && this.state.success && <label style={{fontFamily:'Mitr',marginTop:"5%",paddingInline:'10%',textAlign:'center',fontSize:'16px'}}>
                            การแสดงความคิดเห็นส่งสำเร็จ ! ขอบคุณสำหรับความเห็นนะคะ :-)</label>}
                        {!this.state.checkNull && this.state.success == false &&<label style={{fontFamily:'Mitr',marginTop:"5%",textAlign:'center',fontSize:'16px'}}>
                            การแสดงความคิดเห็นส่งไม่สำเร็จ ! รบกวนลองใหม่อีกครั้งนะคะ</label>}
                        {this.state.checkNull &&<label style={{fontFamily:'Mitr',marginTop:"5%",textAlign:'center',fontSize:'16px'}}>
                            กรุณาให้คะแนนและแสดงความคิดเห็น</label>}
                    </Container>  
                </Modal.Content>
                <Modal.Actions>
                    <Button positive style={{fontFamily: 'Mitr',fontWeight:'400'}}
                    onClick={() => {
                        this.setState({
                            openModal: false
                        })
                    }}
                    >ตกลง</Button>
                </Modal.Actions>
            </Modal>
            {
                (this.state.deviceMode == "tabletLandScape") && this.state.loading &&               
                    <Loader active content='Loading' />
            }
            {
                (this.state.deviceMode != "tabletLandScape") && this.state.loading && 
                <Dimmer active>          
                    <Loader active content='Loading' />
                </Dimmer>
            }
             
         
            </>
        )
    }
}export default Comment;