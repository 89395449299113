import React,{useState} from "react";
import '../css/mood.css'
import 'semantic-ui-css/semantic.css';
import { Segment,Message,Grid,Container,Divider,Dropdown,Loader } from 'semantic-ui-react'
import { Chart } from "react-google-charts";

const month = [
  { key: 1, text: 'มกราคม', value: 1 },
  { key: 2, text: 'กุมภาพันธ์', value: 2 },
  { key: 3, text: 'มีนาคม', value: 3 },
  { key: 4, text: 'เมษายน', value: 4 },
  { key: 5, text: 'พฤษภาคม', value: 5 },
  { key: 6, text: 'มิถุนายน', value: 6 },
  { key: 7, text: 'กรกฎาคม', value: 7 },
  { key: 8, text: 'สิงหาคม', value: 8 },
  { key: 9, text: 'กันยายน', value: 9 },
  { key: 10, text: 'ตุลาคม', value: 10 },
  { key: 11, text: 'พฤศจิกายน', value: 11 },
  { key: 12, text: 'ธันวาคม', value: 12 }
]

const year = [
  { key: 1, text: '2021' ,value: 1},
  { key: 2, text: '2022' ,value: 2},
  { key: 3, text: '2023' ,value: 3},
  { key: 4, text: '2024' ,value: 4},
  { key: 5, text: '2025' ,value: 5},
]
 
class Mood extends React.Component {
  constructor(props) {
    super(props)
    var today = new Date(),
    monthToday = today.getUTCMonth()
    this.state = { 
      selectedYear: 1,
      isLoading: true, 
      selectedMonth : monthToday+1,
      object:[],
      item:'',
      setitems:[],
      allmoods:[],
      arrayyear:[],
      arraymonth:[],
      newMood:[],
      err:[],
      count: 0,
      c:1,
      width: 0,
      height: 0,
      deviceMode: 'none',
      allmoodsFromdatabase: [],
      pieData: [],
      sliceColor: {}
    }
    this.onDrop = this.onDrop.bind(this);   
  }

  componentDidMount(){
    this.handleScreen()
    this.fetchData()
  }

  fetchData = () =>{
    // Where we're fetching data from
    let username = localStorage.getItem('name')
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username: username })
    };
    fetch('https://psyjai-dashboard.herokuapp.com/emotion_class',requestOptions)
      // We get the API response and receive data in JSON format...
      .then(response => response.json())
      // ...then we update the users state
      .then( data => {
          // console.log(data)
          let setDate = []
          // check date in data to add obj list
          for(let i in data){
          let dateSplit = data[i].timestamp.split("T")
          let count = 0
          if(setDate.length == 0){
            if(data[i].variable == "topic"){
              setDate.push({
                [dateSplit[0]]:{
                  levelPre: "none",
                  levelPost: "none",
                  mood: "none",
                  topic: data[i].value
                }
              })
            }        
            if(data[i].variable == "PostSeverityemo"){
              setDate.push({
                [dateSplit[0]]:{
                  levelPre: "none",
                  levelPost: data[i].value,
                  mood: "none",
                  topic: "none"
                }
              })
            }        
            if(data[i].variable == "PreSeverityemo"){
              setDate.push({
                [dateSplit[0]]:{
                  levelPre: data[i].value,
                  levelPost: "none",
                  mood: "none",
                  topic: "none"
                }
              })
            }       
            if(data[i].variable == "emotion_class"){
              setDate.push({
                [dateSplit[0]]:{
                  levelPre: "none",
                  levelPost: "none",
                  mood: data[i].value,
                  topic: "none"
                }
              })
            }            
          }else{
            for(let a in setDate){
              // console.log(Object.values(setDate[a])[0].mood)
              if(dateSplit[0] == Object.keys(setDate[a])[0]){
                  if(data[i].variable == "emotion_class"){
                    Object.values(setDate[a])[0].mood = data[i].value
                  }   
                  if(data[i].variable == "PreSeverityemo"){
                    Object.values(setDate[a])[0].levelPre = data[i].value
                  }   
                  if(data[i].variable == "PostSeverityemo"){
                    Object.values(setDate[a])[0].levelPost = data[i].value
                  }   
                  if(data[i].variable == "topic"){
                    Object.values(setDate[a])[0].topic = data[i].value
                  }   
                  count++     
              }
            }

            if(count == 0){
              if(data[i].variable == "topic"){
                setDate.push({
                  [dateSplit[0]]:{
                    levelPre: "none",
                    levelPost: "none",
                    mood: "none",
                    topic: data[i].value
                  }
                })
              }        
              if(data[i].variable == "PostSeverityemo"){
                setDate.push({
                  [dateSplit[0]]:{
                    levelPre: "none",
                    levelPost: data[i].value,
                    mood: "none",
                    topic: "none"
                  }
                })
              }        
              if(data[i].variable == "PreSeverityemo"){
                setDate.push({
                  [dateSplit[0]]:{
                    levelPre: data[i].value,
                    levelPost: "none",
                    mood: "none",
                    topic: "none"
                  }
                })
              }       
              if(data[i].variable == "emotion_class"){
                setDate.push({
                  [dateSplit[0]]:{
                    levelPre: "none",
                    levelPost: "none",
                    mood: data[i].value,
                    topic: "none"
                  }
                })
              }            
            }
          }
        }

        let allMoodData = []
        for(let i in setDate){
          if(Object.values(setDate[i])[0].mood != "none"){
            allMoodData.push({
                date: Object.keys(setDate[i])[0],
                mood: Object.values(setDate[i])[0].mood,
              })
          }
        }
        this.setState({
          allmoodsFromdatabase: allMoodData
        })
        // console.log(this.state.allmoodsFromdatabase)
        this.countMood()
    })
  }

  countMood = () =>{
    let dataMood = []
    this.state.allmoodsFromdatabase.forEach(data => {
      // console.log(data.date)
        let date = new Date(data.date)
        let month = date.getUTCMonth()
        let yearFromData = date.getUTCFullYear()

        if(yearFromData.toString() == year[this.state.selectedYear-1].text && month == this.state.selectedMonth){
          if(dataMood.length ==0){
              dataMood.push({
                [data.mood]: 1
              })
          }else{
            let count = 0
            for(let i in dataMood){
              if(Object.keys(dataMood[i]) == data.mood){
                let moodUp = parseInt(Object.values(dataMood[i])) + 1
                  dataMood[i] = {
                    [data.mood]: moodUp
                  }
                  count++
              }
            }
            if(count == 0){
              dataMood.push({
                [data.mood]: 1
              })
            }   
          }
        }
    })
    // console.log(dataMood)
    let dataTopie = []
    let dataSlice = {}
    for(let i in dataMood){
      if(i == 0){
        dataTopie.push(["อารมณ์", "จำนวน"])
      }
      dataTopie.push([Object.keys(dataMood[i])[0],Object.values(dataMood[i])[0]])
      let valueMood = Object.keys(dataMood[i])[0]
      if(valueMood == 'สบายๆ'){
        dataSlice = {
          ...dataSlice,
          [i]: { color: '#37BC9B' },
        }
      }

      if(valueMood == 'อารมณ์ดี'){
        dataSlice = {
          ...dataSlice,
          [i]: { color: '#FCBB42' },
        }
      }

      if(valueMood == 'อารมณ์ไม่ดี'){
        dataSlice = {
          ...dataSlice,
          [i]: { color: '#DA4453' },
        }
      }

      if(valueMood == 'ซึมๆ'){
        dataSlice = {
          ...dataSlice,
          [i]: { color: '#3BAFDA' },
        }
      }

      if(valueMood == 'เฉยๆ'){
        dataSlice = {
          ...dataSlice,
          [i]: { color: '#F5F7FA' },
        }
      }
    }
    // console.log(dataSlice)
    this.setState({
      pieData: dataTopie,
      sliceColor: dataSlice
    })
  }
  
  onDrop(picture) {
      this.setState({
          pictures: this.state.pictures,
      });
  }

  handleScreen = () =>{
    const mediaMatchMobilePortrait = window.matchMedia('(max-width: 480px)');
    const mediaMatchMobileLanscape = window.matchMedia('(min-width: 480px) and (max-width: 900px) and (orientation: landscape)');
    const mediaMatchTabletLanscape = window.matchMedia('(min-width: 900px)');
    const mediaMatchTabletPortrait = window.matchMedia('(min-width: 480px) and (max-width: 900px) and (orientation: portrait)');
  
    if(mediaMatchMobilePortrait.matches){
      this.setState({
          deviceMode: "mobilePortrait"
      })
    }
    
    if(mediaMatchMobileLanscape.matches){
      this.setState({
        deviceMode: "mobileLandScape"
      })
    }

    if(mediaMatchTabletLanscape.matches){
      this.setState({
        deviceMode: "tabletLandScape"
      })
    }

    if(mediaMatchTabletPortrait.matches){
      this.setState({
          deviceMode: "tabletPortrait"
      })
    }

    mediaMatchTabletPortrait.addEventListener("change", e => {
      // console.log("onListernerTabletPortrait: ",e.matches,e.media)
      if(e.matches){
        this.setState({
          deviceMode: "tabletPortrait"
        })
      }
    })

    mediaMatchMobilePortrait.addEventListener("change", e => {
      // console.log("onListernerMobilePort: ",e.matches,e.media)
      if(e.matches){
        this.setState({
          deviceMode: "mobilePortrait"
        })
      }
    })

    mediaMatchMobileLanscape.addEventListener("change", e => {
      // console.log("onListernerMobileLand: ",e.matches,e.media)
      if(e.matches){
        this.setState({
          deviceMode: "mobileLandScape"
        })
      }
    })

    mediaMatchTabletLanscape.addEventListener("change", e => {
      // console.log("onListernerTabletLand: ",e.matches,e.media)
      if(e.matches){
        this.setState({
          deviceMode: "tabletLandScape"
        })
      }
    })
  }

  handleMonthChange = async (e, { value }) => {
    // console.log("month: ",value)
    await this.setState({ selectedMonth: value })
    this.countMood()
  }
  handleYearChange = async (e, { value }) =>{ 
    await this.setState({ selectedYear: value }) 
    this.countMood()
  }

  render() {
    let idx,idx_y
    if (this.state.selectedMonth != ''){
      idx = parseInt(this.state.selectedMonth)
    }

    if (this.state.selectedYear != ''){
      idx_y = parseInt(this.state.selectedYear)
    }       

    return (
      <>
      {
         (this.state.deviceMode == "tabletLandScape") &&
      <Grid>
      <Grid.Column id="gridMood"> 
      <Segment id="segmentMood">
      <h3 id="fontHeadMood">อารมณ์ (Moods)</h3>  
      <Divider style={{margin: "2%"}} />
        <Container id="moodContainner">
        <div>
        <div id="rowDropdown">
          <Dropdown
          style={{margin:'5px'}}
          selection
          wrapSelection={false}
          options={month}
          // placeholder={month[idx-1].text}
          onChange={this.handleMonthChange}
          value= {this.state.selectedMonth}     
        />
        <Dropdown
          style={{margin:'5px'}}
          selection
          wrapSelection={false}
          options={year}
          placeholder={year[idx_y].text}
          onChange={this.handleYearChange}
          value={this.state.selectedYear}
        />
        </div>
         {this.state.pieData.length != 0 ? (
          <Chart 
              width= {'650px'}
              height={'420px'}
              chartType="PieChart"
              loader={<div>Loading Chart</div>} 
              data={this.state.pieData}
              options={{
                title: month[this.state.selectedMonth-1].text+' '+year[this.state.selectedYear-1].text,
                pieSliceTextStyle:{color: 'black'},
                legend: {textStyle: {color: 'black',fontSize:14,fontName: 'Mitr'},position:'bottom'},
                titleTextStyle: {fontName: 'Mitr' ,fontSize:18, bold:false,},   
                slices: this.state.sliceColor 
              }}
            rootProps={{ 'data-testid': '1' }}
      />
     
       )  : (
        <Message negative id="showMessage">
           <Message.Header id="fontMessage">ไม่พบข้อมูล</Message.Header>
        </Message>
      )}
        </div>
      </Container>
      </Segment>  
      </Grid.Column>
      </Grid>   
  }
   {
         (this.state.deviceMode == "tabletPortrait") &&
      <Grid id="gridMood">
      <Grid.Column> 
      <Segment id="segmentMood">
      <h3 id="fontHeadMood">อารมณ์ (Moods)</h3>  
      <Divider style={{margin: "2%"}} />
        <Container id="moodContainner">
        <div id="rowDropdown">
          <Dropdown
          style={{margin:'5px'}}
          selection
          wrapSelection={false}
          options={month}
          // placeholder={month[idx-1].text}
          onChange={this.handleMonthChange}
          value={this.state.selectedMonth}
        
        />
        <Dropdown
          style={{margin:'5px'}}
          selection
          // style = {{margin: "10px"}}
          wrapSelection={false}
          options={year}
          // placeholder={year[idx_y].text}
          onChange={this.handleYearChange}
          value={this.state.selectedYear}
        />
        </div>
        <div style={{display: 'flex',alignItems:'center', justifyContent: "center"}}>
         {this.state.pieData.length != 0  ? (
          <Chart 
          width= {'400px'}
          height={'420px'}
          chartType="PieChart"
          loader={<Loader active content='Loading' />} 
          data={this.state.pieData}
          options={{
            title: month[this.state.selectedMonth-1].text+' '+year[this.state.selectedYear-1].text,
            pieSliceTextStyle:{color: 'black'},
            legend: {textStyle: {color: 'black',fontSize:14,fontName: 'Mitr'},position:'bottom'},
            titleTextStyle: {fontName: 'Mitr' ,fontSize:18, bold:false,},      
            slices: this.state.sliceColor     
          }}
          rootProps={{ 'data-testid': '1' }}
      />
     
       )  : (
        <Message negative id="showMessage">
           <Message.Header id="fontMessage">ไม่พบข้อมูล</Message.Header>
        </Message>
      )}
        </div>
      </Container>
      </Segment>  
      </Grid.Column>
      </Grid>   
  }
   {
         (this.state.deviceMode == "mobileLandScape") &&
      <Grid id="gridMood">
      <Grid.Column> 
      <Segment id="segmentMood">
      <h3 id="fontHeadMood">อารมณ์ (Moods)</h3>  
      <Divider style={{margin: "2%"}} />
        <Container id="moodContainner">
        <div id="scrollMood">
        <div id="rowDropdown">
          <Dropdown
          style={{margin:'5px'}}
          selection
          wrapSelection={false}
          options={month}
          // placeholder={month[idx-1].text}
          onChange={this.handleMonthChange}
          value={this.state.selectedMonth}       
        />
        <Dropdown
          style={{margin:'5px'}}
          selection
          // style = {{margin: "10px"}}
          wrapSelection={false}
          options={year}
          // placeholder={year[idx_y].text}
          onChange={this.handleYearChange}
          value={this.state.selectedYear}
        />
        </div>
         {this.state.pieData.length != 0 ? (
          <Chart 
          width= {'500px'}
          height={'400px'}
          chartType="PieChart"
          loader={<Loader active content='Loading' />} 
          data={this.state.pieData}
          options={{
            title: month[this.state.selectedMonth-1].text+' '+year[this.state.selectedYear-1].text,
            pieSliceTextStyle:{color: 'black'},
            legend: {textStyle: {color: 'black',fontSize:14,fontName: 'Mitr'},position:'bottom'},
            titleTextStyle: {fontName: 'Mitr' ,fontSize:18, bold:false,},
            slices: this.state.sliceColor 
          }}
          rootProps={{ 'data-testid': '1' }}
      />
     
       )  : (
        <Message negative id="showMessage">
           <Message.Header id="fontMessage">ไม่พบข้อมูล</Message.Header>
        </Message>
      )}
        </div>
      </Container>
      </Segment>  
      </Grid.Column>
      </Grid>   
  }
  {
    (this.state.deviceMode == "mobilePortrait") &&
    <div style={{width: '100vw'}}>
      <Grid id="gridMood">
      <Grid.Column> 
      <Segment id="segmentMood">
      <h3 id="fontHeadMood">อารมณ์ (Moods)</h3>  
      <Divider style={{margin: "2%"}} />
        <Container id="moodContainner">
        <div id="scrollMood">
          <Dropdown
          style={{margin:'5px'}}
          selection
          wrapSelection={false}
          options={month}
          // placeholder={month[idx-1].text}
          onChange={this.handleMonthChange}
          value={this.state.selectedMonth}        
        />
        <Dropdown
          style={{margin:'5px'}}
          selection
          wrapSelection={false}
          options={year}
          placeholder={year[idx_y].text}
          onChange={this.handleYearChange}
          value={this.state.selectedYear}
        />
         {this.state.pieData.length != 0 ? (
          <Chart   
          width= {'270px'}
          height={'400px'}
          chartType="PieChart"
          loader={<Loader active content='Loading' />} 
          data={this.state.pieData}
          options={{
            title: month[this.state.selectedMonth-1].text+' '+year[this.state.selectedYear-1].text,
            pieSliceTextStyle:{color: 'black'},
            legend: {textStyle: {color: 'black',fontSize:14,fontName: 'Mitr'},position:'bottom'},
            titleTextStyle: {fontName: 'Mitr' ,fontSize:18, bold:false,},
            slices: this.state.sliceColor 
          }}
          rootProps={{ 'data-testid': '1' }}
      />
     
       )  : (
        <Message negative id="showMessage">
           <Message.Header id="fontMessage">ไม่พบข้อมูล</Message.Header>
        </Message>
      )}
        </div>
      </Container>
      </Segment>  
      </Grid.Column>
      </Grid> 
      </div>       
  }         
      </>
    );
  }
}export default Mood;