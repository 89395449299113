import React from "react";

import avatar from '../images/matthew.png'; 
import '../css/intervention.css'
import 'semantic-ui-css/semantic.css';
import { Segment,Divider,Loader,Grid,Image,Popup,Card } from 'semantic-ui-react'
// import {browserHistory} from 'react-router';
import { history } from '../history';
import ja from "date-fns/locale/ja";

const intervention_img = [
  {
    name:'in3PosThing', card: 'นึกถึงสิ่งดีๆ' ,value:'https://dl.dropbox.com/s/dd7mtc6ua4l2slt/in3PosThing.jpg'
},
{
    name:'inActiveListen',card: 'การฟังอย่างใส่ใจ' , value:'https://dl.dropbox.com/s/gd0vlcgq52woo4d/inActiveListen.jpg' 
},
{
    name:'inAngerManage',card: 'การจัดการความโกรธ', value:'https://dl.dropbox.com/s/p12l0tblylierfh/inAngerManage.jpg'
},
{
    name:'inCBT',card: 'สำรวจความคิด', value:'https://dl.dropbox.com/s/8llsx0duz5l9rku/inCBT.jpg'
},
{
    name:'inEmoAwa',card:  'รู้เท่าทันอารมณ์', value:'https://dl.dropbox.com/s/chsggtz0q90vxtl/inEmoAwa.jpg'
},
{
    name:'inGratitude',card: 'ฝึกชื่นชมและขอบคุณ', value:'https://dl.dropbox.com/s/i99sk3yelmofahu/inGratitude.jpg'
},
{
    name:'inGrief',card:'ขั้นของความเศร้าโศก', value:'https://dl.dropbox.com/s/xeuv623l91tb0qs/inGrief.jpg'
},
{
    name:'inImessage',card: 'การพูดสื่อสาร', value:'https://dl.dropbox.com/s/ukimmtjjl0bn9bz/inImessage.jpg' 
},
{
    name:'inPosThink',card: 'การคิดเชิงบวก', value:'https://dl.dropbox.com/s/ulqhothmr1yb070/inPosThink.jpg'
},
{
    name:'inProbSolve',card: 'สำรวจปัญหา', value:'https://dl.dropbox.com/s/o8ui4h7bv5i6exk/inProbSolve.jpg'
},
  {
      name:'inReBody',card: 'สำรวจร่างกาย', value:'https://dl.dropbox.com/s/dy9owdwmlw2ohdw/inReBody.jpg'
  },
  {
      name:'inReBreathing',card:'ฝึกผ่อนคลายด้วยลมหายใจ', value:'https://dl.dropbox.com/s/o609d1gp1v4sel0/inReBreathing.jpg' 
  },
  {
      name:'inRemotive',card: 'สร้างแรงจูงใจ', value:'https://dl.dropbox.com/s/4afn1u255ypjq33/inRemotive.jpg'
  },
  {
      name:'inReMuscle',card: 'ฝึกผ่อนคลายกล้ามเนื้อ', value:'https://dl.dropbox.com/s/vidccta0lddnwhn/inReMuscle.jpg'
  },
  {
      name:'inSelfReg',card: 'ฝึกตั้งเป้าหมาย', value:'https://dl.dropbox.com/s/k8yizszez5patt2/inSelfReg.jpg'
  },
  {
      name:'inSesteem',card: 'รู้จักคุณค่าของตนเอง', value:'https://dl.dropbox.com/s/h8vt2mguj3fqbe5/inSesteem.jpg'
  },
  {
      name:'inSocialSup',card:  'การสนับสนุนจากสังคม', value:'https://dl.dropbox.com/s/8v08bymvumwcnvc/inSocialSup.jpg' 
  },
  {
      name:'inStrangthWeak',card: 'รู้จักจุดเด่นของตนเอง', value:'https://dl.dropbox.com/s/46bb0hnwxtng0bg/inStrangthWeak.jpg'
  },
  {
      name:'inTimeManage',card: 'ฝึกจัดการเวลา', value:'https://dl.dropbox.com/s/58saomu7rwgs1ay/inTimeManage.jpg'
  }     
]
class Intervention extends React.Component {
  constructor(props) {
    super(props);
     this.state = {     
      pictures: [],
      isLoading: true,
      users: [],
      error: null,
      time: [],
      firstname: ' ',
      lastname: ' ',
      setItems: [],
      items: [],
      user_intervention:[],
      inter_value:' ',
      have:true,
      url:' ',
      deviceMode: 'none'
    };
     this.onDrop = this.onDrop.bind(this);
  }
  state = { activeItem: 'home' }
  onDrop(picture) {
      this.setState({
          pictures: this.state.pictures,
      });
  }
  handleItemClick = (e, { name }) => this.setState(
    { activeItem: name },
    history.push('/'+name)
    
    )

    fetchIntervention() {
      // if(this.state.user_intervention == '' || this.state.user_intervention == null ||this.state.user_intervention == undefined){
      //   for (let i in intervention_img){
      //     this.state.user_intervention.push({
      //       inter_value: intervention_img[i].card,
      //       have: true,
      //       url: intervention_img[i].value
      //    })
      //   }
      // }
      // this.setState({
      //   items: this.state.user_intervention
      // })
    
      // Where we're fetching data from
      let username = localStorage.getItem('name')
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: username })
    };
      fetch('https://psyjai-dashboard.herokuapp.com/intervention',requestOptions)
        // We get the API response and receive data in JSON format...
        .then(response => response.json())
        // ...then we update the users state
        .then(data => {
            this.setState({
                users: data,
                isLoading: false,
            })

           let status
            for (let i=0; i < intervention_img.length; i++){
                for(let j=0; j < data.length; j++){
                  //  console.log(data[j].value)           
                  if(data[j].variable === intervention_img[i].name && data[j].value ==='1'){
                     status = false
                     break
                  }
                  else{
                     status = true
                  }
              }
              this.state.user_intervention.push({
                inter_value: intervention_img[i].card,
                have: status,
                url: intervention_img[i].value
              })
            }
          this.setState({
            items: this.state.user_intervention
          })

          })
         
        // Catch any errors we hit and update the app
        .catch(error => this.setState({ error, isLoading: false }));

        

  }
    
    handleScreen = () =>{
      const mediaMatchPortrait = window.matchMedia('(orientation: portrait)')
      const mediaMatchLanscape = window.matchMedia('(orientation: landscape)')
      const mediaMatchMobilePortrait = window.matchMedia('(max-width: 480px)');
      const mediaMatchMobileLanscape = window.matchMedia('(min-width: 480px) and (max-width: 900px) and (orientation: landscape)');
      const mediaMatchTabletLanscape = window.matchMedia('(min-width: 900px)');
      const mediaMatchTabletPortrait = window.matchMedia('(min-width: 480px) and (max-width: 900px) and (orientation: portrait)');
    
      if(mediaMatchMobilePortrait.matches){
        this.setState({
            deviceMode: "mobilePortrait"
        })
      }
      
      if(mediaMatchMobileLanscape.matches){
        this.setState({
          deviceMode: "mobileLandScape"
        })
      }
  
      if(mediaMatchTabletLanscape.matches){
        this.setState({
          deviceMode: "tabletLandScape"
        })
      }
  
      if(mediaMatchTabletPortrait.matches){
        this.setState({
            deviceMode: "tabletPortrait"
        })
      }
  
      mediaMatchTabletPortrait.addEventListener("change", e => {
        // console.log("onListernerTabletPortrait: ",e.matches,e.media)
        if(e.matches){
          this.setState({
            deviceMode: "tabletPortrait"
          })
        }
      })
  
      mediaMatchMobilePortrait.addEventListener("change", e => {
        // console.log("onListernerMobilePort: ",e.matches,e.media)
        if(e.matches){
          this.setState({
            deviceMode: "mobilePortrait"
          })
        }
      })
  
      mediaMatchMobileLanscape.addEventListener("change", e => {
        // console.log("onListernerMobileLand: ",e.matches,e.media)
        if(e.matches){
          this.setState({
            deviceMode: "mobileLandScape"
          })
        }
      })
  
      mediaMatchTabletLanscape.addEventListener("change", e => {
        // console.log("onListernerTabletLand: ",e.matches,e.media)
        if(e.matches){
          this.setState({
            deviceMode: "tabletLandScape"
          })
        }
      })
    }
  

    componentDidMount() {
      this.handleScreen()
      this.fetchIntervention();
     
    }

  render() {
    const {isLoading, users, error } = this.state;
    const { activeItem } = this.state
    // console.log("intervention_img")
    const style = {
      borderRadius: '10px',
      opacity: 1,
      padding: '1em',
      backgroundColor: '#CD5275',
      color:'white',
      fontWeight:'300'
      
    }
    // console.log(this.state.user_intervention)
  
    

   
    return (
      <>
      {
         (this.state.deviceMode == "tabletLandScape") &&
         <Grid>
         <Grid.Column id="gridIntervention">  
           <Segment id="segmentIntervention">
           <div id="scrollIntervention">
               <h3 id="fontHeadIntervention">สิ่งที่ได้เรียนรู้ (Intervention)</h3>
               <Divider style={{margin: "2%"}} />
             {/* // {this.check_inter()} */}
             { !isLoading ? (
               <Card.Group itemsPerRow={5}>
               { this.state.user_intervention.map((user_intervention, i) => 
                   <Card>
                     {
                          user_intervention.have == true || user_intervention.have == undefined ?
                          <Popup 
                          trigger={ <Image size="small" centered src={user_intervention.url}  disabled={true} />}
                          content="คุณยังไม่ได้เรียนรู้หัวข้อนี้ค่ะ"
                          position='bottom center'
                          style={style}
                        /> :
                        <Image size="small" centered src={user_intervention.url}  disabled={user_intervention.have} />
                        }
                       <Card.Content>
                       {user_intervention.have == true ?  <Card.Meta>{user_intervention.inter_value} </Card.Meta> :
                        <Card.Description>{user_intervention.inter_value} </Card.Description>}
                       </Card.Content>  
               </Card> )}
               </Card.Group> )  :       
             <Loader active content='Loading' />
            
         } 
           </div>
         </Segment>  
         </Grid.Column>
       </Grid>   
      }

{
         ( this.state.deviceMode == "mobileLandScape" ) &&  
        <Grid id="gridIntervention">
        <Grid.Column> 
          <Segment id="segmentIntervention">
          <div id="scrollIntervention">
              <h3 id="fontHeadIntervention">สิ่งที่ได้เรียนรู้ (Intervention)</h3>
              <Divider style={{margin: "2%"}} />
            {/* // {this.check_inter()} */}
            { !isLoading ? (  
              <Card.Group itemsPerRow={4}>
              { this.state.user_intervention.map((user_intervention, i) => 
                  <Card>
                  {
                        user_intervention.have == true || user_intervention.have == undefined ?
                        <Popup 
                        trigger={ <Image size="small" centered src={user_intervention.url}  disabled={true} />}
                       content="คุณยังไม่ได้เรียนรู้หัวข้อนี้ค่ะ"
                       position='bottom center'
                       style={style}
                     /> :
                     <Image size="small" centered src={user_intervention.url}  disabled={user_intervention.have} />
                     }
                    <Card.Content>
                    {user_intervention.have == true ?  <Card.Meta>{user_intervention.inter_value} </Card.Meta> :
                     <Card.Description>{user_intervention.inter_value} </Card.Description>}
                    </Card.Content>  
            </Card>)}
              </Card.Group>   ):       
            <Loader active content='Loading' />    }
          </div>
        </Segment>  
    
        </Grid.Column>
      </Grid>   
}

{
      ( this.state.deviceMode == "mobilePortrait" ) &&      
        <Grid id="gridIntervention">
        <Grid.Column> 
          <Segment id="segmentIntervention">
          <div id="scrollIntervention">
              <h3 id="fontHeadIntervention">สิ่งที่ได้เรียนรู้ (Intervention)</h3>
              <Divider style={{margin: "2%"}} />
            {/* // {this.check_inter()} */}
            { !isLoading ? (  
              <Card.Group itemsPerRow={2}>
              { this.state.user_intervention.map((user_intervention, i) => 
                   <Card>
                   {
                         user_intervention.have == true || user_intervention.have == undefined ?
                         <Popup 
                         trigger={ <Image size="small" centered src={user_intervention.url}  disabled={true} />}
                        content="คุณยังไม่ได้เรียนรู้หัวข้อนี้ค่ะ"
                        position='bottom center'
                        style={style}
                      /> :
                      <Image size="small" centered src={user_intervention.url}  disabled={user_intervention.have} />
                      }
                     <Card.Content>
                     {user_intervention.have == true ?  <Card.Meta>{user_intervention.inter_value} </Card.Meta> :
                      <Card.Description>{user_intervention.inter_value} </Card.Description>}
                     </Card.Content>  
             </Card> )}
              </Card.Group>   ):       
            <Loader active content='Loading' />
        }
          </div>
        </Segment>  
        </Grid.Column>
      </Grid>   
}       

{
      ( this.state.deviceMode == "tabletPortrait" ) &&      
        <Grid id="gridIntervention">
        <Grid.Column> 
          <Segment id="segmentIntervention">
          <div id="scrollIntervention">
              <h3 id="fontHeadIntervention">สิ่งที่ได้เรียนรู้ (Intervention)</h3>
              <Divider style={{margin: "2%"}} />
            {/* // {this.check_inter()} */}
            { !isLoading ? (  
              <Card.Group itemsPerRow={4}>
              { this.state.user_intervention.map((user_intervention, i) => 
                   <Card>
                   {
                         user_intervention.have == true || user_intervention.have == undefined ?
                         <Popup 
                         trigger={ <Image size="small" centered src={user_intervention.url}  disabled={true} />}
                        content="คุณยังไม่ได้เรียนรู้หัวข้อนี้ค่ะ"
                        position='bottom center'
                        style={style}
                      /> :
                      <Image size="small" centered src={user_intervention.url}  disabled={user_intervention.have} />
                      }
                     <Card.Content>
                     {user_intervention.have == true ?  <Card.Meta>{user_intervention.inter_value} </Card.Meta> :
                      <Card.Description>{user_intervention.inter_value} </Card.Description>}
                     </Card.Content>  
             </Card> )}
              </Card.Group>  ):       
            <Loader active content='Loading' />
            
        } 
          </div>
        </Segment>  
        </Grid.Column>
      </Grid>   
}       
             
    </>
    );
  }
}export default Intervention;

