import React from "react";
import avatar from '../images/matthew.png'; 
import '../css/homework.css'
import 'semantic-ui-css/semantic.css';
import { Segment,Table,Grid,Divider,Loader,Image,Icon,Message} from 'semantic-ui-react'
import { FaHome,FaTheaterMasks,FaRegLightbulb,FaRegEdit,FaTree,FaQuestion, FaLastfmSquare} from "react-icons/fa";
import { MdMood } from "react-icons/md";
import { GrArticle } from "react-icons/gr";
// import {browserHistory} from 'react-router';
import { history } from '../history';


let timer = null;
class Homework extends React.Component {
  constructor(props) {
    super(props);
     this.state = {   
      loading:true,
      pictures: [],
      items: [],
      user_homework: [],
      check:0,
      date: '',
      value:'',
      have: '',
      index: '',
      deviceMode: 'none'
    };
     this.onDrop = this.onDrop.bind(this);
  }
  state = { activeItem: 'home' }
  onDrop(picture) {
      this.setState({
          pictures: this.state.pictures,
      });
  }
  handleItemClick = (e, { name }) => this.setState(
    { activeItem: name },
    history.push('/'+name)
    
  )
  fetchHomework() {
    const Homework = [
      {
        name:'hwSesteem', card: 'รู้คุณค่าของตัวเอง' ,value:'https://react.semantic-ui.com/images/avatar/large/joe.jpg'
    },
    {
        name:'hwScompass1',card: 'ฝึกการใจดีกับตนเอง' , value:'https://react.semantic-ui.com/images/avatar/large/elliot.jpg' 
    },
    {
        name:'hwScompass2',card: 'ฝึกรู้ทันการตัดสินตนเอง', value:'https://react.semantic-ui.com/images/avatar/large/stevie.jpg'
    },
    {
        name:'hwScompass3',card: 'ปรับมุมมองการตัดสินตัวเอง', value:'https://react.semantic-ui.com/images/avatar/large/stevie.jpg'
    },
    {
        name:'hwDailymed',card:  'ฝึกสติ', value:'https://react.semantic-ui.com/images/avatar/large/nan.jpg'
    },
    {
        name:'hwEmoAwaneg',card: 'ฝึกรู้เท่าทันอารมณ์ทางลบ', value:'https://react.semantic-ui.com/images/avatar/large/zoe.jpg'
    },
    {
        name:'hwEmoAwapos',card:'ฝึกรู้เท่าทันอารมณ์ทางบวก', value:'https://react.semantic-ui.com/images/avatar/large/ade.jpg'
    },
    {
        name:'hwRemotive',card: 'ฝึกสร้างแรงจูงใจ', value:'https://react.semantic-ui.com/images/avatar/large/christian.jpg' 
    },
    {
        name:'hwBA',card: 'กระตุ้นพฤติกรรม', value:'https://react.semantic-ui.com/images/avatar/large/jenny.jpg'
    },
    {
        name:'hwCBT1',card: 'ตั้งคำถามความคิด', value:'https://react.semantic-ui.com/images/avatar/large/veronika.jpg'
    },  
    {
        name:'hwCBT2',card: 'สัตว์ประหลาดความคิด', value:'https://react.semantic-ui.com/images/avatar/large/veronika.jpg'
    },
    {
        name:'hwMood',card: 'บันทึกอารมณ์ประจำวัน', value:'https://react.semantic-ui.com/images/avatar/large/veronika.jpg'
    }, 
    ]
    // Where we're fetching data from
    let username = localStorage.getItem('name')
    // let username = "June Matavee"
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username: username })
  };
    fetch('https://psyjai-dashboard.herokuapp.com/hw',requestOptions)
      // We get the API response and receive data in JSON format...
      .then(response => response.json())
      // ...then we update the users state
      .then(data =>
        {
          this.setState({
            users: data,
            isLoading: false,
          })

          //  doesn't have data in database 
            if(data.length != 0){
              // set date 
              for(let i in data){
                  let dataDate = data[i].timestamp.split("T")
                  data[i].timestamp = dataDate[0]
              }
              let input = (data).sort((a, b) => a.timestamp.localeCompare(b.timestamp));
              let date
              // console.log(input)
              for (let i=0; i < Homework.length; i++){
                  for(let j=0; j < input.length; j++){
                    // date = (input[j].timestamp).split("T")
                    date = input[j].timestamp
                    // console.log(input[j].value)           
                    if(input[j].variable === Homework[i].name && input[j].value ==='1'){
                      this.state.user_homework.push({
                        date: date,
                        value: Homework[i].card,
                      })

                      break
                    }
                }
              }

            this.setState({
              items: this.state.user_homework,
              loading:false,
            })
           }else{
            this.setState({
              // items: this.state.user_homework,
              loading:false,
            })
           }
     })

      // Catch any errors we hit and update the app
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleScreen = () =>{
    const mediaMatchMobilePortrait = window.matchMedia('(max-width: 480px)');
    const mediaMatchMobileLanscape = window.matchMedia('(min-width: 480px) and (max-width: 900px) and (orientation: landscape)');
    const mediaMatchTabletLanscape = window.matchMedia('(min-width: 900px)');
    const mediaMatchTabletPortrait = window.matchMedia('(min-width: 480px) and (max-width: 900px) and (orientation: portrait)');
  
    if(mediaMatchMobilePortrait.matches){
      this.setState({
          deviceMode: "mobilePortrait"
      })
    }
    
    if(mediaMatchMobileLanscape.matches){
      this.setState({
        deviceMode: "mobileLandScape"
      })
    }

    if(mediaMatchTabletLanscape.matches){
      this.setState({
        deviceMode: "tabletLandScape"
      })
    }

    if(mediaMatchTabletPortrait.matches){
      this.setState({
          deviceMode: "tabletPortrait"
      })
    }

    mediaMatchTabletPortrait.addEventListener("change", e => {
      // console.log("onListernerTabletPortrait: ",e.matches,e.media)
      if(e.matches){
        this.setState({
          deviceMode: "tabletPortrait"
        })
      }
    })

    mediaMatchMobilePortrait.addEventListener("change", e => {
      // console.log("onListernerMobilePort: ",e.matches,e.media)
      if(e.matches){
        this.setState({
          deviceMode: "mobilePortrait"
        })
      }
    })

    mediaMatchMobileLanscape.addEventListener("change", e => {
      // console.log("onListernerMobileLand: ",e.matches,e.media)
      if(e.matches){
        this.setState({
          deviceMode: "mobileLandScape"
        })
      }
    })

    mediaMatchTabletLanscape.addEventListener("change", e => {
      // console.log("onListernerTabletLand: ",e.matches,e.media)
      if(e.matches){
        this.setState({
          deviceMode: "tabletLandScape"
        })
      }
    })
  }

  componentDidMount() { 
    this.handleScreen()
    this.fetchHomework();
  }

  render() {
    const { activeItem } = this.state;
  
    return ( 
      <>
      {
        (this.state.deviceMode == "tabletLandScape") && 
        <Grid>
           <Grid.Column id="gridHomework">      
              <Segment id="segmentHomework">
                <h3 id="fontHeadHomework">การบ้าน (Homework)</h3>
                <Divider style={{margin: "2%"}} />
                <div id="scrollHomework">
                { ((this.state.user_homework).length != 0 && this.state.loading == false) &&  (
              <Table celled unstackable>
                <Table.Header >
                  <Table.Row>
                    <Table.HeaderCell>ลำดับที่</Table.HeaderCell>
                    <Table.HeaderCell>วันที่ทำ</Table.HeaderCell>
                    <Table.HeaderCell>หัวข้อ</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

              { this.state.user_homework.map((user_homework, i) =>          
                <Table.Body  sortable celled fixed fluid key={i}>
                  <Table.Row>
                    <Table.Cell>{i+1}</Table.Cell>
                    <Table.Cell>{user_homework.date}</Table.Cell>
                    <Table.Cell>{user_homework.value}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              )}

            </Table>)
          }
          {(this.state.loading == true && (this.state.user_homework).length == 0) && 
            <Loader active content='Loading' />
          }
          {((this.state.user_homework).length == 0 && this.state.loading == false ) &&
            <Message negative id="showMessage">
            <Message.Header id="fontMessage">ไม่พบข้อมูล</Message.Header>
            </Message>

          }


             </div>
           </Segment> 
           </Grid.Column>
            </Grid>
       }
       {
        (this.state.deviceMode == "mobileLandScape") && 
        <Grid id="gridHomework">
           <Grid.Column>      
              <Segment id="segmentHomework">
                <h3 id="fontHeadHomework">การบ้าน (Homework)</h3>
                <Divider style={{margin: "2%"}} />
                <div id="scrollHomework">
                { ((this.state.user_homework).length != 0 && this.state.loading == false) &&  (
              <Table celled unstackable>
                <Table.Header >
                  <Table.Row>
                    <Table.HeaderCell>ลำดับที่</Table.HeaderCell>
                    <Table.HeaderCell>วันที่ทำ</Table.HeaderCell>
                    <Table.HeaderCell>หัวข้อ</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

              { this.state.user_homework.map((user_homework, i) =>          
                <Table.Body  sortable celled fixed fluid key={i}>
                  <Table.Row>
                    <Table.Cell>{i+1}</Table.Cell>
                    <Table.Cell>{user_homework.date}</Table.Cell>
                    <Table.Cell>{user_homework.value}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              )}

            </Table>)
          }
          {(this.state.loading == true && (this.state.user_homework).length == 0) && 
              <Loader active content='Loading' />
          }
          {((this.state.user_homework).length == 0 && this.state.loading == false ) &&
              <Message negative id="showMessage">
              <Message.Header id="fontMessage">ไม่พบข้อมูล</Message.Header>
              </Message>
          }
             </div>
           </Segment> 
           </Grid.Column>
            </Grid>
       }

{
        (this.state.deviceMode == "mobilePortrait") && 
        <Grid id="gridHomework">
           <Grid.Column>      
              <Segment id="segmentHomework">
                <h3 id="fontHeadHomework">การบ้าน (Homework)</h3>
                <Divider style={{margin: "4%"}} />
                <div id="scrollHomework">
              { ((this.state.user_homework).length != 0 && this.state.loading == false) &&  (
              <Table celled unstackable id="tableHomework">
                <Table.Header >
                  <Table.Row>
                    <Table.HeaderCell>ลำดับที่</Table.HeaderCell>
                    <Table.HeaderCell>วันที่ทำ</Table.HeaderCell>
                    <Table.HeaderCell>หัวข้อ</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

              { this.state.user_homework.map((user_homework, i) =>          
                <Table.Body  sortable celled fixed fluid key={i}>
                  <Table.Row>
                    <Table.Cell>{i+1}</Table.Cell>
                    <Table.Cell>{user_homework.date}</Table.Cell>
                    <Table.Cell>{user_homework.value}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              )}

            </Table>)
          }
          {(this.state.loading == true && (this.state.user_homework).length == 0) && 
                <Loader active content='Loading' />
          }
          {((this.state.user_homework).length == 0 && this.state.loading == false ) &&
                <Message negative id="showMessage">
                <Message.Header id="fontMessage">ไม่พบข้อมูล</Message.Header>
                </Message>

          }
             </div>
           </Segment> 
           </Grid.Column>
            </Grid>
       }

{
        (this.state.deviceMode == "tabletPortrait") && 
        <Grid id="gridHomework">
           <Grid.Column>      
              <Segment id="segmentHomework">
                <h3 id="fontHeadHomework">การบ้าน (Homework)</h3>
                <Divider style={{margin: "4%"}} />
                <div id="scrollHomework">
                { ((this.state.user_homework).length != 0 && this.state.loading == false) &&  (
              <Table celled unstackable fixed id="tableHomework">
                <Table.Header >
                  <Table.Row>
                    <Table.HeaderCell>ลำดับที่</Table.HeaderCell>
                    <Table.HeaderCell>วันที่ทำ</Table.HeaderCell>
                    <Table.HeaderCell>หัวข้อ</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

              { this.state.user_homework.map((user_homework, i) =>          
                <Table.Body  sortable celled fixed fluid key={i}>
                  <Table.Row>
                    <Table.Cell>{i+1}</Table.Cell>
                    <Table.Cell>{user_homework.date}</Table.Cell>
                    <Table.Cell>{user_homework.value}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              )}

            </Table>)
          }
          {(this.state.loading == true && (this.state.user_homework).length == 0) && 
                <Loader active content='Loading' />
          }
          {((this.state.user_homework).length == 0 && this.state.loading == false ) &&

                <Message negative id="showMessage">
                <Message.Header id="fontMessage">ไม่พบข้อมูล</Message.Header>
                </Message>

          }
             </div>
           </Segment> 
           </Grid.Column>
            </Grid>
       }
      </>
    );
  }
}export default Homework;

