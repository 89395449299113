import React from "react";
import '../css/result.css'
import 'semantic-ui-css/semantic.css';
import { Segment,Table,Grid,Divider,Loader,Message } from 'semantic-ui-react'
import { history } from '../history';

class Result extends React.Component {
  constructor(props) {
    super(props);
     this.state = {     
      pictures: [],
      firstname: '',
      lastname: '',
      setItems: [],
      items:[],
      user_result:[],
      loading:true,
      isLoading: true,
      users: [],
      date: '',
      stress: '',
      anx: '',
      depress: '',
      deviceMode: 'none'
    };
     this.onDrop = this.onDrop.bind(this);
  }
  state = { activeItem: 'home' }
  onDrop(picture) {
      this.setState({
          pictures: this.state.pictures,
      });
  }
  handleItemClick = (e, { name }) => this.setState(
    { activeItem: name },
    history.push('/'+name)
    
    )


    fetchResult() {
      // Where we're fetching data from
      let username = localStorage.getItem('name')
      // let username = "June Matavee"
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: username })
    };
      fetch('https://psyjai-dashboard.herokuapp.com/result',requestOptions)
        // We get the API response and receive data in JSON format...
        .then(response => response.json())
        // ...then we update the users state
        .then(data =>
          {
            this.setState({
              users: data,
              isLoading: false,
            })
            // this.checkData()
            // console.log(data)
            
            if(data.length != 0){
              for(let i in data){
                  let dataDate = data[i].timestamp.split("T")
                  data[i].timestamp = dataDate[0]
              }
              let input = (data).sort((a, b) => b.timestamp.localeCompare(a.timestamp));
              // console.log(input)
              let newData = [];
              input.forEach(function (item) {
                  var name = item.variable
                  let time = item.timestamp
                  let year = time.split('-')[0]
                  if(year != '2020'){
                    newData[time] = newData[time] || {};
                    newData[time][name]= newData[time][name]|| [];
                    newData[time][name].push(item);
                  }       
              })
              // console.log(newData)
              for (let i in newData){
                let {str,an,dep} = []
                // console.log(newData[i])
                  if(newData[i].TotalQstress != undefined){
                    // console.log(newData[i]['TotalQstress'][0].variable,newData[i]['TotalQstress'][0].value)
                    str = newData[i]['TotalQstress'][0].value
                  }
                  if(newData[i].TotalQanx != undefined){
                    // console.log(newData[i]['TotalQanx'][0].variable,newData[i]['TotalQanx'][0].value)
                    an = newData[i]['TotalQanx'][0].value
                  }
                  if(newData[i].TotalQdepress != undefined){
                    // console.log(newData[i]['TotalQdepress'][0].variable,newData[i]['TotalQdepress'][0].value)
                    dep = newData[i]['TotalQdepress'][0].value
                  }
                  this.state.user_result.push({
                      date : i,
                      stress: str,
                      depress:dep,
                      anx:an
                  })
              }
                  this.setState({
                    items: this.state.user_homework,
                    loading: false
                  })
            }else{
              this.setState({
                loading: false
              })
            }
           

          }
         
        )
        // Catch any errors we hit and update the app
        .catch(error => this.setState({ error, isLoading: false }));
    }
  
    componentDidMount() {
      this.handleScreen()
      this.fetchResult();
    }

    handleScreen = () =>{
      const mediaMatchPortrait = window.matchMedia('(orientation: portrait)')
      const mediaMatchLanscape = window.matchMedia('(orientation: landscape)')
      const mediaMatchMobilePortrait = window.matchMedia('(max-width: 480px)');
      const mediaMatchMobileLanscape = window.matchMedia('(min-width: 480px) and (max-width: 900px) and (orientation: landscape)');
      const mediaMatchTabletLanscape = window.matchMedia('(min-width: 900px)');
      const mediaMatchTabletPortrait = window.matchMedia('(min-width: 480px) and (max-width: 900px) and (orientation: portrait)');
    
      if(mediaMatchMobilePortrait.matches){
        this.setState({
            deviceMode: "mobilePortrait"
        })
      }
      
      if(mediaMatchMobileLanscape.matches){
        this.setState({
          deviceMode: "mobileLandScape"
        })
      }
  
      if(mediaMatchTabletLanscape.matches){
        this.setState({
          deviceMode: "tabletLandScape"
        })
      }
  
      if(mediaMatchTabletPortrait.matches){
        this.setState({
            deviceMode: "tabletPortrait"
        })
      }
  
      mediaMatchTabletPortrait.addEventListener("change", e => {
        // console.log("onListernerTabletPortrait: ",e.matches,e.media)
        if(e.matches){
          this.setState({
            deviceMode: "tabletPortrait"
          })
        }
      })
  
      mediaMatchMobilePortrait.addEventListener("change", e => {
        // console.log("onListernerMobilePort: ",e.matches,e.media)
        if(e.matches){
          this.setState({
            deviceMode: "mobilePortrait"
          })
        }
      })
  
      mediaMatchMobileLanscape.addEventListener("change", e => {
        // console.log("onListernerMobileLand: ",e.matches,e.media)
        if(e.matches){
          this.setState({
            deviceMode: "mobileLandScape"
          })
        }
      })
  
      mediaMatchTabletLanscape.addEventListener("change", e => {
        // console.log("onListernerTabletLand: ",e.matches,e.media)
        if(e.matches){
          this.setState({
            deviceMode: "tabletLandScape"
          })
        }
      })
    }
    
  render() {
    return (
      <>
      {
        (this.state.deviceMode == "tabletLandScape") && 
        <Grid >
          <Grid.Column id="gridResult">      
      <div>
      <Segment id="segmentResult">
        <h3 id="fontHeadResult">ผลการประเมินอารมณ์</h3>
        <Divider style={{margin: "2%"}} />
       {((this.state.user_result).length != 0 && this.state.loading == false) &&  (
        <Table celled unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>วันที่ประเมิน</Table.HeaderCell>
            <Table.HeaderCell>ความวิตกกังวล</Table.HeaderCell>
            <Table.HeaderCell>ความเครียด</Table.HeaderCell>
            <Table.HeaderCell>ความเศร้า</Table.HeaderCell>
          </Table.Row>
          </Table.Header>
          { this.state.user_result.map((user_result, i) =>    {
            // console.log("userResult ",user_result)
            return(
              <Table.Body  sortable celled fixed fluid key={i}>
              <Table.Row>
                <Table.Cell>{user_result.date}</Table.Cell>
                <Table.Cell>{user_result.anx}</Table.Cell>
                <Table.Cell>{user_result.stress}</Table.Cell>
                <Table.Cell>{user_result.depress}</Table.Cell>
              </Table.Row>
            </Table.Body>
            )
          }           
           
          )}  
      </Table>)
      }
        {(this.state.loading == true && (this.state.user_result).length == 0) && 
            <Loader active content='Loading' />
        }
        {((this.state.user_result).length == 0 && this.state.loading == false ) &&
            <Message negative id="showMessage">
              <Message.Header id="fontMessage">ไม่พบข้อมูล</Message.Header>
            </Message>

        }
      </Segment>
      </div>
      </Grid.Column>
      </Grid>
  }
     {
        (this.state.deviceMode == "mobileLandScape") && 
        <Grid id="gridResult">
        <Grid.Column>      
      <Segment id="segmentResult">
        <h3 id="fontHeadResult">ผลการประเมินอารมณ์</h3>
        <Divider style={{margin: "2%"}} />
        {((this.state.user_result).length != 0 && this.state.loading == false) &&  (
            <div id="scrollResult">
            <Table celled unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>วันที่ประเมิน</Table.HeaderCell>
                <Table.HeaderCell>ความวิตกกังวล</Table.HeaderCell>
                <Table.HeaderCell>ความเครียด</Table.HeaderCell>
                <Table.HeaderCell>ความเศร้า</Table.HeaderCell>
              </Table.Row>
              </Table.Header>
              { this.state.user_result.map((user_result, i) =>               
                <Table.Body  sortable celled fixed fluid key={i}>
                  <Table.Row>
                    <Table.Cell>{user_result.date}</Table.Cell>
                    <Table.Cell>{user_result.anx}</Table.Cell>
                    <Table.Cell>{user_result.stress}</Table.Cell>
                    <Table.Cell>{user_result.depress}</Table.Cell>
                  </Table.Row>
                </Table.Body>
            )}  
            </Table>
            </div>)
        }
        {(this.state.loading == true && (this.state.user_result).length == 0) && 
                <Loader active content='Loading' />
        }
        {((this.state.user_result).length == 0 && this.state.loading == false ) &&
              <Message negative id="showMessage">
              <Message.Header id="fontMessage">ไม่พบข้อมูล</Message.Header>
              </Message>
        }
      </Segment>
      </Grid.Column>
      </Grid>
  }
   {
        (this.state.deviceMode == "mobilePortrait") && 
        <Grid id="gridResult">
          <Grid.Column>        
      <Segment id="segmentResult">
        <h3 id="fontHeadResult">ผลการประเมินอารมณ์</h3>
        <Divider style={{margin: "2%"}} />
      {
        ((this.state.user_result).length != 0 && this.state.loading == false) &&  (
        <div id="scrollResult">
      <Table celled unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>วันที่ประเมิน</Table.HeaderCell>
            <Table.HeaderCell>ความวิตกกังวล</Table.HeaderCell>
            <Table.HeaderCell>ความเครียด</Table.HeaderCell>
            <Table.HeaderCell>ความเศร้า</Table.HeaderCell>
          </Table.Row>
          </Table.Header>
          { this.state.user_result.map((user_result, i) =>               
            <Table.Body  sortable celled fixed fluid key={i}>
              <Table.Row>
                <Table.Cell>{user_result.date}</Table.Cell>
                <Table.Cell>{user_result.anx}</Table.Cell>
                <Table.Cell>{user_result.stress}</Table.Cell>
                <Table.Cell>{user_result.depress}</Table.Cell>
              </Table.Row>
            </Table.Body>
          )}  
      </Table>
      </div>)
      }
       {(this.state.loading == true && (this.state.user_result).length == 0) && 
                <Loader active content='Loading' />
        }
        {((this.state.user_result).length == 0 && this.state.loading == false ) &&
              <Message negative id="showMessage">
              <Message.Header id="fontMessage">ไม่พบข้อมูล</Message.Header>
              </Message>
        }
      </Segment>
     
      </Grid.Column>
      </Grid>
  }
  {
        (this.state.deviceMode == "tabletPortrait") && 
        <Grid id="gridResult">
          <Grid.Column >        
      <Segment id="segmentResult">
        <h3 id="fontHeadResult">ผลการประเมินอารมณ์</h3>
        <Divider style={{margin: "2%"}} />
      {
        ((this.state.user_result).length != 0 && this.state.loading == false) && 
        <div id="scrollResult">
      <Table celled unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>วันที่ประเมิน</Table.HeaderCell>
            <Table.HeaderCell>ความวิตกกังวล</Table.HeaderCell>
            <Table.HeaderCell>ความเครียด</Table.HeaderCell>
            <Table.HeaderCell>ความเศร้า</Table.HeaderCell>
          </Table.Row>
          </Table.Header>
          { this.state.user_result.map((user_result, i) =>               
            <Table.Body  sortable celled fixed fluid key={i}>
              <Table.Row>
                <Table.Cell>{user_result.date}</Table.Cell>
                <Table.Cell>{user_result.anx}</Table.Cell>
                <Table.Cell>{user_result.stress}</Table.Cell>
                <Table.Cell>{user_result.depress}</Table.Cell>
              </Table.Row>
            </Table.Body>
          )}  
      </Table>
      </div>
      }
       {(this.state.loading == true && (this.state.user_result).length == 0) && 
                <Loader active content='Loading' />
        }
        {((this.state.user_result).length == 0 && this.state.loading == false ) &&
              <Message negative id="showMessage">
              <Message.Header id="fontMessage">ไม่พบข้อมูล</Message.Header>
              </Message>
        }
      </Segment>
     
      </Grid.Column>
      </Grid>
  }
  </>
      );
    }
  }export default Result;
