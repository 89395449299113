import React from "react";
import 'semantic-ui-css/semantic.css';
import '../css/disclaimer.css'
import { Segment,Rating,Grid,Divider,Form,TextArea,Button,Modal,Image,Container,
    Dimmer,Loader } from 'semantic-ui-react'
import { history } from "../history";

class Disclaimer extends React.Component {
    constructor(props) {
        super(props);
         this.state = { 
        };
    }

    render() {
        return (   
            <Grid id="gridDis">
                <Grid.Column>      
                <Segment id="segmentDis">
                    <h3 id="fontHeadDis">เงื่อนไขและข้อตกลงการใช้งาน</h3>
                <Divider style={{margin: "2%"}} />
                    <div id="scrollDis"> 
                        <p id="firstParagraph">1. “ใส่ใจ” แชทบอทปัญญาประดิษฐ์เพื่อการดูแลสุขภาพจิตและการช่วยเหลือด้านอารมณ์และสุขภาพจิตในภาวะการระบาดของไวรัสโควิด-19 คืออะไร</p>                     
                        <p id="ansParagraph">“ใส่ใจ” เป็นแชทบอทปัญญาประดิษฐ์ ที่ถูกสร้างขึ้นมาเพื่อการดูแลสุขภาพจิตและการช่วยเหลือด้าน 
                        อารมณ์และสุขภาพจิตในภาวะการระบาดของไวรัสโควิด-19 พัฒนาขึ้นภายใต้ความร่วมมือระหว่างภาควิชา 
                        จิตเวชศาสตร์ คณะแพทยศาสตร์ศิริราชพยาบาล และภาควิชาวิศวกรรมคอมพิวเตอร์ คณะวิศวกรรมศาสตร์ มหาวิทยาลัยมหิดล 
                        โดยได้รับการสนับสนุนงบประมาณวิจัยจากกองทุนวิจัยและพัฒนากิจการกระจายเสียง กิจการโทรทัศน์ และกิจการโทรคมนาคม 
                        เพื่อประโยชน์สาธารณะ (กทปส.) ภายใต้การกํากับดูแล ของคณะกรรมการกิจการกระจายเสียง กิจการโทรทัศน์ 
                        และกิจการโทรคมนาคมแห่งชาติ หรือ กสทช.</p>  
                        <p id="ansParagraph">แชทบอทนี้เป็นการบูรณาการองค์ความรู้ด้านจิตวิทยา สุขภาพจิต และปัญญาประดิษฐ์ เพื่อสร้าง เครื่องมือสําหรับให้ผู้ใช้งานสามารถประเมินและดูแลสุขภาพจิตและอารมณ์ในระดับเบื้องต้นด้วยตนเอง ผ่านการใช้งานทางสื่ออิเล็กทรอนิกส์สําหรับบุคคลทั่วไป </p> 
                        <p id="firstParagraph">2. รูปแบบการให้บริการของ “ใส่ใจ” แชทบอทปัญญาประดิษฐ์ เพื่อการดูแลสุขภาพจิต และ การช่วยเหลือด้านอารมณ์และสุขภาพจิตในภาวะการระบาดของไวรัสโควิด-19</p>
                        <p id="ansParagraph">“ใส่ใจ” ถูกออกแบบมาเพื่อเพิ่มการเข้าถึงบริการด้านสุขภาพจิตในระดับเบื้องต้น 
                            โดยมิได้ มีจุดมุ่งหมายในการวินิจฉัย รักษาพยาบาล และไม่สามารถทดแทนการรักษาได้อย่างถาวรเช่นเดียวกับการพบ 
                            บุคลากรด้านจิตเวชที่สถานพยาบาลหรือการเข้ารับการปรึกษาเชิงจิตวิทยากับบุคลากรทางการแพทย์ และ สาธารณสุขโดยตรงได้
                        </p>
                        <p id="ansParagraph">ผลการประเมินที่ได้จากการพูดคุยกับ “ใส่ใจ” ไม่สามารถใช้ทดแทนการประเมินด้วยแบบคัดกรอง หรือ 
                        แบบทดสอบมาตรฐาน และไม่สามารถทดแทนการวินิจฉัยจากแพทย์ได้ แต่ผลที่ได้จากการประเมินจากแชทบอท มีประโยชน์ในแง่ของการให้ผู้ใช้งานติดตามสภาวะอารมณ์ของตนเองได้อย่างเป็นรูปธรรม
                        </p>
                        <p id="ansParagraph">การพูดคุยเพื่อการดูแลสุขภาพจิตและสภาวะอารมณ์กับ “ใส่ใจ” ไม่สามารถทดแทนการบําบัดรักษา หรือการปรึกษาในเชิงจิตวิทยาจากบุคลากรด้านสุขภาพจิตและจิตเวชได้</p>
                        <p id="ansParagraph">“ใส่ใจ” ไม่สามารถใช้งานในกรณีฉุกเฉิน เร่งด่วน หรือในกรณีที่ผู้ใช้งานมีอาการทางจิต สภาวะ อารมณ์แง่ลบที่ท่วมท้นรุนแรง หรือในภาวะที่ผู้ใช้งานอยากทําร้ายตนเอง ทําร้ายผู้อื่น หรือกระทําการ ซึ่งอาจเป็นอันตรายต่อชีวิต หากผู้ใช้งานมีภาวะหรืออาการดังกล่าว ท่านต้องหยุดใช้งานทันที และติดต่อ หน่วยงานการแพทย์ฉุกเฉินเพื่อขอความช่วยเหลือโดยเร่งด่วน ท่านสามารถโทรติดต่อสายด่วนสุขภาพจิต 1323 สายด่วนสมาคมเพื่อนพูดคุยทางโทรศัพท์เพื่อป้องกันการฆ่าตัวตาย สะมาริตันส์ (02) 713 6793 หรือ สถาบันการแพทย์ฉุกเฉิน 1669 เพื่อขอรับความช่วยเหลือที่ถูกต้องและเหมาะสมอย่างเร่งด่วน</p>
                        <p id="ansParagraph">โปรดทําความเข้าใจว่า “ใส่ใจ” เป็นระบบหุ่นยนต์โต้ตอบอัตโนมัติ ทํางานด้วยระบบปัญญาประดิษฐ์ “ใส่ใจ” จึงไม่สามารถให้ความช่วยเหลือท่านในกรณีฉุกเฉิน รุนแรง หรือเร่งด่วนได้</p>
                        <p id="firstParagraph">3. การรักษาความลับและการเก็บรักษาข้อมูลของ “ใส่ใจ” เป็นอย่างไร</p>
                        <p id="ansParagraph">ข้อมูลส่วนบุคคล ในที่นี้ หมายถึง ข้อมูลเกี่ยวกับบุคคลซึ่งทําให้สามารถระบุตัวบุคคลนั้นได้ ไม่ว่าทางตรงหรือทางอ้อม</p>
                        <p id="ansParagraph">การเก็บรวบรวมข้อมูลส่วนบุคคลจะเป็นไปในลักษณะที่ไม่สามารถระบุอัตลักษณ์ของผู้ใช้งานได้ โดยการใช้กระบวนการทางคณิตศาสตร์เพื่อแปลงข้อมูล หากมีการรายงานผลการใช้งานในแง่ของผลการวิจัย หรือรายงานผลต่อผู้ให้ทุน
                        จะเป็นไปในภาพรวมที่ไม่มีการระบุถึงตัวตน หรือข้อมูลส่วนบุคคลผู้ใช้บริการ ผู้พัฒนา และระบบ จะไม่สามารถระบุข้อมูลที่แสดงความเป็นตัวตน 
                        หรืออัตลักษณ์ของท่านโดยข้อมูลของท่าน จะได้รับการปกป้องด้วยวิธีการจัดทําข้อมูลนิรนาม (Non-attributable) และจะไม่ถูกระบุชื่อ สกุล หรือ
                        ข้อมูลอย่างอื่นที่จะทําให้ทราบข้อมูลที่แน่นอนของท่าน ข้อมูลต่อไปนี้จะถูกนําไปใช้ ได้แก่ เพศ อายุ อาชีพ ระดับการศึกษา โดยจะถูกนําไปใช้ประโยชน์ใน 4 กรณี ได้แก่</p>
                        <p id="ansParagraph">1. รายงานผลภาพรวมของผู้ใช้งาน </p>
                        <p id="ansParagraph">2. ปรับปรุงคุณภาพของ “ใส่ใจ” ให้มีความเหมาะสมและสอดคล้องกับลักษณะพื้นฐานของผู้ใช้งานแต่ละกลุ่ม </p>
                        <p id="ansParagraph">3. ระบบ หรือผู้ดูแลจะเก็บรักษาข้อมูลและเรื่องราวของผู้ใช้งานเป็นความลับ โดยไม่เปิดเผยเรื่องราวส่วนบุคคล 
                        และรายละเอียดการให้บริการ ในกรณีที่มีความจําเป็นต้องขอใช้ข้อมูล ของท่านที่นอกเหนือจากรายการที่ได้แจ้งไว้ข้างต้น เพื่อการทํางานวิจัย 
                        หรือตามคําสั่งศาล กรณีมีการร้องขอ ผู้ดูแลระบบจะได้แจ้งให้ท่านทราบ และเพื่อขอความยินยอมต่อไป 
                        โดยจะได้ดําเนินการตามมาตรการการรักษาความลับตามกฎหมาย ทั้งนี้ เพื่อให้การรับรองและแสดงความมั่นแก่ผู้ใช้บริการว่าข้อมูลของท่านจะได้รับ 
                        การคุ้มครอง ปราศจากการล่วงละเมิดข้อมูลด้วยวิธีต่างๆ การเก็บรักษาข้อมูลส่วนบุคคลของผู้ใช้บริการ อยู่ภายใต้หลักความโปร่งใส ถูกต้อง และ 
                        สามารถตรวจสอบได้ โดยเป็นการเก็บรวบรวมภายใต้ขอบเขตและวัตถุประสงค์ตามที่ผู้ให้ทุน และผู้พัฒนากําหนด</p>
                        <p id="ansParagraph">ท่านยินยอมให้ใช้ข้อมูลส่วนบุคคลที่ให้ไว้ในการติดต่อ ประสานงานที่เกี่ยวข้องการกับการให้บริการ ที่จําเป็น</p>
                        <p id="ansParagraph">ทั้งนี้ ข้อความการสนทนา จะถูกแปลงค่าเป็นตัวเลขด้วยกระบวนการปัญญาประดิษฐ์ (Artificial intelligent) 
                        และการเรียนรู้ของเครื่อง (Machine learning) เพื่อพัฒนาฐานการเรียนรู้และนําไปใช้ปรับปรุง 
                        คุณภาพและความแม่นยําของระบบปัญญาประดิษฐ์และเนื้อหาของ “ใส่ใจ” ในภาพรวม</p>
                        <p id="ansParagraph">ผู้ดูแลข้อมูลและพัฒนาระบบ คือ ภาควิชาวิศวกรรมคอมพิวเตอร์ คณะวิศวกรรมศาสตร์ มหาวิทยาลัยมหิดล (อ.ดร.กลกรณ์ วงศ์ภาติกะเสรี email : konlakorn.won@mahidol.ac.th)</p>
                        <p id="ansParagraph">ผู้ดูแลและกํากับเนื้อหาเชิงจิตวิทยาคือ รศ.พญ.สุดสบาย จุลกทัพพะ email : sudsabuy.chu@mahidol.ac.th)</p>
                        <p id="firstParagraph">4. ท่านสามารถให้การยินยอมรับให้บริการ (Inform Consent) ได้อย่างไร</p>
                        <p id="ansParagraph">โปรดอ่านข้อตกลงนี้อย่างระมัดระวังก่อนใช้บริการ และ โปรดปฏิบัติตามเงื่อนไข ตลอดจน ข้อกําหนดในข้อตกลงนี้อย่างเคร่งครัด</p>
                        <p id="ansParagraph" style={{paddingInlineStart:'5%'}}>ก. แชทบอทใส่ใจ คือ คือการให้บริการด้านสุขภาพจิตโดยใช้เทคโนโลยีหุ่นยนต์โต้ตอบอัตโนมัติปัญญาประดิษฐ์</p>
                        <p id="ansParagraph" style={{paddingInlineStart:'5%'}}>ข. แชทบอทใส่ใจเป็นแนวทางในการรักษาเยียวยาจิตใจด้วยตนเองที่เหมาะสมในเบื้องต้น พึงระลึกเสมอว่า แชทบอทใส่ใจนี้ ไม่ได้สร้างขึ้นเพื่อจุดมุ่งหมายในการให้บริการ ในกรณีฉุกเฉิน และ ไม่สามารถทดแทนระบบการแพทย์ฉุกเฉินได้ ดังนั้น ท่านควรมีช่องทางการติดต่อความช่วยเหลือสําหรับกรณี ฉุกเฉินหรือการติดตามการดูแลช่องทางอื่น โดยหากท่านพิจารณาแล้วว่ามีความจําเป็นที่จะต้องได้รับการ ช่วยเหลือที่ถูกต้องและเหมาะสมในกรณีฉุกเฉิน กรุณาติดต่อ สายด่วนฉุกเฉิน 1669 กรมสุขภาพจิต โทร1323 หรือ สมาคมสะมาริตันส์แห่งประเทศไทย โทร (02) 713 6793 เป็นต้น</p>
                        <p id="ansParagraph" style={{paddingInlineStart:'5%'}}>ค. การยอมรับข้อกําหนดและเงื่อนไขดังกล่าว ท่านรับทราบและเข้าใจและยอมรับว่า</p>
                        <p id="ansParagraph" style={{paddingInlineStart:'5%'}}>1) ท่านสามารถคาดหวังประโยชน์จากการใช้บริการแชทบอทใส่ใจได้ แต่คณะผู้พัฒนาไม่สามารถให้การรับรองผลการใช้งานได้ </p>
                        <p id="ansParagraph" style={{paddingInlineStart:'5%'}}>2) การให้บริการผ่านเครือข่ายการสื่อสาร ผู้ใช้บริการย่อมยอมรับในข้อจํากัดของลักษณะบุคคลที่เหมาะต่อการใช้งาน การโต้ตอบของ แชทบอทใส่ใจ ที่ไม่อาจเรียนรู้ข้อมูลได้เทียบเท่า บุคคลจริง ตลอดจน ข้อขัดข้องทางเทคนิค ความล่าช้า หรือเหตุอันไม่พึงประสงค์ในขณะนั้นทุกกรณี </p>
                        <p id="ansParagraph" style={{paddingInlineStart:'5%'}}>3) ท่านยอมรับว่าบริการแชทบอทใส่ใจ มีข้อจํากัดในการใช้งาน และลักษณะของบุคคลที่เหมาะต่อการใช้งาน เนื่องจากการโต้ตอบของแชทบอทใส่ใจ ไม่อาจเรียนรู้ข้อมูล หรือตอบกลับได้ เทียบเท่าบุคคล หรือผู้เชี่ยวชาญจริง และอาจมีข้อขัดข้องทางเทคนิค ความล่าช้า หรือมีเหตุ อันไม่พึงประสงค์เกิดขึ้นได้</p>
                        <p id="ansParagraph">หากท่านใช้งานแชทบอทใส่ใจต่อจากนี้ เป็นการหมายความว่าท่านได้อ่านเงื่อนไขการให้บริการ ความเสี่ยง ข้อจํากัด และยอมรับเงื่อนไขดังกล่าวแล้ว</p>
                        <p id="firstParagraph">5. ลิขสิทธิ์ เนื้อหา และข้อมูลของแชทบอทใส่ใจเป็นอย่างไร</p>
                        <p id="ansParagraph">คณะผู้พัฒนา สงวนสิทธิ์ใน เนื้อหา ข้อความ รูปแบบ เอกสารตลอดจนการพัฒนา ประดิษฐ์คิดค้น ทําซ้ําหรือดัดแปลง 
                        ไม่ว่าบางส่วนหรือทั้งหมด จัดทํา AI Chat bot ให้บริการในลักษณะเดียวกันเพื่อประโยชน์ ในเชิงพาณิชย์ หรือแสวงหาประโยชน์อันเกิดระบบ Al chat bot นี้ 
                        เว้นแต่เป็นการจัดทําเพื่อพัฒนาคุณภาพ สําหรับระบบ AI หรือเป็นการพัฒนาเพื่อเป็นประโยชน์ต่อสาธารณะและไม่แสวงหาผลกําไรเท่านั้น 
                        ทั้งนี้ต้องดําเนินการแจ้งขออนุญาตเป็นหนังสือลายลักษณ์อักษรทางเอกสารหรือทางอิเล็กทรอนิกส์ต่อ ผู้ให้ทุน 
                        ได้แก่ กองทุนวิจัยและพัฒนากิจการกระจายเสียง กิจการโทรทัศน์ และกิจการโทรคมนาคม เพื่อประโยชน์ สาธารณะ (กทปส) ภายใต้การกํากับดูแลของ คณะกรรมการกิจการกระจายเสียง 
                        กิจการโทรทัศน์ และกิจการ โทรคมนาคมแห่งชาติ หรือ (กสทช) และหน่วยงานผู้ร่วมพัฒนาแชทบอทใส่ใจ ได้แก่ 
                        ภาควิชาจิตเวชศาสตร์ คณะแพทยศาสตร์ศิริราชพยาบาล และภาควิชาวิศวกรรมคอมพิวเตอร์ คณะวิศวกรรมศาสตร์ มหาวิทยาลัยมหิดล </p>
                        <p id="firstParagraph">6. หากท่านมีข้อสงสัย ต้องการสอบถามหรือข้อเสนอแนะประการใด จะติดต่อคณะผู้พัฒนาได้อย่างไร</p>
                        <p id="ansParagraph">ท่านสามารถติดต่อได้ที่ภาควิชาจิตเวชศาสตร์ คณะแพทยศาสตร์ศิริราชพยาบาล มหาวิทยาลัยมหิดล โทรศัพท์ (02) 419 4293</p>
                        <p id="ansParagraph">หรือ ภาควิชาวิศวกรรมคอมพิวเตอร์ คณะวิศวกรรมศาสตร์ มหาวิทยาลัยมหิดล โทรศัพท์ (02) 889 2138</p>
                        <p id="ansParagraph">หรือ กองทุนวิจัยและพัฒนากิจการกระจายเสียง กิจการโทรทัศน์ และกิจการโทรคมนาคม เพื่อ ประโยชน์สาธารณะ (กทปส.) โทรศัพท์ (02) 554 8117</p>
                        <div style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
                            <Button onClick={() => history.push('/')}><p style={{fontFamily: 'Mitr',fontWeight:500}}>ไปหน้าหลัก</p></Button>
                        </div> 
                    </div>
                </Segment>
                </Grid.Column>
            </Grid>
        )
    }
}export default Disclaimer;

