import React from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import firebase from './firebasedb/firebaseconfig';
import { history } from './history'
// Page
import Login from "./page/login.js"
import MenuBar from "./page/menu"
import Dashboard from "./page/dashboard_tree"
import Mood from "./page/mood"
import Intervention from "./page/intervention";
import Homework from "./page/homework";
import Result from "./page/result";
import Tree from "./page/tree";
import Comment from "./page/comment";
import QA from "./page/qa";
import Disclaimer from "./page/disclaimer";
import Policy from "./page/policy";

export default class AppRouter extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
            currentUser: null,
            logIn: localStorage.getItem('login'),
            username: localStorage.getItem('name'),
            first: true
        }     
    }
    
    componentDidMount() {
        console.log(localStorage.getItem('login'))
        if(localStorage.getItem('login') == null ||
        localStorage.getItem('login') == undefined){
            localStorage.setItem('login',false)
        }

        this.firebaseInit()
        window.fbAsyncInit = function() {
            window.FB.init({
                appId            : '755271551771919',
                autoLogAppEvents : true,
                xfbml            : true,
                version          : 'v11.0'
            });
        }
        
    }

    firebaseInit = async () =>{
        // console.log(localStorage.getItem('redirect') )
         
        await firebase.auth()
        .getRedirectResult()
        .then((result) => {
            // console.log(result)
        if(result != null){
            if (result.credential) {
                /** @type {firebase.auth.OAuthCredential} */
                var credential = result.credential;
                var token = credential.accessToken;
              }
              var user = result.user;
            //   console.log(user)
              if(user != null){
                // console.log("user, ",user)
                window.FB.api('/'+user.providerData[0].uid+"?access_token="+token, {fields: ['first_name','last_name']}, function(res) {
                    // console.log(res)
                    // localStorage.setItem('login',true)
                    localStorage.setItem('firstname',res.first_name)
                    localStorage.setItem('lastname',res.last_name)  
                    let firstname = res.first_name 
                    let lastname = res.last_name
                    let username = firstname+" "+lastname 
                    localStorage.setItem('name',username)  
                    localStorage.setItem('uid',res.id)
                    // localStorage.setItem('login',true)
                    // history.push("/")
                  })   
              }else{
                localStorage.setItem('login',false)
            }     
        } 
        })

        if(localStorage.getItem('redirect') == "false"){
            firebase.auth().onAuthStateChanged(user => {
                // console.log(user)
                if (user) {   
                    let getUID = localStorage.getItem('uid')
                    // console.log(user.providerData[0].uid,getUID)   
                    if(user.providerData[0].uid == getUID){
                        localStorage.setItem('login',true)
                        this.setState({
                            currentUser: user,
                            logIn: localStorage.getItem('login'),
                        })  
                    }else{
                        localStorage.setItem('login',false)
                    }              
                }else{
                    localStorage.setItem('login',false)
                }
            })
        }else{
            localStorage.setItem('redirect',false)  
            // console.log(localStorage.getItem('redirect'))
        }
            
        
        
    }

    facebookInit = () =>{
        window.fbAsyncInit = function() {
            window.FB.init({
                appId            : '755271551771919',
                autoLogAppEvents : true,
                xfbml            : true,
                version          : 'v11.0'
            });

            window.FB.getLoginStatus(function(response) {
                // console.log(response)
                if (response.status === 'connected') {
                    // The user is logged in and has authenticated your
                    // app, and response.authResponse supplies
                    // and set login is true   
                    // window.location.href = "https://www.facebook.com/v11.0/dialog/oauth?client_id=755271551771919&redirect_uri=/"
                    window.FB.api('/me', {fields: ['first_name','last_name']}, function(res) {
                        if (res && !res.error) {
                            /* handle the result */
                            localStorage.setItem('login',true)
                            localStorage.setItem('firstname',res.first_name)
                            localStorage.setItem('lastname',res.last_name)  
                            let firstname = res.first_name 
                            let lastname = res.last_name
                            let username = firstname+" "+lastname 
                            localStorage.setItem('name',username)  
                            localStorage.setItem('uid',res.id)
                            // window.location.href = "https://www.facebook.com/v11.0/dialog/oauth?client_id=755271551771919&redirect_uri=/"   
                        //   console.log('Good to see you, ' + username + ' :-)');
                        //   console.log('login success)');         
                        }
                    })
                    // console.log(response)
                } else if (response.status === 'not_authorized') {
                    // The user hasn't authorized your application.  They
                    // must click the Login button, or you must call FB.login

                } else {
                    // The user isn't logged in to Facebook. You can launch a
                    // login dialog with a user gesture, but the user may have
                    // to log in to Facebook before authorizing your application.
                    localStorage.setItem('login',false)
                }
            });
            
            (function(d, s, id){
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {return;}
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
              }(document, 'script', 'facebook-jssdk'));
        }
    }

    render(){ 
        let getLinkPath = window.location.href.split("/")
        let realpath = getLinkPath[3].split("?")
        
        if(localStorage.getItem('login') == null){
            localStorage.setItem('login',false)
        }

        if(localStorage.getItem('login') == "false"){
            return(
                <Router>
                    <Switch>     
                        <Route path="/disclaimer">    
                            <div id="backgroundDash">    
                                <Disclaimer/>
                            </div>
                        </Route> 
                        <Route path="/privacy_policy">  
                            <div id="backgroundDash">          
                                <Policy/>
                            </div>
                        </Route> 
                        <Route path="/">
                            <Login />
                        </Route>
                    </Switch>
                    {
                        (realpath != "disclaimer" && 
                        realpath != "privacy_policy") &&
                        <Redirect path="*" to="/" />
                    }        
                </Router>
            );
        }else if(localStorage.getItem('login') == "true"){
            console.log(window.location.href)
            return(
            
                <Router>
                    <div id="backgroundDash">
                    {
                        (realpath != "disclaimer" && 
                        realpath != "privacy_policy" ) &&
                        <MenuBar/> 
                    }
                    <Switch>
                        <Route path="/mood">        
                            <Mood/>
                        </Route> 
                        <Route path="/intervention">        
                            <Intervention/>
                        </Route> 
                        <Route path="/homework">        
                            <Homework/>
                        </Route> 
                        <Route path="/result_screening">        
                            <Result/>
                        </Route> 
                        <Route path="/collection_tree">        
                            <Tree/>
                        </Route> 
                        <Route path="/comment">        
                            <Comment/>
                        </Route> 
                        <Route path="/question">        
                            <QA/>
                        </Route> 
                        <Route path="/disclaimer">        
                            <Disclaimer/>
                        </Route> 
                        <Route path="/privacy_policy">        
                            <Policy/>
                        </Route> 
                        <Route path="/">        
                            <Dashboard/>
                        </Route>   
                    </Switch>
                    </div> 
                    {
                        realpath != "disclaimer" && realpath != "mood" && 
                        realpath != "homework" && realpath != "result_screening" && 
                        realpath != "intervention" && realpath != "collection_tree" && 
                        realpath != "comment" && realpath != "question" && 
                        realpath != "privacy_policy" && 
                        <Redirect path="*" to="/" />
                    }   
                </Router> 
            );
        }else{
            return (null);
        }
    }
}

   