import React from "react";
import 'semantic-ui-css/semantic.css';
import '../css/disclaimer.css'
import { Segment,Rating,Grid,Divider,Form,TextArea,Button,Modal,Image,Container,
    Dimmer,Loader } from 'semantic-ui-react'
import { history } from "../history";

class Policy extends React.Component {
    constructor(props) {
        super(props);
         this.state = { 
        };
    }

    render() {
        return (   
            <Grid id="gridDis">
                <Grid.Column>      
                <Segment id="segmentDis">
                    <h3 id="fontHeadDis">Privacy Policy</h3>
                <Divider style={{margin: "2%"}} />
                    <div id="scrollDis"> 
                        
                        <p id="ansParagraph">This Privacy Policy describes Our policies and procedures on the collection, 
                        use and disclosure of Your information when You use the Service and tells You about Your privacy 
                        rights and how the law protects You.</p>
                        <p id="ansParagraph">We use Your Personal data to provide and improve the Service. 
                            By using the Service, You agree to the collection and use of information 
                            in accordance with this Privacy Policy. This Privacy Policy has been created 
                            with the help of the Privacy Policy Generator.</p>  
                      
                        <p id="firstParagraph" style={{fontSize: '20px'}}>Interpretation and Definitions</p>
                        <p id="firstParagraph" style={{fontSize: '18px'}}>Interpretation</p>
                        <p id="ansParagraph">The words of which the initial letter is capitalized have meanings 
                        defined under the following conditions. The following definitions shall have the same 
                        meaning regardless of whether they appear in singular or in plural.</p>

                        <p id="firstParagraph" style={{fontSize: '18px'}}>Definitions</p>
                        <p id="ansParagraph">For the purposes of this Privacy Policy:</p>
                        <li id="ansParagraph" style={{marginLeft: '10px'}}><b>Account</b> means a unique account created for 
                        You to access our Service or parts of our Service.</li>
                        <li id="ansParagraph" style={{marginLeft: '10px'}}><b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to PSYJAI.</li>
                        <li id="ansParagraph" style={{marginLeft: '10px'}}><b>Cookies</b> are small files that are placed on Your computer, mobile device or any other device by a website, 
                        containing the details of Your browsing history on that website among its many uses.</li>
                        <li id="ansParagraph" style={{marginLeft: '10px'}}><b>Country</b> refers to: Thailand</li>
                        <li id="ansParagraph" style={{marginLeft: '10px'}}><b>Device</b> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
                        <li id="ansParagraph" style={{marginLeft: '10px'}}><b>Personal Data</b> is any information that relates to an identified or identifiable individual.</li>
                        <li id="ansParagraph" style={{marginLeft: '10px'}}><b>Service</b> refers to the Website.</li>
                        <li id="ansParagraph" style={{marginLeft: '10px'}}><b>Service Provider </b> means any natural or legal person who processes the
                        data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, 
                        to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</li>
                        <li id="ansParagraph" style={{marginLeft: '10px'}}><b>Usage Data</b> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
                        <li id="ansParagraph" style={{marginLeft: '10px'}}><b>Website</b> refers to psyjai-dashboard, accessible from https://www.psyjai-dashboard.com</li>
                        <li id="ansParagraph" style={{marginLeft: '10px'}}><b>You</b> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
                        <p></p>
                        <p id="firstParagraph" style={{fontSize: '20px'}}>Collecting and Using Your Personal Data</p>
                        <p id="firstParagraph" style={{fontSize: '18px'}}>Types of Data Collected</p>
                        <p id="firstParagraph" style={{fontSize: '16px'}}>Personal Data</p>
                        <p id="ansParagraph">While using Our Service, We may ask You to provide Us with certain personally identifiable information that 
                        can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
                        <li id="ansParagraph" style={{marginLeft: '10px'}}>First name and last name</li>
                        <li id="ansParagraph" style={{marginLeft: '10px'}}>Usage Data</li>

                        <p></p>
                        <p id="firstParagraph" style={{fontSize: '16px'}}>Usage Data</p>
                        <p id="ansParagraph">Usage Data is collected automatically when using the Service.</p>
                        <p id="ansParagraph">Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), 
                        browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, 
                        the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                        <p id="ansParagraph">When You access the Service by or through a mobile device, We may collect certain 
                        information automatically, including, but not limited to, the type of mobile device You use, Your mobile 
                        device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile 
                        Internet browser You use, unique device identifiers and other diagnostic data.</p>
                        <p id="ansParagraph">We may also collect information that Your browser sends whenever You visit our Service or 
                        when You access the Service by or through a mobile device.</p>

                        <p id="firstParagraph" style={{fontSize: '16px'}}>Tracking Technologies and Cookies</p>
                        <p id="ansParagraph">We use Cookies and similar tracking technologies to track the activity on Our Service and store 
                        certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information 
                        and to improve and analyze Our Service. The technologies We use may include:</p>
                        <li id="ansParagraph" style={{marginLeft: '10px'}}><b>Cookies or Browser Cookies.</b> A cookie is a small file placed on Your 
                        Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, 
                        if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser 
                        setting so that it will refuse Cookies, our Service may use Cookies.</li>
                        <li id="ansParagraph" style={{marginLeft: '10px'}}><b>Flash Cookies. </b> Certain features of our Service may use local stored 
                        objects (or Flash Cookies) to collect and store information about Your preferences or Your activity on our Service. 
                        Flash Cookies are not managed by the same browser settings as those used for Browser Cookies. 
                        For more information on how You can delete Flash Cookies, please read "Where can I change the settings for disabling, 
                        or deleting local shared objects?" available at https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_</li>
                        <li id="ansParagraph" style={{marginLeft: '10px'}}><b>Web Beacons.</b> Certain sections of our Service and our emails may 
                        contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, 
                        and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages 
                        or opened an email and for other related website statistics (for example, 
                        recording the popularity of a certain section and verifying system and server integrity).</li>

                        <p></p>
                        <p id="firstParagraph" style={{fontSize: '20px'}}>Use of Your Personal Data</p>
                        <li id="ansParagraph" style={{marginLeft: '10px'}}><b>To provide and maintain our Service</b>, including to monitor the usage of our Service.</li>
                        <li id="ansParagraph" style={{marginLeft: '10px'}}><b>To manage Your Account:</b> to manage Your registration as a user of the Service. The Personal Data You provide can give 
                        You access to different functionalities of the Service that are available to You as a registered user.</li>
                        <li id="ansParagraph" style={{marginLeft: '10px'}}><b>To manage Your requests: </b> To attend and manage Your requests to Us.</li>
                        <li id="ansParagraph" style={{marginLeft: '10px'}}><b>For other purposes: </b>We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional 
                        campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</li>
                        <p></p>

                        <p id="firstParagraph" style={{fontSize: '20px'}}>Security of Your Personal Data</p>
                        <p id="ansParagraph">The security of Your Personal Data is important to Us, 
                        but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. 
                        While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its 
                        absolute security.</p>

                        <p id="firstParagraph" style={{fontSize: '20px'}}>Children's Privacy</p>
                        <p id="ansParagraph">Our Service does not address anyone under the age of 13. We do not knowingly collect 
                        personally identifiable information from anyone under the age of 13. If You are a parent or guardian and 
                        You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware 
                        that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, 
                        We take steps to remove that information from Our servers.</p>

                        <p id="firstParagraph" style={{fontSize: '20px'}}>User request deletion of data</p>
                        <p id="ansParagraph">User can request to delete thier data by send the request to our email : <b>pornpitcha.mhtech@gmail.com</b></p>
                        
                        <p id="firstParagraph" style={{fontSize: '20px'}}>Contact Us</p>
                        <p id="ansParagraph">If you have any questions about this Privacy Policy, You can contact us: By visiting this page on our website: <b>https://www.facebook.com/psyjaibot</b></p>

                        
                        
                        <div style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
                            <Button onClick={() => history.push('/')}><p style={{fontFamily: 'Mitr',fontWeight:500}}>ไปหน้าหลัก</p></Button>
                        </div> 
                    </div>
                </Segment>
                </Grid.Column>
            </Grid>
        )
    }
}export default Policy;

